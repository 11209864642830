import { MenuItem } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import classNames from "classnames";
import React from "react";
import { Icons } from "../Icons/Icons";
import { InputBaseProps } from "../InputBase/InputBaseProps";
import { InputWrapper } from "../InputBase/InputWrapper";
import "./CustomSelect.scss";

export type ItemBase = object | string;

export interface CustomSelectBasicItem {
  title: string;
  value: string;
}

export type CustomSelectValueType = string | number | null;

export interface CustomSelectProps<Item extends ItemBase> extends InputBaseProps<Item | undefined> {
  items: ReadonlyArray<Item>;
  itemValueGetter: (item: Item | undefined) => CustomSelectValueType;
  itemTitleGetter: (item: Item) => React.ReactNode;
  fullWidth?: boolean;
  className?: string;
  error?: boolean;
  placeholder?: React.ReactNode;
  disabled?: boolean;
}

const CustomSelect = <Item extends ItemBase>(props: CustomSelectProps<Item>) => {
  const {
    items,
    value,
    itemValueGetter,
    itemTitleGetter,
    fullWidth,
    onChange,
    className,
    errorMessage,
    error,
    placeholder,
    disabled,
  } = props;

  return (
    <InputWrapper
      {...props}
      className={classNames({
        "SelectInput-container": true,
        [className as string]: className,
      })}>
      <Select
        disabled={disabled}
        onChange={(event) => {
          onChange && onChange(items.find((item) => itemValueGetter(item) === event.target.value));
        }}
        value={value !== undefined ? itemValueGetter(value) : placeholder}
        input={<Input disableUnderline inputProps={{ className: "Select-input" }} />}
        IconComponent={(props) => (
          <i {...props}>
            <div className="Select-icon">{Icons.IconSelect}</div>
          </i>
        )}
        fullWidth={fullWidth}
        className={classNames({
          "Select-root": true,
          "Select-empty": !value,
          "Select-error": errorMessage || error,
        })}
        MenuProps={{ className: "Select-menu" }}>
        {placeholder ? (
          <MenuItem value="placeholder" className="Select-placeholder" disabled>
            <div className="Select-placeholder-text">
              <div>{placeholder}</div>
            </div>
          </MenuItem>
        ) : undefined}
        {items.map((item, index) => (
          <MenuItem key={index} value={itemValueGetter(item) || ""}>
            <div className="Select-item-title">{itemTitleGetter(item)}</div>
          </MenuItem>
        ))}
      </Select>
    </InputWrapper>
  );
};

export { CustomSelect };
