import { CustomDialog } from "../CustomDialog/CustomDialog";
import "./ConfirmDialog.scss";

export interface ConfirmDialogProps {
  confirmMessage: React.ReactNode;
  onClose: () => void;
  open: boolean;
  okAction: () => void;
  cancelAction: () => void;
}

const ConfirmDialog = ({ confirmMessage, onClose, open, okAction, cancelAction }: ConfirmDialogProps) => {
  return (
    <CustomDialog
      onClose={onClose}
      open={open}
      titleIntlId="COMMON_CONFIRM"
      okAction={okAction}
      cancelAction={cancelAction}>
      {confirmMessage}
    </CustomDialog>
  );
};

export { ConfirmDialog };
