import classNames from 'classnames';
import 'loaders.css/loaders.min.css';
import React from 'react';
import Loader, { LoaderType } from 'react-loaders';
import './loader.scss';


export interface LoaderProps {
    position?: 'left' | 'center' | 'right';
    type?: LoaderType;
    classes?: {
        container?: string;
        loader?: string;
    };
}

const LoaderComponent = ({
    position = 'center',
    type = 'ball-pulse',
    classes = {},
}: LoaderProps) => {
    return (
        <div
            className={classNames({
                'loader-container': true,
                [position]: true,
                [classes.container || '']: true,
            })}
        >
            <Loader
                active
                innerClassName={classNames({
                    'app-loader-inner': true,
                    [classes.loader || '']: classes.loader,
                })}
                type={type}
            />
        </div>
    );
};

export { LoaderComponent as Loader };

