import { CreateOrUpdateExhibitionRequest, ExhibitionContent } from "../../Models/Exhibition/Exhibition";
import { Languages } from "../../Models/Languages/Languages";
import { ExhibitionsDetailsViewState } from "./ExhibitionsDetailsViewModel";

export function exhibitionStateToCreateOrUpdateRequest(
  s: ExhibitionsDetailsViewState
): CreateOrUpdateExhibitionRequest {
  const localized_content = Object.assign(
    {},
    ...(Object.keys(s.localized_content) as Languages[]).map(
      (lang): Record<string, ExhibitionContent> => {
        const currentContent = s.localized_content[lang];
        return {
          [lang]: {
            description: currentContent.description,
            name: currentContent.name,
            picture_id: currentContent.picture_id,
            picture_url: currentContent.picture_url,
            news:
              currentContent.news_content !== "" || currentContent.news_title !== "" || currentContent.picture_id
                ? {
                    content: currentContent.news_content,
                    title: currentContent.news_title,
                    picture_id: currentContent.news_picture_id,
                  }
                : undefined,
          },
        };
      }
    )
  ) as Record<Languages, ExhibitionContent>;

  return {
    order: s.order as number,
    localized_content,
  };
}
