import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { GetPaginatedQueryParameter } from "../../Models/Commons/PaginatedQuery";
import { IoC } from "../../Services/ServicesContainer";
import { BaseListViewModel, BaseListViewState, InitialBaseListViewState } from "../Base/BaseListViewModel";
import { Exhibition } from "../../Models/Exhibition/Exhibition";
import { ExhibitionDataServiceName, IExhibitionDataService } from "../../DataServices/ExhibitionDataService";

export type ExhibitionSortFields = "Order" | "Title";
export type ExhibitionListSort = {
  [key in ExhibitionSortFields]: "asc" | "desc" | undefined;
};
const EmptyExhibitionListSort: ExhibitionListSort = {
  Order: undefined,
  Title: undefined,
};

const getSortKey = (sort: ExhibitionListSort): string => {
  const sortKeys = Object.keys(sort) as ExhibitionSortFields[];
  for (const key of sortKeys) {
    const sortValue = sort[key];
    if (sortValue !== undefined) {
      return key + (sortValue === "asc" ? "Ascending" : "Descending");
    }
  }
  return "";
};

export interface ExhibitionsListViewState extends BaseListViewState<Exhibition> {
  filter: string;
  exhibitions: Exhibition[];
  selectedExhibitions: string[];
  sort: ExhibitionListSort;
}

const InitialExhibitionsListViewState: ExhibitionsListViewState = {
  ...InitialBaseListViewState,
  filter: "",
  exhibitions: [],
  selectedExhibitions: [],
  sort: { ...EmptyExhibitionListSort, Order: "asc" },
};

export class ExhibitionsListViewModel extends BaseListViewModel<Exhibition, ExhibitionsListViewState> {
  private readonly exhibitionService: IExhibitionDataService;

  constructor() {
    super(InitialExhibitionsListViewState);
    this.exhibitionService = IoC.get<IExhibitionDataService>(ExhibitionDataServiceName);
  }

  protected getItems(params: GetPaginatedQueryParameter, cts: XHRRequestCanceler) {
    return this.exhibitionService.getExhibitions({ ...params, sort: getSortKey(this.state.sort) }, cts);
  }

  public setSelectedExhibitions = (exhibitions: string[]) => {
    this.setState({ ...this.state, selectedExhibitions: exhibitions });
    this.refreshData();
  };

  public toogleSort = (field: ExhibitionSortFields) => {
    const { sort } = this.state;
    const currentValue = sort[field];

    this.setState({
      ...this.state,
      sort: { ...EmptyExhibitionListSort, [field]: currentValue === "asc" ? "desc" : "asc" },
    });

    this.refreshData();
  };
}
