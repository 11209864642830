import React from 'react';
import { useFocused, useSelected, useSlateStatic } from 'slate-react';
import IconLaunch from '@material-ui/icons/Launch';
import IconLinkOff from '@material-ui/icons/LinkOff';
import { removeLink } from '../../Utils/LinkHandler';
import './Link.scss';

export const Link = ({ attributes, element, children }: any) => {
    const editor = useSlateStatic();
    const selected = useSelected();
    const focused = useFocused();

    return (
        <div className="element-link" style={{ display: 'inline', position: 'relative' }}>
            <a {...attributes} href={element.href}>
                {children}
            </a>
            {selected && focused && (
                <div className="popup" contentEditable={false}>
                    <a href={element.href} rel="noreferrer" target="_blank">
                        <IconLaunch />
                        {element.href}
                    </a>
                    <button onClick={() => removeLink(editor)}>
                        <IconLinkOff />
                    </button>
                </div>
            )}
        </div>
    );
};
