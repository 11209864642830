import { Unsubscribe } from 'redux';
import { AppState } from '../../../../Configs/store/AppReducer';
import { store } from '../../../../Configs/store/store';
import { ErrorHandlerName, IErrorHandler } from '../../../../Libs/xhr/ErrorHandler';
import { Lazy } from '../../../Helpers/Lazy';
import { IToastService, ToastServiceName } from '../../Interfaces/IToastService';
import { IoC } from '../../ServicesContainer';

export interface IBaseReduxService {
    getState: () => AppState;
    dispatch: <T>(action: T) => T;
    subscribe: (listener: (appStore: AppState) => void) => Unsubscribe;
}

export interface WrapErrorConfig {
    disableToast: false
}

export class BaseReduxService implements IBaseReduxService {
    protected readonly toastService: Lazy<IToastService>;
    private readonly errorHandler: Lazy<IErrorHandler>;

    constructor() {
        this.toastService = IoC.getLazy<IToastService>(ToastServiceName);
        this.errorHandler = IoC.getLazy<IErrorHandler>(ErrorHandlerName);
    }

    public getState = (): AppState => store.getState();

    public dispatch = <T>(action: T): T => store.dispatch(action);

    public subscribe = (listener: (appStore: AppState) => void): Unsubscribe => {
        return store.subscribe(() => listener(store.getState()));
    };

    public unsubscribe = (listener: (appStore: AppState) => void): Unsubscribe => {
        return store.subscribe(() => listener(store.getState()));
    };

    public wrapError<TResponse>(executeRequest: () => Promise<TResponse>, config?: WrapErrorConfig) {
        const { disableToast } = config || { disableToast: true };
        try {
            return executeRequest();
        } catch (anyError) {
            this.handleError(anyError, disableToast);
            //* log error
            return Promise.reject(anyError);
        }
    };

    public handleError = (data: any, disableToast = false) => {
        try {
            console.log('handleError : ', { disableToast, data });
            this.errorHandler.value()?.handle(data);
        } catch (error) {
            console.log('handleError', { error });
        }
    };

    public toastApiError = (errCode: string) => {
        this.toastService.value().showError(errCode);
    };
}
