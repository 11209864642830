import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { ArtWorkSection } from "../../../../../Core/Models/ArtWork/ArtWork";
import { CustomButton, CustomButtonClassType } from "../../../../Components/Views/CustomButton/CustomButton";
import { CustomCard } from "../../../../Components/Views/CustomCard/CustomCard";
import { CustomInputText } from "../../../../Components/Views/CustomInputText/CustomInputText";
import { CustomRadioGroup } from "../../../../Components/Views/CustomRadioGroup/CustomRadioGroup";
import { CustomSelect } from "../../../../Components/Views/CustomSelect/CustomSelect";
import { Icons } from "../../../../Components/Views/Icons/Icons";
import { MediaInput } from "../../../../Components/Views/MediaInput/MediaInput";

import "./ArtWorkInformationTab.scss";
import { ArtWorkInformationTabControllerData } from "./ArtWorkInformationTabController";

export interface ArtWorkInformationTabProps {
  artworkController: ArtWorkInformationTabControllerData;
}

const ArtWorkInformationTab = ({ artworkController }: ArtWorkInformationTabProps) => {
  const { vm, state, registerLocalizedField, registerField } = artworkController;

  const artistProps = registerLocalizedField("artist_name");
  const countryProps = registerLocalizedField("country");
  const titleProps = registerLocalizedField("title");
  const yearProps = registerField("year");
  const subTitleProps = registerLocalizedField("subtitle");
  const latProps = registerField("latitude");
  const longProps = registerField("longitude");
  const urlProps = registerField("app_url");
  const shortDescriptionProps = registerLocalizedField("short_description");
  const copyrightsProps = registerLocalizedField("copyrights");

  return (
    <div className="ArtWorkInformationTab-root">
      <CustomCard
        titleIntlId="ArtWorkInformationTab_Cartel"
        classes={{ root: "ArtWorkInformationTab-cartelRoot", body: "ArtWorkInformationTab-cartelBody" }}>
        <div className="col">
          <CustomInputText {...artistProps} labelIntlId="ArtWorkInformationTab_InputArtistLabel" />
          <CustomInputText {...countryProps} labelIntlId="ArtWorkInformationTab_InputCountryLabel" />
          <CustomInputText {...titleProps} labelIntlId="ArtWorkInformationTab_InputTitleLabel" />
          <CustomInputText {...yearProps} labelIntlId="ArtWorkInformationTab_InputYearLabel" />
          <CustomInputText {...subTitleProps} labelIntlId="ArtWorkInformationTab_InputSubTitleLabel" />

          <CustomSelect<ArtWorkSection>
            itemTitleGetter={(item) => item.name}
            items={state.sections}
            itemValueGetter={(item) => item?.id || ""}
            onChange={(item: ArtWorkSection | undefined) => vm.onChangeField("section", item)}
            value={state.section}
            errorMessage={state.fieldsErrors["section"]}
            labelIntlId="ArtWorkInformationTab_InputSectionLabel"
          />
          <CustomInputText
            {...shortDescriptionProps}
            labelIntlId="ArtWorkInformationTab_InputShortDescriptionLabel"
            maxLength={999}
          />
          <CustomInputText
            {...copyrightsProps}
            labelIntlId="ArtWorkInformationTab_InputCopyRightsLabel"
            maxLength={999}
          />
        </div>
        <div className="col">
          <MediaInput
            value={state.localized_content[state.currentLanguage].localPicture}
            onChange={(file) => vm.onChangeLocalizedField("localPicture", file)}
            labelIntlId="ArtWorkInformationTab_InputPictureLabel"
            maxCount={1}
          />
        </div>
      </CustomCard>
      <CustomCard
        titleIntlId="ArtWorkInformationTab_Location"
        classes={{ root: "ArtWorkInformationTab-locationRoot", body: "ArtWorkInformationTab-locationBody" }}>
        <div className="col">
          <CustomInputText {...latProps} labelIntlId="ArtWorkInformationTab_InputLatLabel" classes={{ root: "row" }} />
          <CustomInputText
            {...longProps}
            labelIntlId="ArtWorkInformationTab_InputLongLabel"
            classes={{ root: "row" }}
          />
          <CustomInputText {...urlProps} labelIntlId="ArtWorkInformationTab_InputURLLabel" />
        </div>
        <div className="col">
          <CustomRadioGroup
            label={<FormattedMessage id="ArtWorkInformationTab_InputPlaceLabel" />}
            value={state.is_outside ? "outdoor" : "indoor"}
            onChange={(value) => vm.onChangeField("is_outside", value !== "indoor")}
            items={[
              { label: <FormattedMessage id="ArtWorkInformationTab_Indoor" />, value: "indoor" },
              { label: <FormattedMessage id="ArtWorkInformationTab_OutDoor" />, value: "outdoor" },
            ]}
            classes={{ root: "row" }}
          />
        </div>
      </CustomCard>
      <CustomCard
        titleIntlId="ArtWorkInformationTab_Publication"
        classes={{ root: "ArtWorkInformationTab-publicationRoot", body: "ArtWorkInformationTab-publicationBody" }}
        renderHeader={
          <div className="ArtWorkInformationTab-publicationTab-header-root">
            <div className="ArtWorkInformationTab-publicationTab-header-title">
              <FormattedMessage id="ArtWorkInformationTab_Publication" />
            </div>
            <div
              className={classNames({
                "ArtWorkInformationTab-publicationTab-header-statusColor": true,
                [vm.getArtWorkStatus()]: true,
              })}
            />
            <div className="ArtWorkInformationTab-publicationTab-header-status">{vm.getArtWorkStatus()}</div>
          </div>
        }>
        {state.mode === "edit" && (
          <CustomButton
            variant={CustomButtonClassType.Standard}
            title={<FormattedMessage id="ArtWorkInformationTab_PublishTitle" />}
            className="ArtWorkInformationTab-publishButton"
            onClick={vm.onPublish}
            disabled={state.is_published}
          />
        )}
        {state.mode === "edit" && (
          <CustomButton
            variant={CustomButtonClassType.Outlined}
            title={<FormattedMessage id="ArtWorkInformationTab_CancelPublishTitle" />}
            className="ArtWorkInformationTab-cancelButton"
            onClick={vm.onCancelPublish}
            disabled={!state.is_published}
          />
        )}
        <CustomButton
          variant={CustomButtonClassType.Success}
          title={<FormattedMessage id="ArtWorkInformationTab_SaveTitle" />}
          className="ArtWorkInformationTab-saveButton"
          onClick={vm.onSubmit}
          icon={Icons.IconSave}
        />
      </CustomCard>
    </div>
  );
};

export { ArtWorkInformationTab };
