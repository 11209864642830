import React, { useState, useEffect } from "react";

const useVideoPlayer = (videoRef: any) => {
  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    progress: 0,
    speed: 1,
    isMuted: false,
  });

  const togglePlay = () => {
    setPlayerState({
      ...playerState,
      isPlaying: !playerState.isPlaying,
    });
  };

  useEffect(() => {
    playerState.isPlaying ? videoRef.current.play() : videoRef.current.pause();
  }, [playerState.isPlaying, videoRef]);

  const handleOnTimeUpdate = () => {
    const progress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
    setPlayerState({
      ...playerState,
      progress,
    });
  };

  const handleVideoProgress = (event: React.FormEvent<HTMLInputElement>) => {
    const manualChange = Number((event.target as HTMLInputElement).value);
    videoRef.current.currentTime = (videoRef.current.duration / 100) * manualChange;
    setPlayerState({
      ...playerState,
      progress: manualChange,
    });
  };

  const handleVideoSpeed = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const speed = Number((event.target as HTMLSelectElement).value);
    videoRef.current.playbackRate = speed;
    setPlayerState({
      ...playerState,
      speed,
    });
  };

  const toggleMute = () => {
    setPlayerState({
      ...playerState,
      isMuted: !playerState.isMuted,
    });
  };

  useEffect(() => {
    playerState.isMuted ? (videoRef.current.muted = true) : (videoRef.current.muted = false);
  }, [playerState.isMuted, videoRef]);

  return {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    toggleMute,
  };
};

export default useVideoPlayer;
