import { FormattedMessage } from "react-intl";
import "./NavBar.scss";

import { NavBarLink } from "./NavBarLink/NavBarLink";
import Logo from "../../../assets/logo/logo.png";
import { AppRoutes } from "../../../../Core/App/AppRoutes";
import { useSelector } from "react-redux";
import { AppState } from "../../../../Configs/store/AppReducer";
import { MeInfo } from "../../../../Core/Models/Me/Me";
import { BoRoles } from "../../../../Core/Models/BoUsers/BoUsersResponse";

const NavBar = () => {
  const me = useSelector<AppState>((s) => s.Me.data) as MeInfo;
  return (
    <div className="NavBar-root">
      <div className="NavBar-logo">
        <img src={Logo} alt="Logo" />
      </div>

      <NavBarLink title={<FormattedMessage id="NavBar_ExhibitionTitle" />} to={AppRoutes.EXHIBITIONS_LIST} />
      <NavBarLink title={<FormattedMessage id="NavBar_ArtWorkTitle" />} to={AppRoutes.ART_WORK_LIST} />
      <NavBarLink title={<FormattedMessage id="NavBar_VideoTitle" />} to={AppRoutes.VIDEO_LIST} />
      <NavBarLink title={<FormattedMessage id="NavBar_NewsTitle" />} to={AppRoutes.NEWS_DETAILS} />
      {me.role === BoRoles.Administrator && (
        <NavBarLink title={<FormattedMessage id="NavBar_BOUserTitle" />} to={AppRoutes.BO_USER_LIST} />
      )}
    </div>
  );
};

export { NavBar };
