export enum ExhibitionStatus {
  Main = "main",
  Others = "others",
}

export type ExhibitionStatusWithI18nType = { value: ExhibitionStatus; label: string };
export const ExhibitionStatusWithI18n = [
  {
    value: ExhibitionStatus.Main,
    label: "ExhibitionDetailsPage_StatusMain",
  },
  {
    value: ExhibitionStatus.Others,
    label: "ExhibitionDetailsPage_StatusOthers",
  },
];
