import { Editor, Element, Node, Path, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { CustomElement, CustomText, ElementType, EmptyText, LinkElement, ParagraphElement } from "../CustomTypes.d";
import { createLinkNode, createParagraphNode } from "../Utils/CreateCustomNodes";

const withKeyCommands = (editor: Editor) => {
  const {
    deleteBackward,
    insertBreak,
    insertSoftBreak,
    isVoid,
    insertData,
    insertText,
    onChange,
    normalizeNode
  } = editor;

  editor.onChange = (...args) => {
    const { selection } = editor;
    if (selection) {
      const parentPath = Path.parent(selection.focus.path);
      const parentNode = Node.get(editor, parentPath) as CustomElement;
      if (parentNode.type === ElementType.video) {
        ReactEditor.focus(editor);
      }
    }
    onChange(...args);
  };

  // We're checking if we're on a void node (image) to delete the image if that's the case
  editor.deleteBackward = (...args) => {
    const parentPath = Path.parent(editor.selection!.focus.path);
    const parentNode = Node.get(editor, parentPath);
    // @ts-ignore
    if (isVoid(parentNode)) {
      Transforms.removeNodes(editor, { at: parentPath });
      if (editor.children.length <= 0) {
        editor.children = [
          {
            type: ElementType.paragraph,
            children: [{ text: "" }]
          }
        ];
      } else {
        Transforms.move(editor, { unit: "offset" });
      }
    } else {
      deleteBackward(...args);
    }
  };

  // We're checking if we're on a void node (image) to be able to insert a line break if that's the case
  editor.insertBreak = (...args) => {
    const parentPath = Path.parent(editor.selection!.focus.path);
    const parentNode = Node.get(editor, parentPath);

    // @ts-ignore
    if (isVoid(parentNode)) {
      const nextPath = Path.next(parentPath);
      Transforms.insertNodes(editor, createParagraphNode() as ParagraphElement, {
        at: nextPath,
        select: true
      });
    } else {
      insertBreak(...args);
    }
  };

  // We're checking if we're on a void node (image) to be able to insert a soft line break if that's the case
  editor.insertSoftBreak = (...args) => {
    const parentPath = Path.parent(editor.selection!.focus.path);
    const parentNode = Node.get(editor, parentPath);

    // @ts-ignore
    if (isVoid(parentNode)) {
      Transforms.insertNodes(editor, createParagraphNode() as ParagraphElement, {
        at: parentPath,
        select: true
      });
    } else {
      insertSoftBreak(...args);
    }
  };

  // We're checking if the content we're pasting is a link to format it accordingly
  editor.insertData = (...args) => {
    const copiedText = args[0].getData("text");
    let url;
    try {
      url = new URL(copiedText);
    } catch (_) {
      insertData(...args);
    }
    if (url?.protocol === "http:" || url?.protocol === "https:") {
      const link = createLinkNode(copiedText, "Lien") as LinkElement;
      Transforms.insertNodes(editor, link, { select: true });
    }
  };

  // We're checking if the content we're trying to write next to is a link and if so we offset the cursor before to avoid writing in the link
  editor.insertText = (...args) => {
    const { selection } = editor;
    if (!!selection) {
      const parentPath = Path.parent(selection.focus.path);
      const parentNode = Node.get(editor, parentPath) as CustomElement;
      if (parentNode.type === ElementType.link) {
        const linkText = parentNode.children[0] as EmptyText;
        // We're checking if we're at the end of the link and if so we offset the editor to avoid writing into the link
        if (linkText.text.length === selection.focus.offset) {
          Transforms.move(editor, { unit: "offset" });
        }
        insertText(...args);
      } else {
        insertText(...args);
      }
    } else {
      insertText(...args);
    }
  };

  // Normalize mode is here to remove link nodes whose text value is empty string (if you delete link by removing each character).
  // It also prevents a bug when you remove all text from editor but keep formats on (italic, bold, underline) by reseting the editor state
  editor.normalizeNode = (entry, options) => {
    const [node, path] = entry;
    if (Element.isElement(node) && node.type === ElementType.paragraph) {
      const children = Array.from(Node.children(editor, path));
      for (let [child, childPath] of children) {
        const childText = child as CustomText;
        if (Element.isElement(child) && child.type === ElementType.link && child.children[0].text === "") {
          //VJU 13/12/2023 : si on supprime le dernier caractère d'un a, on supprime le a et on rajoute une string vide pour pour avoir un élément valable dans le noeud (slate n'aime pas les noeuds vide)
          Transforms.removeNodes(editor, { at: childPath });
          Transforms.insertNodes(
            editor,
            {
              type: ElementType.paragraph,
              children: [{ text: "" }]
            },
            { at: childPath }
          );
        } else if ((childText.text === "" && (childText.underlined || childText.italic || childText.bold))) {
          if (children.length === 1) {
            //VJU 13/12/2023 : si on supprime le dernier caractère d'un paragraphe avec des modifiers, on reset les modifiers
            Transforms.setNodes(editor, {
              underlined: undefined,
              italic: undefined,
              bold: undefined
            }, { at: childPath });
          } else {
            //VJU 13/12/2023 : pas de protection ici, parc qu'on sait qu'il y a un plusieurs éléments dans le noeud
            Transforms.removeNodes(editor, { at: childPath });
          }
        }
      }
    }
    normalizeNode(entry, options);
  };

  return editor;
};

export default withKeyCommands;
