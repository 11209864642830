import React from 'react';
import { useSlateStatic } from 'slate-react';
import { MarkButtons } from '../Components/MarkButtons/MarkButtons';
import { LinkButton } from '../Components/Link/LinkButton/LinkButton';
import { ImageButton } from '../Components/Image/ImageButton/ImageButton';
import { XRichTextProps } from '..';
import { VideoButton } from '../Components/Video/VideoButton/VideoButton';
import './Toolbar.scss';
import { HTMLDeserializer, HTMLSerializer } from '../Utils/Serializers';
import { useRTEConfig } from '../Context/RichtextConfig';
import { Button } from '../Components/MarkButtons/Button/Button';
import { Assets } from '../Assets/Assets';

export interface ToolbarProps extends XRichTextProps {}

export const Toolbar = ({ videoList, busyLoadingVideos }: ToolbarProps) => {
    const [logButton, setLogButton] = React.useState(false);
    const editor = useSlateStatic();
    const RTEConfig = useRTEConfig();

    const logEditor = () => {
        const serialized = HTMLSerializer(editor);
        const deserialized = HTMLDeserializer(serialized);
        console.log('Logs', { editor, serialized, deserialized });
    };

    return (
        <div className="editor-toolbar">
            <MarkButtons />
            <LinkButton />
            <ImageButton />
            {videoList && <VideoButton videoList={videoList} busyLoadingVideos={busyLoadingVideos} />}
            {RTEConfig.isDev?.valueOf() && (
                <Button
                    onClick={logEditor}
                    onMouseOver={() => setLogButton(!logButton)}
                    onMouseOut={() => setLogButton(!logButton)}
                >
                    {logButton ? <Assets.Log fill="#000000" /> : <Assets.Log />}
                </Button>
            )}
        </div>
    );
};
