import { ApiDataResponse, BasePaginatedListResponse } from "../ApiData";
import { ArtWorkInformationContent } from "../../ViewModels/ArtWork/ArtWorkInformationTabViewModel";
import { Languages } from "../Languages/Languages";

export interface ArtWorkSection {
  id: string;
  name: string;
  is_main_section: boolean;
}

export enum ArtWorkStatus {
  Brouillon = "Brouillon",
  Published = "Published",
  Cancelled = "Cancelled",
}

export interface ArtWork {
  id: string;
  localized_content: Record<Languages, ArtWorkInformationContent>;
  year: string;
  picture_url: string;
  section: ArtWorkSection;
  latitude: string;
  longitude: string;
  is_outside: boolean;
  app_url: string;
  ar_file_url: string;
  is_published: boolean;
  is_cancelled: boolean;
  published_date: Date;
}

export interface LocalizedContentRequest {
  artist_name: string;
  country: string;
  title: string;
  picture_id: string;
  subtitle: string;
  description: string;
  short_description: string;
  copyrights: string;
}

export interface CreateOrUpdateArtworkRequest {
  localized_content: Record<Languages, LocalizedContentRequest>;
  year: string;
  section_id: string;
  latitude: string;
  longitude: string;
  is_outside: boolean;
  is_published: boolean;
  published_date: Date | undefined;
}

export type ArtWorkListResponse = BasePaginatedListResponse<ArtWork>;

export type ArtWorkDetailsResponse = ApiDataResponse<ArtWork>;

export type ArtWorkCreateResponse = ApiDataResponse<string>;

export type ArtWorkSectionsResponse = ApiDataResponse<ArtWorkSection[]>;
