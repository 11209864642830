import { IconButton } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { FormattedMessage } from "react-intl";
import { CustomButton, CustomButtonClassType } from "../../CustomButton/CustomButton";
import { Icons } from "../../Icons/Icons";

import "./CustomDialog.scss";

export interface CustomDialogProps {
  open: boolean;
  titleIntlId: string;
  onClose: () => void;
  children?: React.ReactNode;
  renderActions?: () => React.ReactNode;
  hideCloseButton?: boolean;
  okAction?: () => void;
  cancelAction?: () => void;
}

const CustomDialog = ({
  open,
  titleIntlId,
  onClose,
  children,
  renderActions,
  hideCloseButton,
  cancelAction,
  okAction,
}: CustomDialogProps) => {
  return (
    <Dialog open={open} classes={{ paper: "CustomDialog-root" }}>
      <div className="CustomDialog-header">
        <FormattedMessage id={titleIntlId} />
        {!hideCloseButton && (
          <IconButton onClick={onClose} className="CustomDialog-header-closeButton">
            {Icons.IconClose}
          </IconButton>
        )}
      </div>
      <div className="CustomDialog-body">{children}</div>
      {renderActions ? (
        <div className="CustomDialog-actions">{renderActions()}</div>
      ) : (
        <div className="CustomDialog-actions">
          <CustomButton
            className="CustomDialog-actions-cancel"
            variant={CustomButtonClassType.Danger}
            title={<FormattedMessage id="COMMON_CANCEL" />}
            onClick={cancelAction}
          />
          <CustomButton
            className="CustomDialog-actions-submit"
            variant={CustomButtonClassType.Standard}
            title={<FormattedMessage id="COMMON_VALIDATE" />}
            onClick={okAction}
          />
        </div>
      )}
    </Dialog>
  );
};

export { CustomDialog };
