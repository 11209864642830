import { history } from "../../../Configs/history/history";
import { getDialogService } from "../../../Core/Services/Interfaces/IDialogService";
import { getNavigationService } from "../../../Core/Services/Interfaces/INavigationService";

class CustomPrompt {
  unblock = undefined;

  onBlock = (tx) => {
    if (tx.pathname === history.location.pathname) {
      this.unblock();
      getNavigationService()
        .value()
        .goTo(tx.pathname + tx.hash);
    } else {
      getDialogService()
        .value()
        .showConfirm("COMMON_NOT_SAVED", () => {
          this.unblock();
          getNavigationService()
            .value()
            .goTo(tx.pathname + tx.hash);
        });
      return false;
    }
  };

  updateBlock = (block) => {
    if (block) {
      console.log("BLOCK");
      this.unblock = history.block(this.onBlock);
    } else if (this.unblock) {
      this.unblock();
      console.log("UNBLOCK");
      this.unblock = undefined;
    }
  };
}

export { CustomPrompt };
