import { XHRRequestCanceler } from "../../Libs/xhr/XHRRequestCanceler";
import { Constants } from "../Constants";
import { BaseApiDataResponse } from "../Models/ApiData";
import {
  AuthenticationResponse,
  LoginRequest,
  RefreshRequest,
  ResetPasswordAuthenticate,
} from "../Models/Authentication/AuthResponse";
import { BaseDataService } from "./Base/BaseDataService";

export const END_POINTS = {
  LOGIN: "/api/v1/admins/login",
  LOGOUT: "/api/v1/admins/logout",
  REFRESH: "/api/v1/admins/refresh",
  RESET_PASSWORD: "/api/v1/admins/password/forgotten",
  RESET_PASSWORD_AUTHENTICATE: `/api/v1/admins/password/reset`,
};

export interface IAuthDataService {
  login: (email: string, password: string, cts: XHRRequestCanceler) => Promise<AuthenticationResponse>;
  logout: () => Promise<BaseApiDataResponse>;
  refresh: (refreshToken: string, cts?: XHRRequestCanceler) => Promise<AuthenticationResponse>;
  forgotPassword: (email: string, cts: XHRRequestCanceler) => Promise<BaseApiDataResponse>;
  resetPasswordAndAuthenticate: (
    token: string,
    email: string,
    newPassword: string,
    cts: XHRRequestCanceler
  ) => Promise<BaseApiDataResponse>;
}

class AuthDataService extends BaseDataService implements IAuthDataService {
  async login(email: string, password: string, cts: XHRRequestCanceler): Promise<AuthenticationResponse> {
    const body: LoginRequest = {
      email: email,
      password: password,
      client_id: Constants.CLIENT_ID,
      client_secret: Constants.CLIENT_SECRET,
      grant_type: "Password",
    };
    return this.post<AuthenticationResponse>(END_POINTS.LOGIN, body, cts, {
      checkError: false,
      noAuth: true,
    });
  }
  async logout(): Promise<BaseApiDataResponse> {
    return this.post<BaseApiDataResponse>(END_POINTS.LOGOUT, {});
  }
  async refresh(refreshToken: string, cts?: XHRRequestCanceler): Promise<AuthenticationResponse> {
    const body: RefreshRequest = {
      refresh_token: refreshToken,
      client_id: Constants.CLIENT_ID,
      client_secret: Constants.CLIENT_SECRET,
    };
    return this.post<AuthenticationResponse>(END_POINTS.REFRESH, body, cts, {
      checkError: false,
      noAuth: true,
    });
  }

  async forgotPassword(email: string, cts: XHRRequestCanceler): Promise<BaseApiDataResponse> {
    const body = {
      email: email,
    };

    return this.post<AuthenticationResponse>(END_POINTS.RESET_PASSWORD, body, cts, {
      noAuth: true,
    });
  }

  async resetPasswordAndAuthenticate(
    token: string,
    email: string,
    newPassword: string,
    cts: XHRRequestCanceler
  ): Promise<BaseApiDataResponse> {
    const body: ResetPasswordAuthenticate = {
      email: email,
      new_password: newPassword,
      validation_code: token,
    };
    return this.post<BaseApiDataResponse>(END_POINTS.RESET_PASSWORD_AUTHENTICATE, body, cts, {
      checkError: false,
      noAuth: true,
    });
  }
}

const AuthDataServiceName = "AuthDataService";
export { AuthDataService, AuthDataServiceName };
