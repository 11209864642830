import { XHRRequestConfig } from "../../Libs/xhr/XHRRequest";
import { XHRRequestCanceler } from "../../Libs/xhr/XHRRequestCanceler";
import { ApiUrls } from "../Models/ApiUrls";
import { MeResponse } from "../Models/Me/Me";
import { BaseDataService } from "./Base/BaseDataService";

export interface IMeDataService {
  getMyInfo: (cts?: XHRRequestCanceler, config?: XHRRequestConfig) => Promise<MeResponse>;

  checkAccess: (authorization: string) => Promise<MeResponse>;
}

class MeDataService extends BaseDataService implements IMeDataService {
  getMyInfo = (cts?: XHRRequestCanceler, config?: XHRRequestConfig): Promise<MeResponse> => {
    return this.get<MeResponse>(ApiUrls.ME, cts, config);
  };

  checkAccess = (authorization: string): Promise<MeResponse> => {
    return this.get<MeResponse>(
      ApiUrls.ME,
      undefined, //Axios CANCELATION TOKEN
      {
        headers: { Authorization: authorization },
        checkError: false,
      },
      true //no auth from redux store #AppsStore
    );
  };
}

const MeDataServiceName: string = "MeDataService";

export { MeDataService, MeDataServiceName };
