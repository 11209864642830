import { IntlShape } from "react-intl";

import { Messages } from "../Services/Data/I18n/typings/LocalMessageActionTypes";

export class MessageUtils {
  public static isEmpty = (messages: Messages | undefined): boolean => {
    if (!messages) {
      return true;
    }
    return !Object.keys(messages)
      .map((k) => Boolean(messages[k]))
      .reduce((p, c) => (p ? p : c), false);
  };

  public static checkEmptyField = (field: string, value: any, messages: Messages, errorMessage: string): void => {
    if (!value) {
      messages[field] = errorMessage;
    }
  };

  public static get = (messageProvider: IntlShape, id: string): string => {
    return messageProvider.formatMessage({ id });
  };
}
