import React from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { DownloadOptions, IToastService } from '../../Core/Services/Interfaces/IToastService';
import { CustomToast, CustomToastType } from '../Components/Views/CustomToast/CustomToast';

class ToastService implements IToastService {
    public showSuccess(content: string): React.ReactText {
        return toast.success(CustomToast({ type: CustomToastType.SUCCESS, content }));
    }
    public showError (content: string): React.ReactText {
        return toast.error(CustomToast({ type: CustomToastType.ERROR, content }));
    }

    public showInfo(content: string): React.ReactText {
        return toast.info(CustomToast({ type: CustomToastType.INFO, content }));
    }

    public showSuccessUpdate(): React.ReactText {
        return toast(
            CustomToast({
                type: CustomToastType.SUCCESS,
                content: <FormattedMessage id="Toast_GenericSuccessUpdateData" />,
            })
        );
    }

    //* CUSTOM DOWNLOAD TOAST
    public showDownloadInfo(downloadOptions: DownloadOptions): React.ReactText {
        return '';
    }

    public updateDownloadInfo(toastId: React.ReactText, downloadOptions: DownloadOptions): React.ReactText {
        return '';
    }

    public notImplemented(): React.ReactText {
        return this.showInfo('Not implemented yet');
    }

    public dismiss(toastId: React.ReactText): void {
        toast.dismiss(toastId);
    }
}

export { ToastService };

