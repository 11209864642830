import { UploadData, UploadDataType } from "../../Libs/xhr/ApiClient";
import { XHRRequestCanceler } from "../../Libs/xhr/XHRRequestCanceler";
import { ApiUrls } from "../Models/ApiUrls";
import { SetVideoTitleBody, UploadMediaDataResponse, UploadVideoDataResponse } from "../Models/Files/File";
import { BaseDataService } from "./Base/BaseDataService";
import { VideoDetailsResponse, VideoListResponse, VideoThumbnailResponse } from "../Models/Video/Video";
import { BaseApiDataResponse } from "../Models/ApiData";
import { CancelTokenSource } from "axios";

export interface IMediaDataService {
  uploadImage: (
    file: File,
    onProgress?: (value: number) => void,
    cts?: CancelTokenSource,
  ) => Promise<UploadMediaDataResponse>;

  uploadVideo: (
    file: File,
    onProgress?: (value: number) => void,
    cts?: CancelTokenSource,
  ) => Promise<UploadVideoDataResponse>;

  setVideoTitle: (body: SetVideoTitleBody, cts?: XHRRequestCanceler) => Promise<BaseApiDataResponse>;

  updateVideoTitle: (
    videoLibraryId: string,
    body: SetVideoTitleBody,
    cts?: XHRRequestCanceler
  ) => Promise<BaseApiDataResponse>;

  getVideoDetails: (videoLibraryId: string, cts: XHRRequestCanceler) => Promise<VideoDetailsResponse>;

  getVideoList: (cts: XHRRequestCanceler) => Promise<VideoListResponse>;

  getVideoThumbnailUrl: (videoId: string, cts: XHRRequestCanceler) => Promise<VideoThumbnailResponse>;

  createVideoThumbnailUrl: (videoId: string, offset: number, cts: XHRRequestCanceler) => Promise<BaseApiDataResponse>;
}

class MediaDataService extends BaseDataService implements IMediaDataService {
  uploadImage = (
    file: File,
    onProgress?: (value: number) => void,
    cts?: CancelTokenSource,
  ): Promise<UploadMediaDataResponse> => {
    const dataToUpload: UploadData = {
      type: UploadDataType.File,
      value: file,
    };
    return this.upload<UploadMediaDataResponse>(
      ApiUrls.MEDIA_UPLOAD_IMAGE,
      dataToUpload,
      { cancelToken: cts ? cts.token : undefined },
      onProgress
    );
  };

  uploadVideo = (
    file: File,
    onProgress?: (value: number) => void,
    cts?: CancelTokenSource,
  ): Promise<UploadVideoDataResponse> => {
    const dataToUpload: UploadData = {
      type: UploadDataType.File,
      value: file,
    };
    return this.upload<UploadVideoDataResponse>(ApiUrls.MEDIA_UPLOAD_VIDEO, dataToUpload, {
      cancelToken: cts ? cts.token : undefined,
      timeout: 1000000,
    });
  };

  setVideoTitle = (body: SetVideoTitleBody, cts?: XHRRequestCanceler): Promise<BaseApiDataResponse> => {
    return this.post<BaseApiDataResponse>(ApiUrls.SET_VIDEO_TITLE, body, cts);
  };

  updateVideoTitle = (
    videoLibraryId: string,
    body: SetVideoTitleBody,
    cts?: XHRRequestCanceler
  ): Promise<BaseApiDataResponse> => {
    return this.put<BaseApiDataResponse>(ApiUrls.UPDATE_VIDEO_TITLE(videoLibraryId), body, cts);
  };

  getVideoDetails = (videoLibraryId: string, cts: XHRRequestCanceler): Promise<VideoDetailsResponse> => {
    return this.get<VideoDetailsResponse>(ApiUrls.GET_VIDEO_DETAILS(videoLibraryId), cts);
  };

  getVideoList = (cts: XHRRequestCanceler): Promise<VideoListResponse> => {
    return this.get<VideoListResponse>(ApiUrls.GET_VIDEO_LIST, cts);
  };

  getVideoThumbnailUrl = (videoId: string, cts: XHRRequestCanceler): Promise<VideoThumbnailResponse> => {
    return this.get<VideoThumbnailResponse>(ApiUrls.GET_VIDEO_THUMBNAIL(videoId));
  };

  createVideoThumbnailUrl = (
    videoId: string,
    offset: number,
    cts: XHRRequestCanceler
  ): Promise<BaseApiDataResponse> => {
    return this.post<BaseApiDataResponse>(
      ApiUrls.UPDATE_VIDEO_THUMBNAIL(videoId),
      { preview_seconds_offset: offset },
      cts
    );
  };
}

const MediaDataServiceName: string = "MediaDataService";

export { MediaDataService, MediaDataServiceName };
