import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { IoC } from "../../Services/ServicesContainer";
import { BaseListViewState, InitialBaseListViewState } from "../Base/BaseListViewModel";
import { IMediaDataService, MediaDataServiceName } from "../../DataServices/MediaDataService";
import { BaseViewModel } from "../Base/BaseViewModel";
import { Video, VideoListResponse } from "../../Models/Video/Video";

export interface VideoListViewState extends BaseListViewState<Video> {
  filter: string;
  videoList: Video[];
  selectedVideo: string[];
}

const InitialVideoListViewState: VideoListViewState = {
  ...InitialBaseListViewState,
  filter: "",
  videoList: [],
  selectedVideo: [],
};

export class VideoListViewModel extends BaseViewModel<VideoListViewState> {
  private readonly mediaDataService: IMediaDataService;

  constructor() {
    super(InitialVideoListViewState);
    this.mediaDataService = IoC.get<IMediaDataService>(MediaDataServiceName);
    this.init();
  }

  private init = () => {
    this.WrapError(
      async (cts: XHRRequestCanceler) => {
        const response: VideoListResponse = await this.getItems(cts);
        this.setState({ ...this.state, items: response.data });
      },
      { withBusy: true }
    );
  };

  private getItems(cts: XHRRequestCanceler) {
    return this.mediaDataService.getVideoList(cts);
  }
}
