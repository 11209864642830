import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { IMediaDataService, MediaDataServiceName } from "../../DataServices/MediaDataService";
import { INewsDataService, NewsDataServiceName } from "../../DataServices/NewsDataService";
import { FileInputValue } from "../../Models/Files/File";
import { Messages } from "../../Services/Data/I18n/typings/LocalMessageActionTypes";
import { IoC } from "../../Services/ServicesContainer";
import { MessageUtils } from "../../Utils/MessageUtils";
import { ValidatorsUtils } from "../../Utils/ValidatorsUtils";
import { BaseEditOrCreateViewModel } from "../Base/BaseEditOrCreateViewModel";
import { BaseViewState } from "../Base/BaseViewModel";
import { Languages } from "../../Models/Languages/Languages";
import { validateLocalizedContent } from "../Exhibitions/validateLocalizedContent";
import { uploadLocalizedContentPictures } from "../Exhibitions/uploadLocalizedContentPictures";
import { newsDataResponseToNewsDetailsViewState } from "./newsDataResponseToNewsDetailsViewState";
import { newsDetailsViewStatetoCreateNewsRequest } from "./newsDetailsViewStatetoCreateNewsRequest";

export interface NewsDetailsContent {
  title: string;
  content: string;
  localPicture: FileInputValue[];
  picture_id: string;
  picture_url: string;
}

export interface NewsDetailsViewState extends BaseViewState {
  localized_content: Record<Languages, NewsDetailsContent>;
  currentLanguage: Languages;
  id: string | undefined;
  fieldsErrors: Messages;
  invalidCredentials: boolean;
  mode: "create" | "edit";
}

export const DefaultLocalizedContent: NewsDetailsContent = {
  title: "",
  content: "",
  localPicture: [],
  picture_id: "",
  picture_url: "",
};

export const NewsLocalizedContent = { fr: DefaultLocalizedContent, nl: DefaultLocalizedContent };

export const InitialNewsDetailsViewState: NewsDetailsViewState = {
  localized_content: { fr: DefaultLocalizedContent, nl: DefaultLocalizedContent },
  currentLanguage: "fr",
  id: undefined,
  fieldsErrors: [],
  invalidCredentials: false,
  mode: "create",
};

export class NewsDetailsViewModel extends BaseEditOrCreateViewModel<NewsDetailsViewState> {
  private readonly newsDataService: INewsDataService;
  protected readonly mediaService: IMediaDataService;

  constructor() {
    super(InitialNewsDetailsViewState);
    this.newsDataService = IoC.get<INewsDataService>(NewsDataServiceName);
    this.mediaService = IoC.get<IMediaDataService>(MediaDataServiceName);
  }

  public onChangeLocalizedField = (field: any, value: any) => {
    this.setState({
      ...this.state,
      localized_content: {
        ...this.state.localized_content,
        [this.state.currentLanguage]: {
          ...this.state.localized_content[this.state.currentLanguage],
          [field]: value,
        },
      },
      modified: true,
      fieldsErrors: {
        ...this.state.fieldsErrors,
        [field as string]: undefined,
      },
    });
  };

  public changeCurrentLanguage = (value: Languages) => {
    this.setState({
      ...this.state,
      currentLanguage: value,
    });
  };

  public getEditItemState = async (itemId: string, cts: XHRRequestCanceler): Promise<NewsDetailsViewState> => {
    const response = await this.newsDataService.getNews(cts);
    return newsDataResponseToNewsDetailsViewState(response, this.state);
  };

  protected validateFields = () => {
    const errors: Messages = {};
    const requiredFieldError = this.getString("COMMON_REQUIRED_FIELD");

    errors.localized_content = validateLocalizedContent(this.state.localized_content, (content) => {
      const locErrors: Messages = {};
      ValidatorsUtils.checkRequired(content.title, locErrors, "title", requiredFieldError);
      ValidatorsUtils.checkRequired(content.content, locErrors, "content", requiredFieldError);

      return {
        isEmpty: false, // always false, the user need to complete the form to publish
        errors: locErrors,
      };
    });

    this.setState({ ...this.state, fieldsErrors: errors });
    return MessageUtils.isEmpty(errors);
  };

  protected save = async (cts: XHRRequestCanceler) => {
    const updatedContent = await uploadLocalizedContentPictures(this.mediaService, this.state.localized_content, cts);
    this.setState({ ...this.state, localized_content: updatedContent });

    this.setState({ ...this.state, modified: false });
    const response = await this.newsDataService.createNews(newsDetailsViewStatetoCreateNewsRequest(this.state), cts);
    if (response.is_success) {
      this.toastService.value().showSuccessUpdate();
      const newState: NewsDetailsViewState = await this.getEditItemState("empty", cts);
      this.setState({ ...newState });
    } else {
      this.toastService.value().showError("Erreur lors de la modification de l'actualité");
    }
  };
}
