import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { OptionalRouteParams } from "../../../../Core/Models/RouteParams";
import {
  ExhibitionsDetailsViewModel,
  ExhibitionsDetailsViewState,
} from "../../../../Core/ViewModels/Exhibitions/ExhibitionsDetailsViewModel";
import { ViewModelLocator } from "../../../../Core/ViewModels/ViewModelLocator";
import { useInputTextFieldProps } from "../../../Hooks/Form/useInputProps";
import { useLifecycle } from "../../../Hooks/Lifecycle/useLifecycle";
import { CustomPrompt } from "../../../Hooks/Prompt/CustomPrompt";

export const useExhibitionDetailsPageController = () => {
  const { id } = useParams<OptionalRouteParams>();

  const { vm, state } = useLifecycle<ExhibitionsDetailsViewState, ExhibitionsDetailsViewModel>({
    getRef: () => ViewModelLocator.ExhibitionsDetailsVM,
    setRefValue: (value) => (ViewModelLocator.ExhibitionsDetailsVM = value),
    newInstance: () => new ExhibitionsDetailsViewModel(),
    logName: "ExhibitionsDetailsVM",
  });

  useEffect(() => {
    vm.onItemChange(id);
  }, [id, vm]);

  const prompt = useRef(new CustomPrompt());

  useEffect(() => {
    prompt.current.updateBlock(state.modified);
  }, [state.modified]);

  const currentLanguageState = {
    ...state.localized_content[state.currentLanguage],
    fieldsErrors: state.fieldsErrors?.localized_content?.[state.currentLanguage] ?? {},
  };

  const registerLocalizedField = useInputTextFieldProps(currentLanguageState, vm.onChangeLocalizedField, {
    hideLabel: true,
    i18nPrefix: "ExhibitionDetailsPage",
  });

  const registerField = useInputTextFieldProps(state, vm.onChangeField, {
    hideLabel: true,
    i18nPrefix: "ExhibitionDetailsPage",
  });

  return { vm, state, currentLanguageState, registerLocalizedField, registerField };
};
