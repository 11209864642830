import React from 'react';
import classNames from 'classnames';
import Loader, { LoaderType } from 'react-loaders';
import './RTEImageLoader.scss';

export interface LoaderProps {
    position?: 'left' | 'center' | 'right';
    type?: LoaderType;
    color?: string;
    classes?: {
        container?: string;
        loader?: string;
    };
}

export const RTEImageLoader = ({ position = 'left', type = 'ball-beat', color = '#2A384B', classes }: LoaderProps) => {
    const { container: containerClasses = '', loader: loaderClasses = '' } = classes || {};

    const loaderContainerClassNames = classNames('my-loader-container', {
        [position]: true,
        [containerClasses]: containerClasses,
    });

    return (
        <div className={loaderContainerClassNames}>
            <Loader
                innerClassName={classNames('my-loader', {[loaderClasses]: loaderClasses })}
                type={type}
                active
                {...{ color }}
            />
        </div>
    );
};