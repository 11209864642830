import { FormattedMessage, useIntl } from "react-intl";
import { CustomInputText } from "../../Components/Views/CustomInputText/CustomInputText";
import { CustomSelect } from "../../Components/Views/CustomSelect/CustomSelect";
import { CustomSwitch } from "../../Components/Views/CustomSwitch/CustomSwitch";
import { useInputTextFieldProps } from "../../Hooks/Form/useInputProps";
import { AuthenticatedPageLayout } from "../AuthenticatedPageLayout/AuthenticatedPageLayout";
import * as yup from "yup";
import { CustomButton, CustomButtonClassType } from "../../Components/Views/CustomButton/CustomButton";
import { Icons } from "../../Components/Views/Icons/Icons";
import "./UserDetailsPage.scss";
import { ApiUrls } from "../../../Core/Models/ApiUrls";
import { BoRoles, BoUserResponse, CreateOrUpdateBoUser } from "../../../Core/Models/BoUsers/BoUsersResponse";
import { dataService, fetcherWithToastOnError } from "../../../Utils/fetcher";
import { useParams } from "react-router-dom";
import { OptionalRouteParams } from "../../../Core/Models/RouteParams";
import { getToastService } from "../../../Core/Services/Interfaces/IToastService";
import { AppRoutes } from "../../../Core/App/AppRoutes";
import { useApiDataResponseFn, useApiDataResponseImmutable, useForm } from "@ideine/web-common";
import { getNavigationService } from "../../../Core/Services/Interfaces/INavigationService";

export type Roles = { value: BoRoles; label: string };
export const BoUserRolesList = [
  {
    value: BoRoles.ContentCreator,
    label: "BoUser_Roles_ContentCreator",
  },
  {
    value: BoRoles.Administrator,
    label: "BoUser_Roles_Administrator",
  },
];

const InitialUser: CreateOrUpdateBoUser = {
  email: "",
  last_name: "",
  first_name: "",
  enabled: true,
  role: BoRoles.ContentCreator,
};

export const useUserDetailsPageController = () => {
  const { id } = useParams<OptionalRouteParams>();
  const { data } = useApiDataResponseImmutable<BoUserResponse>(
    id ? ApiUrls.BO_USER(id) : undefined,
    fetcherWithToastOnError
  );

  const initialFormValue = data?.data ?? InitialUser;
  const i18n = useIntl();
  const requiredMessage = i18n.formatMessage({ id: "COMMON_REQUIRED_FIELD" });
  const form = useForm<CreateOrUpdateBoUser>({
    initialValues: initialFormValue,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const response = await (id
        ? dataService.put<BoUserResponse>(ApiUrls.BO_USER_UPDATE(id), values)
        : dataService.post<BoUserResponse>(ApiUrls.BO_USER_CREATE, values));
      getToastService().value().showSuccessUpdate();

      if (response?.data?.id && !id) {
        getNavigationService().value().goToBoUserDetails(response.data.id);
      }
    },
    validationSchema: yup.object().shape({
      first_name: yup.string().required(requiredMessage),
      last_name: yup.string().required(requiredMessage),
      email: yup.string().required(requiredMessage),
    }),
  });

  const inviteState = useApiDataResponseFn(async () => {
    const response = await dataService.patch(ApiUrls.BO_USER_UPDATE(id!), {});
    if (response.is_success) {
      getToastService()
        .value()
        .showSuccess(i18n.formatMessage({ id: "UserDetailsPage_Invite_Success" }));
    }

    return response;
  }, []);

  return { form, isEditingExistingItem: Boolean(id), inviteState };
};

export const UserDetailsPage = () => {
  const { form, isEditingExistingItem, inviteState } = useUserDetailsPageController();

  let state = {
    ...form.values,
    fieldsErrors: form.errors,
  };

  const registerField = useInputTextFieldProps(state, (field, value) => form.setFieldValue(field, value), {
    i18nPrefix: "UserDetailsPage",
  });

  return (
    <AuthenticatedPageLayout
      previousTitle={<FormattedMessage id="UserListPage_Title" />}
      previousTitleLink={AppRoutes.BO_USER_LIST}
      title={
        <FormattedMessage id="COMMON_FULLNAME" values={{ firstName: state.first_name, lastName: state.last_name }} />
      }
      addDefaultPadding>
      <form onSubmit={form.handleSubmit} className="UserDetailsPage-form">
        <div className="row">
          <div className="col">
            <CustomInputText {...registerField("first_name")} />
            <CustomInputText {...registerField("last_name")} />
            <div className="row">
              <div className="col">
                <CustomInputText {...registerField("email")} />
              </div>
              {isEditingExistingItem && (
                <CustomButton
                  onClick={inviteState.execute}
                  variant={CustomButtonClassType.Outlined}
                  title={
                    <>
                      <FormattedMessage id="COMMON_INVITE" />
                      {inviteState.state.loading && <>...</>}
                    </>
                  }
                />
              )}
            </div>
          </div>
          <div className="col">
            <CustomSelect
              itemTitleGetter={(item) => <FormattedMessage id={item.label} />}
              items={BoUserRolesList}
              itemValueGetter={(item) => item?.value ?? ""}
              onChange={(item) => form.setFieldValue("role", item?.value)}
              value={BoUserRolesList.find((b) => b.value === state.role)}
              labelIntlId="UserDetailsPage_Role_Label"
            />
            <CustomSwitch
              checked={state.enabled ?? false}
              labelIntlId={"UserDetailsPage_IsEnabled_Label"}
              onChange={(c) => form.setFieldValue("enabled", c)}
              sufix={
                <FormattedMessage
                  id={state.enabled ? "UserDetailsPage_IsEnabled_True_Label" : "UserDetailsPage_IsEnabled_False_Label"}
                />
              }
            />
          </div>
        </div>
        <CustomButton
          className="UserDetailsPage-save"
          title={<FormattedMessage id="COMMON_SAVE" />}
          icon={Icons.IconSave}
          type="submit"
        />{" "}
      </form>
    </AuthenticatedPageLayout>
  );
};
