import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CustomButton, CustomButtonClassType } from "../../../../Components/Views/CustomButton/CustomButton";
import { PopoverMenu } from "../../../../Components/Views/PopoverMenu/PopoverMenu";
import "./ProfileHeaderMenu.scss";

interface ProfileHeaderMenuProps {
  className?: string;
  loading: boolean;
  menulabel: string;
  onLogout(onFinishedLogout: () => void): void;
}

const ProfileHeaderMenu: React.FunctionComponent<ProfileHeaderMenuProps> = ({
  className,
  loading,
  menulabel,
  onLogout,
}: ProfileHeaderMenuProps) => {
  const internalOnLogout = (): void => onLogout(closeMenu);

  const { closeMenu, menuContent: IconLogoutButton } = PopoverMenu({
    loading,
    menu: menulabel,

    content: (
      <div className="LogoutMenu-content">
        <CustomButton
          variant={CustomButtonClassType.Link}
          title={<FormattedMessage id="Header_Logout" />}
          onClick={internalOnLogout}
        />
      </div>
    ),
  });

  const rootClasses = classNames({
    "profile-header-icons-root": true,
    [className || ""]: className,
  });

  return <div className={rootClasses}>{IconLogoutButton}</div>;
};

export { ProfileHeaderMenu };
