import { XHRRequestCanceler } from "../../../../Libs/xhr/XHRRequestCanceler";
import { AuthDataServiceName, IAuthDataService } from "../../../DataServices/AuthenticationDataService";
import { Lazy } from "../../../Helpers/Lazy";
import { BaseApiDataResponse } from "../../../Models/ApiData";
import { AuthenticationResponse, AuthenticationResult } from "../../../Models/Authentication/AuthResponse";
import { ApiErrorCodes } from "../../../Models/ErrorCodes";
import { IoC } from "../../ServicesContainer";
import { BaseReduxService } from "../Api/BaseReduxService";
import { AuthenticationActions } from "./AuthenticationActions";
import { LogoutAction } from "./typings/AuthenticationActionTypes";
import { AuthUtils } from "./utils/AuthUtils";

export enum AuthenticateResultType {
  SUCCESS = "SUCCESS",
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  LOGIN_ERROR = "LOGIN_ERROR",
  WEAK_PASSWORD = "WEAK_PASSWORD",
}

export interface IAuthService extends BaseReduxService {
  login: (email: string, password: string, cts: XHRRequestCanceler) => Promise<AuthenticateResultType>;
  logout: (reason?: string) => Promise<LogoutAction>;
  getAuthorization: () => Promise<string>;
  refreshAuthentication: (forceRefresh?: boolean) => Promise<any>;
  forgotPassword: (email: string, cts: XHRRequestCanceler) => Promise<BaseApiDataResponse>;
  resetPasswordAndAuthenticate: (
    token: string,
    email: string,
    newPassword: string,
    cts: XHRRequestCanceler
  ) => Promise<BaseApiDataResponse>;
}

class AuthService extends BaseReduxService implements IAuthService {
  private authDataService: Lazy<IAuthDataService>;

  constructor() {
    super();
    this.authDataService = IoC.getLazy<IAuthDataService>(AuthDataServiceName);
  }

  public login = async (email: string, password: string, cts: XHRRequestCanceler): Promise<AuthenticateResultType> => {
    try {
      const response: AuthenticationResponse = await this.authDataService.value().login(email, password, cts);

      const transformedAuth = AuthUtils.transformAuth(response.data as AuthenticationResult);
      await this.dispatch(AuthenticationActions.loginSuccess(transformedAuth));
      return Promise.resolve(AuthenticateResultType.SUCCESS);
    } catch (error: any) {
      const { error_code }: BaseApiDataResponse = error;
      this.dispatch(AuthenticationActions.loginFail(error));
      if (error_code === ApiErrorCodes.GENERIC_HTTP_ERROR) {
        return Promise.resolve(AuthenticateResultType.INVALID_CREDENTIALS);
      } else {
        return Promise.resolve(AuthenticateResultType.LOGIN_ERROR);
      }
    }
  };

  public logout = async (reason?: string): Promise<LogoutAction> => {
    try {
      //await this.authDataService.value().logout();
      return this.dispatch(AuthenticationActions.logout(reason));
    } catch (error) {
      return this.dispatch(AuthenticationActions.logout(reason));
    }
  };

  //? @returns {String} the authorization header, NOT the token
  public getAuthorization = (): Promise<string> => {
    const { expiration_date, access_token } = this.getState().Authentication;

    if (AuthUtils.authenticationIsExpired(expiration_date)) {
      return this.dispatch(this.refreshAuthentication()).then((auth: any) =>
        AuthUtils.getAuthorizationFromToken(auth.access_token)
      );
    }

    const auth = AuthUtils.getAuthorizationFromToken(access_token);
    return Promise.resolve(auth);
  };

  /**
   * If the access token is not expired, fires refreshSuccess right away with current state.
   * Resets the initialized state (keeps the authenticated state) and then refreshes the access token
   * @param {Boolean} forceRefresh - If should refresh the tokens, even if the current ones are not expired
   * @returns {Promise<Object>} result - the result of the refresh, same as the login result OR an object
   * with props `isError` and `err`
   * @throws {Promise<Error>} an Axios error if the refresh failed
   */
  refreshAuthentication = async (forceRefresh?: boolean): Promise<AuthenticationResult> => {
    const authentication = this.getState().Authentication;

    const { expiration_date, refresh_token } = authentication || {
      refresh_token: "",
      expiration_date: "",
    };

    if (!AuthUtils.authenticationIsExpired(expiration_date) && !forceRefresh) {
      const res: AuthenticationResult = {
        access_token: authentication.access_token,
        refresh_token: authentication.refresh_token,
        expires_in: authentication.expires_in,
        token_type: authentication.token_type,
        expiration_date: authentication.expiration_date,
      };

      this.dispatch(AuthenticationActions.refreshSuccess(res));
      return Promise.resolve(res);
    }

    if (!refresh_token) {
      const errorMessage = "No refresh token found, cannot refresh and will logout";
      this.handleError(errorMessage);
      this.dispatch(AuthenticationActions.refreshFail(errorMessage));
      return Promise.reject(errorMessage);
      //.catch((err: any) => ({ isError: true, err }));
    } else {
      this.dispatch(AuthenticationActions.setInitialized(false, "loading refresh")); // is loading

      try {
        const response: any = await this.authDataService.value().refresh(refresh_token);
        const transformedAuth = AuthUtils.transformAuth(response.data);
        this.dispatch(AuthenticationActions.refreshSuccess(transformedAuth));
        return Promise.resolve(transformedAuth);
      } catch (error) {
        this.dispatch(AuthenticationActions.refreshFail(error));
        return Promise.reject(error);
      }
    }
  };

  public forgotPassword = async (email: string, cts: XHRRequestCanceler): Promise<BaseApiDataResponse> => {
    return this.authDataService.value().forgotPassword(email, cts);
  };

  public resetPasswordAndAuthenticate = async (
    token: string,
    email: string,
    newPassword: string,
    cts: XHRRequestCanceler
  ): Promise<BaseApiDataResponse> => {
    return this.authDataService.value().resetPasswordAndAuthenticate(token, email, newPassword, cts);
  };
}

const AuthServiceName = "AuthService";
export { AuthService, AuthServiceName };
