import './PopoverMenu.scss';

import Popover from '@material-ui/core/Popover';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import React, { useState } from 'react';

import { Loader } from '../../Templates/Loader/Loader';

export interface PopoverMenuProps {
    loading?: boolean;
    menu: React.ReactNode;
    content?: React.ReactNode;
}
const expandMoreIconColor: string = '#ee7624';

//* Not used
const PopoverMenu = ({ loading = false, menu, content }: PopoverMenuProps) => {
    const [menuOpened, setMenuOpened] = useState(false);

    const openMenu = (): void => setMenuOpened(true);
    const closeMenu = (): void => setMenuOpened(false);

    const anchorEl = React.useRef(null);

    const RenderMenu = () => (
        <div ref={anchorEl} onClick={openMenu} className="pm-button-root">
            {loading && <Loader position="center" />}
            {!loading && <div className="pm-button-menu">{menu}</div>}

            <ExpandMoreIcon
                className={classNames({ 'pm-button-expand-icon': true, open: menuOpened })}
                htmlColor={expandMoreIconColor}
            />
        </div>
    );

    return {
        closeMenu,
        menuContent: (
            <div className="popover-menu">
                {RenderMenu()}
                <Popover
                    open={menuOpened}
                    anchorEl={anchorEl.current}
                    anchorReference="anchorEl"
                    onClose={closeMenu}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    classes={{ paper: classNames({ 'pm-popover-paper': true }) }}
                >
                    {content}
                </Popover>
            </div>
        ),
    };
};

export { PopoverMenu };
