import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { BaseFormViewModel, BaseFormViewState } from "./BaseFormViewModel";

export interface BaseEditOrCreateViewState<T> extends BaseFormViewState<T> {
  id?: string;
  mode: "edit" | "create";
}

export abstract class BaseEditOrCreateViewModel<
  TState extends BaseEditOrCreateViewState<any>
> extends BaseFormViewModel<TState> {
  /**
   *
   * @param initialCreateOrEditState the default state. use for both create or edit if the second paramter is not provided
   * @param initialEditState the default edit state.
   */
  constructor(private initialCreateOrEditState: TState, private initialEditState?: TState) {
    super(initialCreateOrEditState);
  }

  /** Inform the VM that an id is known */
  public onItemChange = (itemId: string | undefined) => {
    if (itemId) {
      this.editItem(itemId);
    } else {
      this.createItem();
    }
  };

  private createItem = () => this.setState({ ...this.initialCreateOrEditState, id: undefined, mode: "create" });

  private editItem = (newItemId: string) => {
    const oldItemId = this.state.id;

    //The current item is the one? : No need to refresh the data.
    if (oldItemId === newItemId) {
      this.setState({ ...this.state, mode: "edit" });
    } else {
      //Reset the state & get the item
      this.setState(
        this.initialEditState
          ? { ...this.initialEditState, mode: "edit" }
          : { ...this.initialCreateOrEditState, mode: "edit" }
      );
      this.WrapError(
        async (cts: XHRRequestCanceler) => {
          const state = await this.getEditItemState(newItemId, cts);
          this.setState({ ...state, mode: "edit" });
        },
        { withBusy: true }
      );
    }
  };

  protected abstract getEditItemState(itemId: string, cts: XHRRequestCanceler): Promise<TState>;
}
