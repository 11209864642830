import { TableCell, TableRow } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import classnames from 'classnames';
import React, { ComponentType, FunctionComponent, ReactNode, useCallback } from 'react';
import { Loader } from '../../../Templates/Loader/Loader';
import { NoResult, NoResultProps } from '../../NoResult/NoResult';
import { CustomPagination } from '../Pagination/Pagination';
import { ItemListTableRow } from './ItemListTableRow/ItemListTableRow';
import './PaginatedTable.scss';
import { TableHeader } from './TableHeader/TableHeader';

export type PaginatedTableBaseItem = { id: string };

export interface RowRendererProps<Item extends PaginatedTableBaseItem> {
    item: Item;
    onRowClick?: (item: Item) => void;
}

export interface TableHeaderProps {
    headerProps: { className: string };
    headerRowProps: { className: string };
    headerCellProps: { className: string };
}

export interface PaginatedTableProps<Item extends PaginatedTableBaseItem> {
    className?: string;
    /** Array of columns for the header */
    headerColumns?: ReactNode[];
    /**
     * function({headerProps, rowProps, cellProps}) => PropTypes.node
     * Provide this function to render a custom header, instead of passing 'headerColumns'
     */
    tableHeader?: FunctionComponent<TableHeaderProps>;
    /**
     * a react component to render each item, receives all the items props as individual props (destructured)
     * as well as item={item}
     */
    rowComponent?: ComponentType<RowRendererProps<Item> & Item>;
    /** Return X cells content as ReacNode */
    getCellsData?: (item: Item) => React.ReactNode[];
    /** if a query is ongoing */
    loadingData?: boolean;
    /** if should show a loader */
    /** Array of data that RowRenderer will be mapped upon  */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items: Array<Item>;
    /**
     * The total amount of items that the db has.
     * Used to calculate pagination with `itemsPerPage`
     */
    totalCount?: number;
    /** Index of the current page */
    page?: number;
    /**
     * Signature:
     * function(page: number) => void
     * page: The page the user just selected
     */
    onPageChange?: (page: number) => void;
    /** when the user click the row */
    onRowClick?: (item: Item) => void;
    /**
     * Number of items that there should be, per page.
     * Used to calculate pagination from `total_count`
     */
    itemsPerPage?: number;
    /** Show the pagination buttons */
    showPagination?: boolean;
    /**
     * Replaces the item at the specified index, with the new data
     * function(index: number, data: object) => void
     */
    replaceItem?: (index: number, data: Item) => void;
    /** Extra props passed to each row item */
    rowProps?: { [x: string]: any };
    /**
     * Extra props to pass to each row item
     * function(item: Object, index: Number) => Object
     */
    rowCallbackProps?: (
        item: Item,
        index: number
    ) => {
        [x: string]: any;
    };

    classes?: { noResults?: string };
    NoResultComponent?: React.FunctionComponent<NoResultProps>;
}

enum PaginatedTableContentType {
    Loading,
    Empty,
    List,
}

const PaginatedTable = <Item extends PaginatedTableBaseItem>({
    className,
    rowComponent: RowRenderer,
    headerColumns = [],
    loadingData,
    items = [],
    getCellsData,
    totalCount: propTotalCount = 0,
    onPageChange,
    itemsPerPage = items.length,
    page = 0,
    showPagination = true,
    replaceItem = () => undefined,
    rowProps = {},
    onRowClick,
    rowCallbackProps,
    tableHeader = undefined,
    classes = { noResults: '' },
    NoResultComponent,
}: PaginatedTableProps<Item>) => {

    const renderRow = useCallback((item: Item, index: number) => {
        if (RowRenderer) {
            return <RowRenderer
                key={item.id ?? index}
                rowIndex={index}
                {...item}
                item={item}
                onRowClick={onRowClick}
                replaceItem={(data: any): void => replaceItem(index, data)}
                {...rowProps}
                {...((rowCallbackProps && rowCallbackProps(item, index)) || {})}
            />;
        }
        else if (getCellsData) {
            return <ItemListTableRow<Item>
                key={item.id ?? index}
                item={item}
                onRowClick={onRowClick}
                getCellsData={getCellsData}
            />
        }
    }, [RowRenderer, getCellsData, onRowClick, replaceItem, rowCallbackProps, rowProps]);

    const contentType: PaginatedTableContentType = (function () {
        if (loadingData) {
            return PaginatedTableContentType.Loading;
        } else if (!loadingData && (!items.length)) {
            return PaginatedTableContentType.Empty;
        } else {
            return PaginatedTableContentType.List;
        }
    })();

    const totalCount = propTotalCount || items.length || 0;

    const NoResultRenderer = NoResultComponent || NoResult;

    const rootClassnames = classnames({
        'pl-root': true,
        [className as string]: className,
        'pl-root-empty': contentType === PaginatedTableContentType.Empty,
    });

    return (
        <div className={rootClassnames}>
            <div className="p1-table-wrapper">
                <Table className="pl-table">
                    {contentType === PaginatedTableContentType.List &&
                        TableHeader({ headerColumns, tableHeader })}
                    {contentType === PaginatedTableContentType.List && (
                        <TableBody className="pl-table-body">
                            {items.map(renderRow)}
                        </TableBody>
                    )}
                    {contentType === PaginatedTableContentType.Empty && (
                        <TableBody className="PaginatedTable-noResultBody">
                            <TableRow>
                                <TableCell colSpan={headerColumns.length}>
                                    <NoResultRenderer
                                        className={classnames({
                                            'pl-no-results': true,
                                            [classes.noResults || '']: classes.noResults,
                                        })}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            </div>

            {contentType === PaginatedTableContentType.Loading && (
                <Loader classes={{ container: 'pl-loader' }} />
            )}

            {contentType === PaginatedTableContentType.List && showPagination && (
                <div className="pl-pagination">
                    <CustomPagination
                        pageCount={Math.ceil(totalCount / itemsPerPage)}
                        onPageChange={onPageChange}
                        page={page}
                    />
                </div>
            )}
        </div>
    );
};

export { PaginatedTable };

