import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";

import "./CustomCard.scss";

export interface CustomCardClasses {
  root?: string;
  header?: string;
  body?: string;
}

export interface CustomCardProps {
  children: React.ReactNode;
  titleIntlId: string;
  classes?: CustomCardClasses;
  renderHeader?: React.ReactNode;
}

const CustomCard = ({ children, titleIntlId, classes, renderHeader }: CustomCardProps) => {
  return (
    <div className={classNames({ "CustomCard-root": true, [classes?.root || ""]: classes?.root })}>
      <div className={classNames({ "CustomCard-header": true, [classes?.header || ""]: classes?.header })}>
        {renderHeader ? renderHeader : <FormattedMessage id={titleIntlId} />}
      </div>
      <div className={classNames({ "CustomCard-body": true, [classes?.body || ""]: classes?.body })}>{children}</div>
    </div>
  );
};

export { CustomCard };
