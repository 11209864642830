import { useResetPasswordPageController } from "./ResetPasswordPageController";

import "./ResetPasswordPage.scss";
import { CustomInputText, InputType, InputVariant } from "../../../Components/Views/CustomInputText/CustomInputText";
import { CustomButton, CustomButtonClassType } from "../../../Components/Views/CustomButton/CustomButton";
import { FormattedMessage } from "react-intl";
import { getNavigationService } from "../../../../Core/Services/Interfaces/INavigationService";
import React from "react";
import { AuthPageLayout } from "../AuthPageLayout/AuthPageLayout";

const ResetPasswordPage = () => {
  const { onSubmit, registerField, state } = useResetPasswordPageController();

  return (
    <AuthPageLayout>
      <div className="ResetPasswordPage-infoText">
        <FormattedMessage id="ResetPasswordPage_InfoText" />
      </div>
      <form onSubmit={onSubmit}>
        <CustomInputText
          {...registerField("password")}
          errorMessage={state.hasPasswordPolicyError ? "" : undefined}
          inputVariant={InputVariant.Outlined}
          type={InputType.Password}
          placeholderIntlId="ResetPasswordPage_InputPlaceholder_password"
          classes={{ root: "ResetPasswordPage_Password" }}
        />
        <CustomInputText
          {...registerField("passwordConfirm")}
          errorMessage={state.hasPasswordPolicyError ? "" : undefined}
          inputVariant={InputVariant.Outlined}
          type={InputType.Password}
          placeholderIntlId="ResetPasswordPage_InputPlaceholder_passwordConfirm"
          classes={{ root: "ResetPasswordPage_PasswordConfirm" }}
        />
        {state.hasPasswordPolicyError && (
          <p className="error">
            <FormattedMessage id="ResetPasswordPage_PasswordPolicyError" />
          </p>
        )}

        <CustomButton
          title={<FormattedMessage id="COMMON_VALIDATE" />}
          type="submit"
          isBusy={state.busy}
          className="ResetPasswordPage_submit"
        />
      </form>
      <CustomButton
        variant={CustomButtonClassType.Link}
        title={<FormattedMessage id="ForgotPasswordPage_ToLoginLink" />}
        className="LoginPage-forgotButton"
        onClick={() => getNavigationService().value().goToLogin()}
      />
    </AuthPageLayout>
  );
};

export { ResetPasswordPage };
