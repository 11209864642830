import React from 'react';
import { useSlate } from 'slate-react';
import { SlateRichText } from '../../../Utils/SlateRichText';
import { Button } from '../Button/Button';

export interface MarkButtonProps {
    readonly type: string;
}

export const MarkButton = ({ type, children }: React.PropsWithChildren<MarkButtonProps>) => {

    const editor = useSlate();

    return (
        <Button active={SlateRichText.isMarkActive(type, editor)} onMouseDown={(event: React.MouseEvent) => SlateRichText.addMark(event, type, editor)}>
            {children}
        </Button>
    );
};
