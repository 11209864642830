import React from 'react';
import { Editor } from 'slate';
import classNames from 'classnames';
import styled from 'styled-components';
import { CustomButton } from '../../CustomButton/CustomButton';
import { RTEImageLoader } from '../../Image/ImageDropzone/RTEImageLoader/RTEImageLoader';
import { XRichTextProps } from '../../..';
import { insertVideo } from '../../../Utils/VideoHandler';
import { useRTEConfig } from '../../../Context/RichtextConfig';
import './VideoChoice.scss';

export interface VideoChoiceProps extends XRichTextProps {
    editor: Editor;
    handleClose: () => void;
}

export const VideoChoice = ({
    editor, handleClose, videoList, busyLoadingVideos,
}: VideoChoiceProps) => {
    const addVideo = async (url: string, videoId: any, thumbnailUrl: string) => {
        insertVideo({
            editor, url, videoId, thumbnailUrl,
        });
        handleClose();
    };

    const RTEConfig = useRTEConfig();

    return (
        <div className="video-choice-root">
            <div className="VideoChoice-list">
                {busyLoadingVideos && <RTEImageLoader classes={{ container: 'VideoChoice-loader' }} />}
                {videoList?.map((video) => (
                    <VideoChoiceList
                        key={video.id}
                        onClick={() => addVideo(video.url, video.id, video.thumbnailUrl)}
                        className={classNames('VideoChoice-listElement', {
                            'VideoChoice-elementInProgress': video.in_progress,
                        })}
                    >
                        {video.in_progress ? (
                            <VideoInProgress className="VideoChoice-videoInProgress" />
                        ) : (
                            <video src={video.url} />
                        )}
                        <div className="VideoChoice-listElementTitle">{video.title}</div>
                    </VideoChoiceList>
                ))}
            </div>
            <div className="ic-buttons-container">
                <CustomButton onClick={handleClose} danger title={RTEConfig.rteButtonCancelLabel} fullWidth />
            </div>
        </div>
    );
};

const VideoChoiceList = styled.div`
    width: 200px;
    height: 150px;
    margin-right: 16px;
    background-color: ${(props) => props.theme.videoListPageItemBackgroundColor ?? 'white'};
    color: ${(props) => props.theme.videoListPageItemTextColor ?? 'black'};
    margin-bottom: 16px;
    cursor: pointer;
`;

const VideoInProgress = styled.div`
    width: 200px;
    height: 192px;
    background-color: ${(props) => props.theme.videoListPageItemInProgressBackgroundColor ?? 'white'};
`;
