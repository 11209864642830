import { Editor, Path, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { CustomElement, VideoElement } from "../CustomTypes";
import { createVideoNode } from "./CreateCustomNodes";

export interface InsertVideoProps {
  editor: Editor;
  url?: string;
  thumbnailUrl: string;
  videoId: any;
}

export const insertVideo = ({ editor, url, videoId, thumbnailUrl }: InsertVideoProps) => {
  if (!url) return;

  const { selection } = editor;
  const video = createVideoNode(url, videoId, thumbnailUrl) as VideoElement;

  ReactEditor.focus(editor);

  if (!!selection) {
    const [parentNode, parentPath] = Editor.parent(editor, selection.focus?.path);

    if (editor.isVoid(parentNode as CustomElement) || !Editor.isEmpty(editor, parentNode as CustomElement)) {
      // We check if the currently selected Node is void or empty and insert the video Node after it
      Transforms.insertNodes(editor, video, {
        at: Path.next(parentPath),
        select: true,
      });
    } else {
      // If the node is empty, replace it with the video instead
      Transforms.removeNodes(editor, { at: parentPath });
      Transforms.insertNodes(editor, video, { at: parentPath, select: true });
    }
  } else {
    // Insert the new video node at the bottom of the Editor when editor is not selected
    Transforms.insertNodes(editor, video, { select: true });
  }
};
