import { AppRoutes } from "../../../Core/App/AppRoutes";
import { AppRouteType } from "../../Components/Templates/AppRouter/AppRouteType";
import { PrivateRoute } from "../../Components/Templates/AppRouter/PrivateRoute";
import { NewsDetailsPage } from "../../Pages/News/ExhibitionDetailsPage/NewsDetailsPage";

const NewsCreateRoute: AppRouteType = {
  id: AppRoutes.NEWS_DETAILS,
  render: PrivateRoute,
  props: {
    exact: true,
    path: AppRoutes.NEWS_DETAILS,
    component: NewsDetailsPage,
  },
};

export const getNewsRoutes = () => [NewsCreateRoute];
