import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { Lazy } from "../../Helpers/Lazy";
import { AuthServiceName, IAuthService } from "../../Services/Data/Authentication/AuthenticationService";
import { Messages } from "../../Services/Data/I18n/typings/LocalMessageActionTypes";
import { IoC } from "../../Services/ServicesContainer";
import { MessageUtils } from "../../Utils/MessageUtils";
import { ValidatorsUtils } from "../../Utils/ValidatorsUtils";
import { BaseFormViewModel, BaseFormViewState, InitialBaseFormViewState } from "../Base/BaseFormViewModel";

export interface ResetPasswordViewState extends BaseFormViewState<ResetPasswordViewState> {
  password: string;
  passwordConfirm: string;
  hasPasswordPolicyError: boolean;
}

export const InitialResetPasswordViewState: ResetPasswordViewState = {
  password: "",
  passwordConfirm: "",
  hasPasswordPolicyError: false,
  ...InitialBaseFormViewState,
};

export class ResetPasswordViewModel extends BaseFormViewModel<ResetPasswordViewState> {
  protected readonly authService: Lazy<IAuthService>;

  constructor(private email: string, private code: string, private isApp: boolean) {
    super(InitialResetPasswordViewState);
    this.authService = IoC.getLazy<IAuthService>(AuthServiceName);
  }

  protected validateFields = () => {
    const { password, passwordConfirm } = this.state;
    const errors: Messages = {};

    const requiredMessage = this.getString("COMMON_REQUIRED_FIELD");
    ValidatorsUtils.checkRequired(password, errors, "password", requiredMessage);
    ValidatorsUtils.checkRequired(passwordConfirm, errors, "passwordConfirm", requiredMessage);
    ValidatorsUtils.checkError(
      password === passwordConfirm,
      (v) => v,
      errors,
      "passwordConfirm",
      this.getString("ResetPasswordPage_PasswordMustMatch")
    );

    this.setState({ ...this.state, fieldsErrors: errors, hasPasswordPolicyError: false });
    return MessageUtils.isEmpty(errors);
  };

  protected save = async (cts: XHRRequestCanceler) => {
    const { password } = this.state;

    const response = await this.authService.value().resetPasswordAndAuthenticate(this.code, this.email, password, cts);
    if (response.is_success) {
      this.toastService.value().showSuccess(this.i18n.value().getString("InitializePasswordPage_SuccessText"));
      if (this.isApp) {
        this.navigationService.value().goToGetApp();
      } else {
        this.navigationService.value().goToLogin();
      }
    } else if (response.error_code === "WEAK_PASSWORD") {
      this.toastService.value().showError(this.i18n.value().getString("ResetPasswordPage_PasswordPolicyError"));
      this.setState({
        ...this.state,
        fieldsErrors: { password: this.i18n.value().getString("ResetPasswordPage_PasswordPolicyFieldError") },
      });
    }
  };
}
