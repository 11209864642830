import Axios, { CancelTokenSource } from "axios";
import React from "react";
import { CustomButton } from "../../CustomButton/CustomButton";
import { ImageDropzone } from "../ImageDropzone/ImageDropzone";
import { insertImage } from "../../../Utils/ImageHandler";
import { Editor } from "slate";
import { XRichTextProps } from "../../..";
import { ImageMedia, useRTEConfig } from "../../../Context/RichtextConfig";
import "./imageChoice.scss";

export interface ImageChoiceProps extends XRichTextProps {
  editor: Editor;
  handleClose: () => void;
}

export const ImageChoice = ({ editor, handleClose }: ImageChoiceProps) => {
  const uploadImageCanceler = React.useRef<CancelTokenSource>();
  const [imageFile, setImageFile] = React.useState<File>();
  const [imageMedia, setImageMedia] = React.useState<ImageMedia>();

  const RTEConfig = useRTEConfig();

  const addImage = () => {
    if (imageMedia) {
      insertImage({
        editor,
        url: RTEConfig.getUrl?.(imageMedia, { height: 200 }),
      });
    }
    handleClose();
  };

  const onRemove = () => {
    if (uploadImageCanceler.current) {
      uploadImageCanceler.current.cancel("remove image");
    }
    setImageFile(undefined);
    setImageMedia(undefined);
  };

  return (
    <div className="image-choice-root">
      <ImageDropzone
        imageFile={imageFile}
        imageUrl={
          RTEConfig.getUrl?.(imageMedia, { height: 200 })
        }
        title={RTEConfig.imageDropzoneTitle}
        label={RTEConfig.imageDropzoneLabel}
        cancelLabel={RTEConfig.imageDropzoneCancelLabel}
        imageLoadingLabel={RTEConfig.imageDropzoneLoadingMessage}
        onUpload={async (file: File, progressCallback?: any) => {
          uploadImageCanceler.current = Axios.CancelToken.source();
          const response: ImageMedia | undefined = await RTEConfig.onImageUpload?.(
            file,
            progressCallback,
            uploadImageCanceler.current
          );

          if (response) {
            uploadImageCanceler.current = undefined;
            setImageFile(file);
            setImageMedia(response);
          }
        }}
        onRemove={onRemove}
        onCancel={onRemove}
      />

      <div className="ic-buttons-container">
        <CustomButton
          fullWidth
          disabled={!imageMedia}
          onClick={addImage}
          title={RTEConfig.rteButtonAddLabel}
          className={"ic-button-root"}
        />
        <CustomButton
          danger
          fullWidth
          onClick={handleClose}
          title={RTEConfig.rteButtonCancelLabel}
          className={"ic-button-root"}
        />
      </div>
    </div>
  );
};
