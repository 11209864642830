import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { Lazy } from "../../Helpers/Lazy";
import { AuthServiceName, IAuthService } from "../../Services/Data/Authentication/AuthenticationService";
import { Messages } from "../../Services/Data/I18n/typings/LocalMessageActionTypes";
import { IoC } from "../../Services/ServicesContainer";
import { MessageUtils } from "../../Utils/MessageUtils";
import { ValidatorsUtils } from "../../Utils/ValidatorsUtils";
import { BaseFormViewModel } from "../Base/BaseFormViewModel";
import { BaseViewState } from "../Base/BaseViewModel";

export interface ForgotPasswordViewState extends BaseViewState {
  email: string;
  fieldsErrors: Messages;
  invalidCredentials: boolean;
  sent: boolean;
}

export const InitialForgotPasswordViewState: ForgotPasswordViewState = {
  email: "",
  fieldsErrors: [],
  invalidCredentials: false,
  sent: false,
};

export class ForgotPasswordViewModel extends BaseFormViewModel<ForgotPasswordViewState> {
  protected readonly authService: Lazy<IAuthService>;

  constructor() {
    super(InitialForgotPasswordViewState);
    this.authService = IoC.getLazy<IAuthService>(AuthServiceName);
  }

  protected validateFields = () => {
    const { email } = this.state;
    const errors: Messages = {};

    const requiredMessage = this.getString("COMMON_REQUIRED_FIELD");
    ValidatorsUtils.checkRequired(email, errors, "email", requiredMessage);

    this.setState({ ...this.state, fieldsErrors: errors, invalidCredentials: false });

    return MessageUtils.isEmpty(errors);
  };

  protected save = async (cts: XHRRequestCanceler) => {
    const response = await this.authService.value().forgotPassword(this.state.email, cts);
    if (response.is_success) {
      this.toastService.value().showSuccess(this.i18n.value().getString("ForgotPasswordPage_Success"));
      this.setState({ ...this.state, sent: true });
    }
  };
}
