import classNames from "classnames";
import "loaders.css/loaders.min.css";
import React from "react";
import Loader, { LoaderType } from "react-loaders";
import "./loader.scss";

export interface LoaderProps {
  position?: "left" | "center" | "right";
  type?: LoaderType;
  color?: string;
  classes?: {
    container?: string;
    loader?: string;
  };
}

const LoaderComponent = ({ position = "left", type = "ball-beat", color = "#2A384B", classes }: LoaderProps) => {
  const { container: containerClasses = "", loader: loaderClasses = "" } = classes || {};

  const loaderContainerClassNames = classNames({
    "my-loader-container": true,
    [position]: true,
    [containerClasses]: containerClasses,
  });

  return (
    <div className={loaderContainerClassNames}>
      <Loader
        innerClassName={classNames({ "my-loader": true, [loaderClasses]: loaderClasses })}
        type={type}
        active
        //color={color}
        {...{ color }}
      />
    </div>
  );
};

export { LoaderComponent as Loader };
