import * as AuthenticationTypes from "./typings/AuthenticationActionTypes";
import * as EmailActionTypes from "./typings/EmailActionTypes";
import { AuthUtils } from "./utils/AuthUtils";
import { AuthenticationResult } from "../../../Models/Authentication/AuthResponse";
import { getToastService } from "../../Interfaces/IToastService";
import { IoC } from "../../ServicesContainer";
import { I18nService, I18nServiceName } from "../I18n/I18nService";

export class AuthenticationActions {
  static logout = (reason?: string): AuthenticationTypes.LogoutAction => ({
    type: AuthenticationTypes.AuthActionType.LOG_OUT,
    payload: { reason },
  });

  static setInitialized = (initialized: boolean, reason: string): AuthenticationTypes.SetInitializedAction => ({
    type: AuthenticationTypes.AuthActionType.SET_INITIALIZED,
    payload: { initialized, reason },
  });

  static setAuthenticated = (isAuthenticated: boolean): AuthenticationTypes.SetAuthenticatedAction => ({
    type: AuthenticationTypes.AuthActionType.SET_AUTHENTICATED,
    payload: { isAuthenticated },
  });

  static setAuthorizationHeader = (value: string): AuthenticationTypes.SetAuthorizationHeaderAction => ({
    type: AuthenticationTypes.AuthActionType.SET_AUTHORIZATION_HEADER,
    payload: { value },
  });

  static clearAuthorizationHeader = (): AuthenticationTypes.ClearAuthorizationHeader => ({
    type: AuthenticationTypes.AuthActionType.CLEAR_AUTHORIZATION_HEADER,
  });

  //* REFRESH
  /**
   * Dispatches the success auth actions, and calls `UserActioner.retrieveAuthenticatedUserInfo`
   * @param {Object} result - The result of the successful refresh
   * @param {String} result.access_token
   * @param {String} result.refresh_token
   * @param {Number} result.expires_in
   * @param {String} result.token_type
   * @param {String} result.expiration_date: - ms since epoch
   * @returns {Promise<Object>} result - the result of the refresh, same as the login result
   */
  static refreshSuccess = (result: any) => (dispatch: any) => {
    const authorization = AuthUtils.getAuthorizationFromToken(result.access_token);
    dispatch(AuthenticationActions.setAuthorizationHeader(authorization));
    dispatch(AuthenticationActions.setAuthenticated(true));
    dispatch(EmailActions.refreshEmailSuccess(result));
    dispatch(AuthenticationActions.setInitialized(true, "refresh success"));
    return Promise.resolve(result);
  };

  /**
   * Dispatches the email refresh failure
   * @throws {Promise<Error>} the error received
   */
  static refreshFail = (error: any) => (dispatch: any) => {
    dispatch(AuthenticationActions.clearAuthorizationHeader());
    dispatch(AuthenticationActions.setAuthenticated(false));
    dispatch(EmailActions.refreshEmailFailure(error));
    dispatch(AuthenticationActions.setInitialized(true, "refresh fail"));
    return Promise.reject(error);
  };

  /**
   * Dispatches the success auth actions, and calls `UserActioner.retrieveAuthenticatedUserInfo`
   * @param {Object} result - The result of the successful login
   * @param {String} result.access_token
   * @param {String} result.refersh_token
   * @param {Number} result.expires_in
   * @param {String} result.token_type
   * @param {String} result.expiration_date: - ms since epoch
   * @returns {Promise<Object>} result - the result received
   */
  static loginSuccess = (auth: any) => (dispatch: any) => {
    const authorization = AuthUtils.getAuthorizationFromToken(auth.access_token);
    dispatch(AuthenticationActions.setAuthorizationHeader(authorization));
    dispatch(AuthenticationActions.setAuthenticated(true));
    dispatch(EmailActions.loginEmailSuccess(auth));
    dispatch(AuthenticationActions.setInitialized(true, "login success"));
    return Promise.resolve(auth);
  };

  /**
   * Dispatches the email login failure with the errror received
   * @param {Error} error
   * @throws {Promise<Error>} the error received
   */
  static loginFail = (error: any) => (dispatch: any) => {
    dispatch(AuthenticationActions.clearAuthorizationHeader());
    dispatch(AuthenticationActions.setAuthenticated(false));
    dispatch(EmailActions.loginEmailFailure(error));
    dispatch(AuthenticationActions.setInitialized(true, "login fail"));
    getToastService().value().showError(IoC.getLazy<I18nService>(I18nServiceName).value().getString('Toast_LoginFailed'));
    return Promise.reject(error);
  };
}

export class EmailActions {
  static refreshEmailSuccess = (result: AuthenticationResult): EmailActionTypes.RefreshEmailSuccessAction => ({
    type: EmailActionTypes.EmailActionType.REFRESH_EMAIL_SUCCESS,
    payload: { ...result },
  });

  static refreshEmailFailure = (error: any): EmailActionTypes.RefreshEmailFailureAction => ({
    type: EmailActionTypes.EmailActionType.REFRESH_EMAIL_FAIL,
    payload: { error },
  });

  static loginEmailSuccess = (result: AuthenticationResult): EmailActionTypes.LoginEmailSuccessAction => ({
    type: EmailActionTypes.EmailActionType.LOGIN_EMAIL_SUCCESS,
    payload: { ...result },
  });

  static loginEmailFailure = (error: any): EmailActionTypes.LoginEmailFailureAction => ({
    type: EmailActionTypes.EmailActionType.LOGIN_EMAIL_FAIL,
    payload: { error },
  });
}
