import {
  ExhibitionsListViewModel,
  ExhibitionsListViewState,
} from "../../../Core/ViewModels/Exhibitions/ExhibitionsListViewModel";
import { ViewModelLocator } from "../../../Core/ViewModels/ViewModelLocator";
import { useLifecycle } from "../../Hooks/Lifecycle/useLifecycle";

export const useExhibitionsListPageController = () => {
  const { vm, state } = useLifecycle<ExhibitionsListViewState, ExhibitionsListViewModel>({
    getRef: () => ViewModelLocator.ExhibitionsListVM,
    setRefValue: (value) => (ViewModelLocator.ExhibitionsListVM = value),
    newInstance: () => new ExhibitionsListViewModel(),
    logName: "ExhibitionsListVM",
  });

  return { vm, state };
};
