import './Pagination.scss';

import IconButton from '@material-ui/core/IconButton';
import { PaginationRenderItemParams } from '@material-ui/lab/Pagination';
import classNames from 'classnames';
import React from 'react';

import { Icons } from '../../Icons/Icons';

interface CustomPaginationItem {
    onClick?(): void;
    children: React.ReactNode;
    selected: boolean;
    type: string;
    disabled: boolean;
}

export const getPaginationItem = ({
    onClick,
    children,
    selected,
    disabled,
    type,
}: CustomPaginationItem): React.ReactNode => {
    const paginationItemClasses = classNames({ 'c-p-i': true, [type]: type, selected, disabled });
    return (
        <IconButton
            className={paginationItemClasses}
            onClick={onClick}
            children={children}
            disabled={disabled}
        />
    );
};

interface RenderPaginationItemProps {
    params: PaginationRenderItemParams;
    onChange(page: number): void;
    pageCount: number;
    page: number;
}

export const renderPaginationItem = ({
    params,
    onChange,
    page: currentPage,
    pageCount,
}: RenderPaginationItemProps): React.ReactNode => {
    const paginationItemContent: {
        children: React.ReactNode;
        onClick?(): void;
        disabled: boolean;
        selected: boolean;
    } = {
        children: null,
        disabled: params.page === undefined,
        selected: params.type === 'page' && params.page === currentPage + 1,
    };

    switch (params.type) {
        case 'previous':
            paginationItemContent.children = Icons.IconPaginationPrevious;
            paginationItemContent.disabled = params.page < 0;
            paginationItemContent.onClick = () => onChange(currentPage - 1);
            break;
        case 'next':
            paginationItemContent.children = Icons.IconPaginationNext;
            paginationItemContent.disabled = params.page === pageCount;
            paginationItemContent.onClick = () => onChange(currentPage + 1);
            break;
        case 'page':
            paginationItemContent.children = params.page;
            break;
        case 'start-ellipsis':
        case 'end-ellipsis':
            paginationItemContent.children = "..."
            paginationItemContent.disabled = true;
            break;
    }

    return getPaginationItem({
        type: params.type,
        onClick: () => onChange(params.page - 1),
        ...paginationItemContent,
    });
};
