import MuiButton from "@material-ui/core/Button";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { Loader } from "../../Templates/Loader/Loader";
import "./CustomButton.scss";

export enum CustomButtonClassType {
  Standard = "Button-standard",
  Danger = "Button-danger",
  Success = "Button-success",
  Link = "Button-link",
  Outlined = "Button-outlined",
}

export interface CustomButtonProps {
  icon?: React.ReactElement;
  endRowIcon?: React.ReactElement;
  title?: React.ReactNode;
  variant?: CustomButtonClassType;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  loading?: boolean;
  disabled?: boolean;
  type?: "submit" | "reset" | "button";
  isBusy?: boolean;
  link?: string;
  visible?: boolean;
  fullWidth?: boolean;
}

export const CustomButton = ({
  title = "",
  icon,
  endRowIcon,
  variant = CustomButtonClassType.Standard,
  className,
  onClick,
  disabled = false,
  type,
  isBusy,
  link,
  visible = true,
  fullWidth,
}: CustomButtonProps) => {
  const getContent = (): React.ReactNode => (
    <div className={classNames({ [variant]: variant })}>
      <div
        className={classNames({
          "Button-content": true,
          "Button-busy": isBusy,
          "Button-fullWidth": fullWidth,
        })}>
        {icon && !isBusy && <div className="Button-icon">{icon}</div>}
        {isBusy ? <Loader /> : title}
        {endRowIcon && !isBusy && <div className="Button-endRowIcon">{endRowIcon}</div>}
      </div>
    </div>
  );

  if (!visible) {
    return null;
  }

  return (
    <div
      className={classNames({
        "Button-root": true,
        "Button-disabled": disabled,
        [className as string]: className,
      })}>
      <MuiButton onClick={onClick} disabled={disabled} type={type}>
        {link ? <Link to={link}>{getContent()}</Link> : getContent()}
      </MuiButton>
    </div>
  );
};
