import './ImageDropzone.scss';

import classNames from 'classnames';
import qs from 'qs';
import React from 'react';
import { useViewProps } from './ViewProps';
import { LoadableImage } from './LoadableImage/LoadableImage';
import { ProgressUpload } from './ProgressUpload/progressUpload';
import { DropImageView } from './DropImageView/DropImageView';
import { Assets } from '../../../Assets/Assets';

interface UploadProgressEvent {
    readonly percent: number; //* 0.0 -> 1.0
}

type ProgressEventAction = (event: UploadProgressEvent) => void;

const qsOptions: qs.IStringifyOptions = {
    indices: false,
    arrayFormat: 'repeat',
    addQueryPrefix: true,
};

const getFilePreview = (file?: any): string | undefined => {
    if (!file || file.preview || !file.path) {
        return undefined;
    }
    try {
        return URL.createObjectURL(file);
    } catch (e) {
        return undefined;
    }
};

export interface ImageDropzoneProps {
    readonly title?: React.ReactNode;
    readonly label?: React.ReactNode;
    readonly cancelLabel?: React.ReactNode;
    readonly imageLoadingLabel?: React.ReactNode;
    readonly imageFile?: File;
    readonly imageUrl?: string;
    readonly onUpload?: (file: File, progress?: ProgressEventAction) => void;
    readonly onRemove?: () => void;
    readonly onCancel?: () => void;
    /** Use the object url of the image file for the thumbnail */
}

export const ImageDropzone = ({
    title,
    label,
    cancelLabel,
    imageLoadingLabel,
    onRemove = () => {},
    onUpload = (file: File, progress?: ProgressEventAction) => Promise.resolve(),
    onCancel,
    imageFile,
    imageUrl,
}: ImageDropzoneProps) => {
    const { add: canAdd = true, replace: canReplace = true, remove: canRemove = true } =  {};
    const { prevProps } = useViewProps({ imageFile, imageUrl });

    const [file, setFile] = React.useState<File | undefined>();
    const [preview, setPreview] = React.useState<string>();
    const [progress, setProgress] = React.useState<number>(0);

    //#region //* CLASSES

    const enhancedRootClasses = classNames({ 'rte-image-dropzone-container': true });

    //#endregion

    React.useEffect(() => {
        setFile(imageFile);
        setPreview(getFilePreview(imageFile));
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (prevProps) {
            if (
                (prevProps.imageFile && imageFile && prevProps.imageUrl !== imageUrl) ||
                (!prevProps.imageFile && imageFile)
            ) {
                setProgress(0);
                setFile(imageFile);
                setPreview(getFilePreview(imageFile));
            } else if (prevProps.imageFile && !imageFile) {
                setProgress(0);
                setFile(undefined);
                setPreview(undefined);
            }
        }
        // eslint-disable-next-line
    }, [imageFile, imageUrl]);

    const onDrop = (files: Array<File> = []) => {
        const fileToUpload: File = files[0];

        if (prevProps && prevProps.imageFile) {
            onRemove();
        }

        setProgress(0.001);
        setFile(fileToUpload);
        setPreview(getFilePreview(fileToUpload));

        onUpload(fileToUpload, (event: UploadProgressEvent) => {
            setProgress(event.percent);
        });
    };

    const handleRemove = () => {
        onRemove();
        setFile(undefined);
        setPreview(undefined);
        setProgress(0);
    };

    const getFormattedUrl = (url?: string) => {
        if (!url) {
            return '';
        }

        const questionMarkIndex = url ? url.indexOf('?') : 0;
        const baseUrl = questionMarkIndex > 0 ? url.substring(0, questionMarkIndex) : url;
        const search = questionMarkIndex > 0 ? url.substring(questionMarkIndex) : '';

        if (baseUrl.startsWith('data:image/png;base64')) {
            return baseUrl;
        }

        return `${baseUrl}${qs.stringify(
            {
                ...qs.parse(search, { ignoreQueryPrefix: true }),
                aspect: 'fit',
                width: 200,
                height: 200,
            },
            qsOptions
        )}`;
    };

    const handleCancelUpload = () => {
        if (onCancel) {
            onCancel();
        }
        setFile(undefined);
        setPreview(undefined);
        setProgress(0);
    };

    const renderActions = () => {
        if (file || preview || imageUrl) {
            return (
                <div className="actions">
                    {canReplace && <div className="icon" onClick={openDropzone}><Assets.Pen/></div>}
                    {canRemove && (
                        <div onClick={handleRemove} className="icon"><Assets.Trash/></div>
                    )}
                </div>
            );
        }
    };

    const renderDropzoneContent = () => {
        if (file || preview || imageUrl) {
            return (
                <div className="image-dropzone-content">
                    <LoadableImage
                        alt="preview"
                        className="preview-image"
                        src={getFormattedUrl(imageUrl)}
                        classes={{ root: 'preview-image-loadable-root' }}
                    />
                </div>
            );
        }

        return (
            <div className="image-dropzone-content">
                <div className="icon"><Assets.Camera /></div>
                <div className="title">{title}</div>
            </div>
        );
    };

    const hasImage = !!imageUrl;

    const dropzoneClassNames = classNames({
        dropzone: true,
        disabled: (file && !canReplace) || (!file && !canReplace),
        dashBorder: !hasImage,
    });

    const showUpload = (progress > 0.0 && progress !== 1) || (progress === 1 && !hasImage);

    const uploadIsDoneButNotRequest = progress !== null && Math.round(progress * 100) === 100;

    if (showUpload) {
        return (
            <div className="image-progress-upload-container">
                <ProgressUpload
                    progress={progress * 100}
                    indeterminate={uploadIsDoneButNotRequest}
                    abort={handleCancelUpload}
                    cancelLabel={cancelLabel}
                />
                {uploadIsDoneButNotRequest && (
                    <div className="upload-secondary-loading-message">
                        {imageLoadingLabel}
                    </div>
                )}
            </div>
        );
    }

    if (!canAdd && !file && !imageUrl) {
        return null;
    }

    const { openDropzone, DropzoneView } = DropImageView({
        accept: 'image/*',
        onDrop,
        dropzoneClassNames,
        disabled: (file && !canReplace) || (!file && !canReplace),
        renderDropzoneContent,
    });

    return (
        <div className={enhancedRootClasses}>
            <div className="image-dropzone-title">{label}</div>
            <div className="dropzone-container">
                <DropzoneView />
                {renderActions()}
            </div>
        </div>
    );
};
