import { ErrorHandlerName, IErrorHandler } from "../Libs/xhr/ErrorHandler";
import { Constants } from "./Constants";
import { ArtWorkDataService, ArtWorkDataServiceName, IArtWorkDataService } from "./DataServices/ArtWorkDataService";
import { AuthDataService, AuthDataServiceName, IAuthDataService } from "./DataServices/AuthenticationDataService";
import {
  ExhibitionDataService,
  ExhibitionDataServiceName,
  IExhibitionDataService,
} from "./DataServices/ExhibitionDataService";
import { IMeDataService, MeDataServiceName, MeDataService } from "./DataServices/MeDataService";
import { IMediaDataService, MediaDataService, MediaDataServiceName } from "./DataServices/MediaDataService";
import { INewsDataService, NewsDataService, NewsDataServiceName } from "./DataServices/NewsDataService";
import { CustomErrorHandler } from "./Errors/CustomErrorHandler";
import { initializeApiClient } from "./Services/Data/Api/ApiService";
import { ArtWorkService, ArtWorkServiceName, IArtWorkService } from "./Services/Data/ArtWork/ArtWorkService";
import { AuthService, AuthServiceName, IAuthService } from "./Services/Data/Authentication/AuthenticationService";
import { I18nService, I18nServiceName, II18nService } from "./Services/Data/I18n/I18nService";
import { IMeService, MeService, MeServiceName } from "./Services/Data/Me/MeService";
import { LogService } from "./Services/LogService";
import { IoC } from "./Services/ServicesContainer";

export abstract class CoreBootstrapper {
  protected initializeCore = (): void => {
    const appErrorHandler = IoC.get<IErrorHandler>(ErrorHandlerName);

    //* Setup Api client
    const timeout = 1000 * 60; //* 1 minute;
    initializeApiClient(Constants.getRoot(), appErrorHandler, timeout);
  };

  protected initializeCoreServices = (): void => {
    //* Create and bind I18nService
    const i18nService: II18nService = new I18nService();
    IoC.bind<II18nService>(I18nServiceName, i18nService);

    //* Create and bind App ErrorHandler
    IoC.bind<IErrorHandler>(ErrorHandlerName, new CustomErrorHandler(i18nService, new LogService()));

    //* Register Redux services
    IoC.bind<IAuthDataService>(AuthDataServiceName, new AuthDataService());
    IoC.bind<IAuthService>(AuthServiceName, new AuthService());
    IoC.bind<IMeService>(MeServiceName, new MeService());
    IoC.bind<IMeDataService>(MeDataServiceName, new MeDataService());

    //* Register services
    IoC.bind<IArtWorkService>(ArtWorkServiceName, new ArtWorkService());
    IoC.bind<IArtWorkDataService>(ArtWorkDataServiceName, new ArtWorkDataService());
    IoC.bind<IExhibitionDataService>(ExhibitionDataServiceName, new ExhibitionDataService());
    IoC.bind<INewsDataService>(NewsDataServiceName, new NewsDataService());
    IoC.bind<IMediaDataService>(MediaDataServiceName, new MediaDataService());
  };
}
