import IconButton from "@material-ui/core/IconButton";
import className from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";
import iconError from "../../../assets/icons/ToastIcons/error.svg";
import iconInfo from "../../../assets/icons/ToastIcons/info.svg";
import iconSuccess from "../../../assets/icons/ToastIcons/success.svg";
import { Icons } from "../Icons/Icons";
import "./CustomToast.scss";

enum CustomToastType {
    ERROR = "Error",
    SUCCESS = "Success",
    INFO = "Info",
}

interface CustomToastProps {
    type: CustomToastType;
    content: React.ReactNode;
}

const CustomToast = ({ type, content }: CustomToastProps): React.FunctionComponent => {
    const toastClass = className("custom-toast-container", { [type]: type });
    let icon: string;

    switch (type) {
        case "Error":
            icon = iconError;
            break;
        case "Success":
            icon = iconSuccess;
            break;
        default:
            icon = iconInfo;
    }

    return ({ closeToast }: any) => (
        <div className={toastClass}>
            <img src={icon} alt="delete" className="toast-icon"/>
            <div className="body">
                <div>
                    <div className="title">
                        <FormattedMessage id={`Toast_Title${type}`}/>
                    </div>
                    <div className="content">{content}</div>
                </div>
                <IconButton className="close-icon" onClick={closeToast} children={Icons.IconClose}/>
            </div>
        </div>
    );
};

export { CustomToast, CustomToastType };
