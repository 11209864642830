import React, { HTMLAttributes } from "react";
import icChevronRight from "../../../assets/icons/ic_chevronRightBlack.svg";
import icSearch from "../../../assets/icons/ic_searchOrange.svg";
import icClose from "../../../assets/icons/ic_closeOrange.svg";
import icSelect from "../../../assets/icons/ic_arrowDownOrange.svg";
import icAdd from "../../../assets/icons/ic_addWhite.svg";
import icImport from "../../../assets/icons/ic_import.svg";
import icSave from "../../../assets/icons/ic_save.png";
import paginationNext from '../../../assets/icons/ic_pagination_next.svg';
import paginationPrevious from '../../../assets/icons/ic_pagination_previous.svg';

//* Media input
import icAddMedia from "../../../assets/icons/ic_media_input_add.svg";
import icEditMedia from "../../../assets/icons/ic_media_input_edit.svg";
import icDeleteMedia from "../../../assets/icons/ic_media_input_delete.svg";

//* Paginated table
import icEndRow from "../../../assets/icons/PaginatedTable/ic_paginatedTableEndRow.svg";

//* Radio button
import icRadioNormal from "../../../assets/icons/ic_radio.svg";
import icRadioChecked from "../../../assets/icons/ic_radioChecked.svg";
export interface IconProps {
  src: string;
  name?: string;
  className?: string;
  inlineStyle?: HTMLAttributes<HTMLImageElement>;
}

export const Icon = ({ src, name, className, inlineStyle }: IconProps): React.ReactElement => {
  return <img className={className} src={src} alt={name} style={inlineStyle} />;
};

export class Icons {
  public static IconChevronRight = Icon({
    src: icChevronRight,
    name: "chevron right",
  });

  public static IconSearch = Icon({
    src: icSearch,
    name: "search",
  });

  public static IconClose = Icon({
    src: icClose,
    name: "clear",
  });

  public static IconSelect = Icon({
    src: icSelect,
    name: "select",
  });

  public static IconAdd = Icon({
    src: icAdd,
    name: "add",
  });

  public static IconImport = Icon({
    src: icImport,
    name: "import",
  });

  public static IconSave = Icon({
    src: icSave,
    name: "save",
  });

  //* Paginated table
  public static IconEndRow = Icon({
    src: icEndRow,
    name: "end row",
  });

  //* Media Input
  public static IconMediaAdd = Icon({
    src: icAddMedia,
    name: "add",
  });

  public static IconMediaEdit = Icon({
    src: icEditMedia,
    name: "edit",
  });

  public static IconMediaDelete = Icon({
    src: icDeleteMedia,
    name: "delete",
  });

  //* Radio button
  public static IconRadioChecked = Icon({
    src: icRadioChecked,
    name: "checked",
  });

  public static IconRadioNormal = Icon({
    src: icRadioNormal,
    name: "not checked",
  });

  public static IconPaginationNext = Icon({
    src: paginationNext,
    name: "next page",
  });

  public static IconPaginationPrevious = Icon({
    src: paginationPrevious,
    name: "previous page",
  });
}

export default Icon;
