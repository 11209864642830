import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import './NoResult.scss';


export interface NoResultProps {
    className: string;
}

const NoResult = ({ className }: NoResultProps) => {
    return (
        <div
            className={classNames({
                'NoResult-root': true,
                [className as string]: !!className,
            })}
        >
            <FormattedMessage id="COMMON_EMPTY_MESSAGE" />
        </div>
    );
};

export { NoResult };
