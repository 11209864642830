import { Messages } from '../Services/Data/I18n/typings/LocalMessageActionTypes';

export class ValidatorsUtils {
    static checkError = (
        value: any,
        isErrorCallBack: (value: any) => boolean,
        errors: Messages,
        fieldName: string,
        errorMessage: string
    ): Messages => {
        if (isErrorCallBack(value)) {
            return errors;
        } else {
            errors[fieldName] = errorMessage;
            return errors;
        }
    };

    static checkRequired = (
        value: any,
        errors: Messages,
        fieldName: string,
        errorMessage: string
    ): Messages => {
        if (ValidatorsUtils.IsValidRequired(value)) {
            return errors;
        } else {
            errors[fieldName] = errorMessage;
            return errors;
        }
    };

    static IsValidRequired = (value: any) => {
        return !(value === '' || value === undefined || value === null);

    };

    public static checkIfValidEmail = (value: string) => {
        const pattern = /^[a-z0-9._-]+@[a-z0-9._-]{2,}\.[a-z]{2,4}$/;

        return pattern.exec(value);
    };

    public static checkIfValidPassword = (value: string) => {
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_-])[A-Za-z\d@$!%*?&_-]{8,}$/;

        return pattern.exec(value);
    };
}
