import { Route } from "react-router-dom";

import { AppRoutes } from "../../../Core/App/AppRoutes";
import { AppRouteType } from "../../Components/Templates/AppRouter/AppRouteType";
import { LoginPage } from "../../Pages/Auth/LoginPage/LoginPage";
import { ForgotPasswordPage } from "../../Pages/Auth/ForgotPasswordPage/ForgotPasswordPage";
import { ResetPasswordPage } from "../../Pages/Auth/ResetPasswordPage/ResetPasswordPage";

const loginRoute: AppRouteType = {
  id: AppRoutes.LOGIN,
  render: Route,
  props: {
    exact: true,
    path: AppRoutes.LOGIN,
    component: LoginPage,
  },
};

const forgotPasswordRoute: AppRouteType = {
  id: AppRoutes.FORGOT_PASSWORD,
  render: Route,
  props: {
    exact: true,
    path: AppRoutes.FORGOT_PASSWORD,
    component: ForgotPasswordPage,
  },
};

const resetPasswordRoute: AppRouteType = {
  id: AppRoutes.RESET_PASSWORD,
  render: Route,
  props: {
    exact: true,
    path: AppRoutes.RESET_PASSWORD,
    component: ResetPasswordPage,
  },
};

export const getAuthRoutes = () => [loginRoute, forgotPasswordRoute, resetPasswordRoute];
