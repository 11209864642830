import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../Configs/store/AppReducer";
import { AuthServiceName, IAuthService } from "../../../Core/Services/Data/Authentication/AuthenticationService";
import { MeState } from "../../../Core/Services/Data/Me/typings/MeState";
import { IoC } from "../../../Core/Services/ServicesContainer";
import { UserUtils } from "../../../Core/Utils/UserUtils";

export interface UserLoggedState {
  initialized: boolean;
  name: string;
  requestLogout: (onLogoutFinished: () => void) => void;
}

const DefaultUserLoggedState: UserLoggedState = {
  initialized: false,
  name: "",
  requestLogout: () => {},
};

export const useAuthenticatedUser = (): UserLoggedState => {
  const [state, setState] = useState(DefaultUserLoggedState);
  const me: MeState = useSelector((state: AppState) => state.Me);

  useEffect(() => {
    const requestLogout = (onLogoutFinished: () => void): void => logout(onLogoutFinished);

    setState({
      initialized: me?.initialized,
      name: UserUtils.getFullName(me?.data),
      requestLogout,
    });
  }, [me]);

  const logout = (onLogoutFinished: () => void): void => {
    const authService = IoC.get<IAuthService>(AuthServiceName);
    authService.logout("user click on logout");
    onLogoutFinished();
  };

  return state;
};
