import React from "react";
import { FormattedMessage } from "react-intl";
import { FileInputValue } from "../../../../Core/Models/Files/File";
import { CustomButton } from "../../../Components/Views/CustomButton/CustomButton";
import { CustomCard } from "../../../Components/Views/CustomCard/CustomCard";
import { CustomInputText } from "../../../Components/Views/CustomInputText/CustomInputText";
import { MediaInput } from "../../../Components/Views/MediaInput/MediaInput";
import { InputTextFieldProps } from "../../../Hooks/Form/useInputProps";
import { AuthenticatedPageLayout } from "../../AuthenticatedPageLayout/AuthenticatedPageLayout";
import { UniqueTab } from "../../Exhibitions/ExhibitionDetailsPage/ExhibitionDetailsPage";
import "./NewsDetailsPage.scss";
import { useNewsDetailsPageController } from "./NewsDetailsPageController";

const NewsDetailsPage = () => {
  const { vm, state, currentLanguageState, registerLocalizedField } = useNewsDetailsPageController();

  const nameProps = registerLocalizedField("title");
  const descriptionProps = registerLocalizedField("content");
  const title = state.mode === "create" ? <FormattedMessage id="NewsListPage_addNews" /> : currentLanguageState.title;
  return (
    <AuthenticatedPageLayout title={title}>
      <UniqueTab
        label={<FormattedMessage id="NewsDetailsPage_Details_Label_Tab" />}
        changeCurrentLanguage={vm.changeCurrentLanguage}
        currentLanguage={state.currentLanguage}
        localizedErrors={state.fieldsErrors.localized_content}>
        <Card titleIntlId="NewsDetailsPage_Details_Label">
          <NewsForm
            nameProps={nameProps}
            descriptionProps={descriptionProps}
            localPicture={state.localized_content[state.currentLanguage].localPicture}
            onChangePicture={(file) => vm.onChangeLocalizedField("localPicture", file)}
          />
          <div className="row">
            <div className="NewsDetailsPage-actions">
              <CustomButton
                title={<FormattedMessage id="COMMON_PUBLISH" />}
                onClick={vm.onSubmit}
                className="NewsDetailsPage-actions-btn"
              />
            </div>
          </div>
        </Card>
      </UniqueTab>
    </AuthenticatedPageLayout>
  );
};

export const NewsForm = ({
  nameProps,
  descriptionProps,
  onChangePicture,
  localPicture,
}: {
  nameProps: InputTextFieldProps;
  descriptionProps: InputTextFieldProps;

  localPicture: FileInputValue[];
  onChangePicture: (file: FileInputValue[]) => void;
}) => {
  return (
    <div className="NewsFormContainer">
      <div className="row">
        <div className="col">
          <div className="NewsDetailsPage-body">
            <CustomInputText {...nameProps} labelIntlId="NewsDetailsPage_NewsName" />
            <CustomInputText
              {...descriptionProps}
              labelIntlId="NewsDetailsPage_NewsDescription"
              multiline
              classes={{ input: "NewsDetailsPage_NewsDescription-input" }}
            />
          </div>
        </div>
        <div className="col">
          <MediaInput
            value={localPicture}
            onChange={onChangePicture}
            labelIntlId="ArtWorkInformationTab_InputPictureLabel"
            maxCount={1}
          />
        </div>
      </div>
    </div>
  );
};

const Card = (props: React.ComponentProps<typeof CustomCard>) => (
  <CustomCard
    {...props}
    classes={{
      root: "NewsDetailsPage-card",
      body: "NewsDetailsPage-card-body",
    }}
  />
);

export { NewsDetailsPage };
