import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { MediaType } from "../../../Web/Components/Views/MediaInput/MediaInput";
import { IMediaDataService, MediaDataServiceName } from "../../DataServices/MediaDataService";
import { Lazy } from "../../Helpers/Lazy";
import { FileInputValue } from "../../Models/Files/File";
import { IoC } from "../../Services/ServicesContainer";
import { MessageUtils } from "../../Utils/MessageUtils";
import { ValidatorsUtils } from "../../Utils/ValidatorsUtils";
import { BaseEditOrCreateViewModel } from "../Base/BaseEditOrCreateViewModel";
import { BaseViewState } from "../Base/BaseViewModel";
import { Messages } from "../../Services/Data/I18n/typings/LocalMessageActionTypes";

export interface VideoDetailsViewState extends BaseViewState {
  title: string;
  thumbnailOffset: number | null;
  id: string | undefined;
  video: FileInputValue[];
  uploadedVideoId: string;
  thumbnailUrl: string;
  originalThumbnailOffset: number;

  fieldsErrors: Messages;
  invalidCredentials: boolean;
  mode: "create" | "edit";
}

export const InitialVideoDetailsViewState: VideoDetailsViewState = {
  title: "",
  thumbnailOffset: null,
  id: undefined,
  video: [],
  uploadedVideoId: "",
  thumbnailUrl: "",
  originalThumbnailOffset: 0,

  fieldsErrors: [],
  invalidCredentials: false,
  mode: "create",
};

export class VideoDetailsViewModel extends BaseEditOrCreateViewModel<VideoDetailsViewState> {
  private readonly mediaDataService: Lazy<IMediaDataService>;

  constructor() {
    super(InitialVideoDetailsViewState);
    this.mediaDataService = IoC.getLazy<IMediaDataService>(MediaDataServiceName);
  }

  public getEditItemState = async (itemId: string, cts: XHRRequestCanceler) => {
    const response = await this.mediaDataService.value().getVideoDetails(itemId, cts);
    const thumbnail = await this.mediaDataService.value().getVideoThumbnailUrl(response.data.video_id, cts);
    return {
      ...this.state,
      id: itemId,
      thumbnailOffset: response.data.thumbnail_offset,
      originalThumbnailOffset: response.data.thumbnail_offset,
      thumbnailUrl: thumbnail.data.url,
      title: response.data.title,
      video: [{ url: response.data.url, id: response.data.video_id, type: MediaType.video }],
    };
  };

  protected validateFields = () => {
    const errors: Messages = {};
    const requiredFieldError = this.getString("COMMON_REQUIRED_FIELD");

    const { title, video } = this.state;
    ValidatorsUtils.checkRequired(title, errors, "title", requiredFieldError);
    if (video.length < 1) {
      errors["video"] = requiredFieldError;
    }

    this.setState({ ...this.state, fieldsErrors: errors });
    return MessageUtils.isEmpty(errors);
  };

  protected save = async (cts: XHRRequestCanceler) => {
    if (!this.state.id) {
      await this.uploadMedias(cts);
      await this.mediaDataService
        .value()
        .setVideoTitle({ title: this.state.title, video_id: this.state.uploadedVideoId });
      this.navigationService.value().goToVideoList();
    } else {
      if (this.state.thumbnailOffset && this.state.originalThumbnailOffset !== this.state.thumbnailOffset) {
        await this.mediaDataService.value().updateVideoTitle(this.state.id, {
          title: this.state.title,
          video_id: this.state.video[0].id,
          thumbnail_offset: this.state.thumbnailOffset,
        });
        await this.mediaDataService
          .value()
          .createVideoThumbnailUrl(this.state.video[0].id || "", this.state.thumbnailOffset, cts);
      } else {
        await this.mediaDataService.value().updateVideoTitle(this.state.id, {
          title: this.state.title,
          video_id: this.state.video[0].id,
        });
      }
    }
  };

  private uploadMedias = async (cts: XHRRequestCanceler) => {
    const { video } = this.state;

    if (video[0].file) {
      const response = await this.mediaDataService.value().uploadVideo(video[0].file, () => {}, undefined);
      this.setState({ ...this.state, uploadedVideoId: response.data.video_id });
    }
  };
}
