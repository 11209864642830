import { Editor, Path, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { CustomElement, ElementType, ImageElement } from "../CustomTypes.d";
import { createImageNode } from "./CreateCustomNodes";

export interface InsertImageProps {
  editor: Editor;
  url?: string;
}

export const insertImage = ({editor, url}: InsertImageProps) => {
  if (!url) return;

  const { selection } = editor;
  const image = createImageNode(url, "Image") as ImageElement;

  ReactEditor.focus(editor);

  if (!!selection) {
    const [parentNode, parentPath] = Editor.parent(editor, selection.focus?.path);
    const customNode = parentNode as CustomElement;

    // We check if the currently selected Node is void or empty and insert the image Node after it
    if (editor.isVoid(parentNode as CustomElement) || !Editor.isEmpty(editor, parentNode as CustomElement)) {
      // We check if the currently selected Node is a link to handle special behaviour and add the image Node after it
      if (customNode.type === ElementType.link) {
        Transforms.move(editor, { unit: "line" });
        Transforms.move(editor, { unit: "offset" });
        const select = editor.selection;
        if (select) {
          const parentPath = Path.parent(select.focus.path);
          Transforms.insertNodes(editor, image, {
            at: Path.next(parentPath),
            select: true,
          });
        }
      } else {
        Transforms.insertNodes(editor, image, {
          at: Path.next(parentPath),
          select: true,
        });
      }
    } else {
      // If the node is empty, replace it with the image instead
      Transforms.removeNodes(editor, { at: parentPath });
      Transforms.insertNodes(editor, image, { at: parentPath, select: true });
    }
  } else {
    // Insert the new image node at the bottom of the Editor when editor is not selected
    Transforms.insertNodes(editor, image, { select: true });
  }
};
