import { ArtWorkInformationContent } from "./ArtWorkInformationTabViewModel";

export function isArtWorkInformationContentEmpty(content: ArtWorkInformationContent) {
  return (
    content.title === "" &&
    content.artist_name === "" &&
    content.description === "" &&
    content.short_description === "" &&
    (content.localPicture === undefined || content.localPicture.length === 0)
  );
}
