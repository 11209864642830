import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { OptionalRouteParams } from "../../../../../Core/Models/RouteParams";
import {
  ArtWorkInformationTabViewModel,
  ArtWorkInformationTabViewState,
} from "../../../../../Core/ViewModels/ArtWork/ArtWorkInformationTabViewModel";
import { ViewModelLocator } from "../../../../../Core/ViewModels/ViewModelLocator";
import { useInputTextFieldProps } from "../../../../Hooks/Form/useInputProps";
import { useLifecycle } from "../../../../Hooks/Lifecycle/useLifecycle";
import { CustomPrompt } from "../../../../Hooks/Prompt/CustomPrompt.js";

export type ArtWorkInformationTabControllerData = ReturnType<typeof useArtWorkInformationTabController>;

export const useArtWorkInformationTabController = (setName: (name: string) => void) => {
  const { id } = useParams<OptionalRouteParams>();

  const { vm, state } = useLifecycle<ArtWorkInformationTabViewState, ArtWorkInformationTabViewModel>({
    getRef: () => ViewModelLocator.ArtWorkInformationTabVM,
    setRefValue: (value) => (ViewModelLocator.ArtWorkInformationTabVM = value),
    newInstance: () => new ArtWorkInformationTabViewModel(setName),
    logName: "ArtWorkInformationTabVM",
  });

  useEffect(() => {
    vm.onItemChange(id);
  }, [id, vm]);

  const prompt = useRef(new CustomPrompt());

  useEffect(() => {
    prompt.current.updateBlock(state.modified);
  }, [state.modified]);

  const currentLanguageState = {
    ...state.localized_content[state.currentLanguage],
    fieldsErrors: state.fieldsErrors?.localized_content?.[state.currentLanguage] ?? {},
  };

  const registerLocalizedField = useInputTextFieldProps(currentLanguageState, vm.onChangeLocalizedField, {
    hideLabel: true,
    i18nPrefix: "ArtWorkInformationTab",
  });

  const registerField = useInputTextFieldProps(state, vm.onChangeField, {
    hideLabel: true,
    i18nPrefix: "ArtWorkInformationTab",
  });

  return { vm, state, registerLocalizedField, registerField };
};
