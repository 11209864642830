import classNames from 'classnames';
import React from 'react';
import Dropzone, { DropzoneRef } from 'react-dropzone';

interface DropImageViewProps {
    readonly onDrop?: (files: Array<File>) => void;
    readonly accept?: any;
    readonly disabled?: boolean;
    readonly dropzoneClassNames?: string;
    readonly renderDropzoneContent?: () => React.ReactNode;
}

interface RenderDropzoneViewProps {
    readonly classes?: { root?: string };
}

export const DropImageView = ({
    onDrop,
    accept,
    renderDropzoneContent,
    dropzoneClassNames,
    disabled,
}: DropImageViewProps) => {
    let dropzoneRef: DropzoneRef | null = null;
    const openDropzone = () => {
        if (dropzoneRef) {
            dropzoneRef.open();
        }
    };
    return {
        DropzoneView: (props: React.PropsWithChildren<RenderDropzoneViewProps> = {}) => {
            return (
                <Dropzone
                    ref={(ref) => (dropzoneRef = ref)}
                    accept={accept}
                    onDrop={onDrop}
                    multiple={false}
                    disabled={disabled}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div
                            {...getRootProps()}
                            style={{ display: renderDropzoneContent ? 'block' : 'none' }}
                            className={classNames(dropzoneClassNames)}
                        >
                            {renderDropzoneContent && renderDropzoneContent()}
                            <input {...getInputProps()} />
                        </div>
                    )}
                </Dropzone>
            );
        },
        openDropzone,
    };
};
