import { FormControl } from "@material-ui/core";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import "./InputWrapper.scss";

export interface InputWrapperPropsBase {
  className?: string;
  labelIntlId?: string;
  errorMessage?: string;
  hasError?: boolean;
}

export interface InputWrapperProps extends InputWrapperPropsBase {
  children: React.ReactNode;
}

export const InputWrapper = ({ children, labelIntlId, hasError, errorMessage, className }: InputWrapperProps) => {
  const error = hasError || errorMessage;
  const wrapperClasses = classNames({ "InputWrapper-root": true, [className as string]: className });
  const labelClasses = classNames({ "Input-label": true, error });

  return (
    <FormControl className={wrapperClasses}>
      {labelIntlId && <div className={labelClasses}>{<FormattedMessage id={labelIntlId} />}</div>}
      {children}
      {errorMessage && <div className="ErrorMessage">{errorMessage}</div>}
    </FormControl>
  );
};
