import {
  ForgotPasswordViewModel,
  ForgotPasswordViewState,
} from "../../../../Core/ViewModels/Auth/ForgotPasswordViewModel";
import { useInputTextFieldProps } from "../../../Hooks/Form/useInputProps";
import { ViewModelLocator } from "../../../../Core/ViewModels/ViewModelLocator";
import { usePreventDefault } from "../../../Hooks/Form/usePreventDefault";
import { useLifecycle } from "../../../Hooks/Lifecycle/useLifecycle";
import { AppRoutes } from "../../../../Core/App/AppRoutes";
import { useRedirectIfAuthenticated } from "../../../Hooks/Authentication/useRedirectIfAuthenticated";

export const useForgotPasswordPageController = () => {
  const { vm, state } = useLifecycle<ForgotPasswordViewState, ForgotPasswordViewModel>({
    getRef: () => ViewModelLocator.ForgotPasswordVM,
    setRefValue: (value) => (ViewModelLocator.ForgotPasswordVM = value),
    newInstance: () => new ForgotPasswordViewModel(),
    logName: "ForgotPasswordVM",
  });

  useRedirectIfAuthenticated(AppRoutes.ART_WORK_LIST);

  const onSubmit = usePreventDefault(vm.onSubmit);
  const registerField = useInputTextFieldProps(state, vm.onChangeField, {
    hideLabel: true,
    i18nPrefix: "ForgotPasswordPage",
  });

  return { vm, state, onSubmit, registerField };
};
