import { useApiDataResponse } from "@ideine/web-common";
import { FormattedMessage } from "react-intl";
import { Constants } from "../../../Core/Constants";
import { ApiUrls } from "../../../Core/Models/ApiUrls";
import { BoUsersDto, BoUsersListResponse } from "../../../Core/Models/BoUsers/BoUsersResponse";
import { getNavigationService } from "../../../Core/Services/Interfaces/INavigationService";
import { fetcherWithToastOnError } from "../../../Utils/fetcher";
import { CustomButton } from "../../Components/Views/CustomButton/CustomButton";
import { Icons } from "../../Components/Views/Icons/Icons";
import { PaginatedTable } from "../../Components/Views/PaginatedTable/PaginatedTable/PaginatedTable";
import { AuthenticatedPageLayout } from "../AuthenticatedPageLayout/AuthenticatedPageLayout";
import "./UserListPage.scss";
export const useUserListPageController = () => {
  const { data } = useApiDataResponse<BoUsersListResponse>(ApiUrls.BO_USER_LIST, fetcherWithToastOnError);
  return { users: data?.data };
};

export const UserListPage = () => {
  const { users } = useUserListPageController();

  return (
    <AuthenticatedPageLayout title={<FormattedMessage id="UserListPage_Title" />} addDefaultPadding>
      <div className="UserListPage-actions">
        <CustomButton
          title={<FormattedMessage id="UserListPage_Create" />}
          icon={Icons.IconAdd}
          onClick={() => getNavigationService().value().goToBoUserCreate()}
        />
      </div>

      <PaginatedTable<BoUsersDto>
        items={users ?? []}
        headerColumns={[
          <FormattedMessage id="UserListPage_Header_Name" />,
          <FormattedMessage id="UserListPage_Header_Role" />,
        ]}
        getCellsData={(item) => [
          <FormattedMessage id="COMMON_FULLNAME" values={{ firstName: item.first_name, lastName: item.last_name }} />,
          <FormattedMessage id={`BoUser_Roles_${item.role}`} />,
        ]}
        onRowClick={(item: BoUsersDto) => getNavigationService().value().goToBoUserDetails(item.id)}
        itemsPerPage={Constants.DEFAULT_ITEM_COUNT_PER_PAGE}
      />
    </AuthenticatedPageLayout>
  );
};
