import { ArtWorkListViewModel, ArtWorkListViewState } from "../../../Core/ViewModels/ArtWork/ArtWorkListViewModel";
import { ViewModelLocator } from "../../../Core/ViewModels/ViewModelLocator";
import { useLifecycle } from "../../Hooks/Lifecycle/useLifecycle";

export const useArtWorkListPageController = () => {
  const { vm, state } = useLifecycle<ArtWorkListViewState, ArtWorkListViewModel>({
    getRef: () => ViewModelLocator.ArtWorkListVM,
    setRefValue: (value) => (ViewModelLocator.ArtWorkListVM = value),
    newInstance: () => new ArtWorkListViewModel(),
    logName: "ArtWorkListVM",
  });

  return { vm, state };
};
