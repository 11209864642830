import { AppRoutes } from "../../Core/App/AppRoutes";
import { getAuthRoutes } from "./Routes/AuthRoutes";
import { getArtWorkRoutes } from "./Routes/ArtWorkRoutes";
import { getVideoRoutes } from "./Routes/VideoRoutes";
import { getExhibitionsRoutes } from "./Routes/ExhibitionRoutes";
import { getNewsRoutes } from "./Routes/NewsRoutes";
import { getBOUserListRoutes } from "./Routes/BoUserRoutes";

export const DEFAULT_ROOT = AppRoutes.EXHIBITIONS_LIST;

export const getAppRoutes = () => [
  ...getAuthRoutes(),
  ...getArtWorkRoutes(),
  ...getExhibitionsRoutes(),
  ...getVideoRoutes(),
  ...getNewsRoutes(),
  ...getBOUserListRoutes(),
];
