import { Text } from "slate";
import { CustomElement, CustomText, ElementType, ImageElement, ParagraphElement } from "../CustomTypes.d";
import { jsx } from "slate-hyperscript";

// Used to convert old richtext data into new data
export const normalizeNodes: any = (nodes: CustomElement & CustomText, getUrl: any) => {
    let normalizedNodes: CustomElement[] = [];
    let nodesToBeNormalized: CustomText[] = [];
    nodes.children.forEach((node: any) => {
        const nodeElement = node as CustomElement;
        if (nodeElement.type === ElementType.image) {
            const nodeImage = nodeElement as ImageElement;
            if (!nodeImage.src.includes("Bearer")) {
                nodeImage.src = getUrl({ id: "", url: nodeImage.src }, { height: 200 });
            }
        }
        if (nodeElement.type === undefined) {
            const nodeText = node as CustomText;
            if (nodeText.text === "" && (nodeText.bold || nodeText.italic || nodeText.underlined)) {
                nodesToBeNormalized.push({ text: "" });
            } else {
                nodesToBeNormalized.push(nodeText);
            }
        } else if (nodesToBeNormalized.length > 0) {
            const newNode: ParagraphElement = { type: ElementType.paragraph, children: nodesToBeNormalized };
            normalizedNodes.push(newNode);
            nodesToBeNormalized = [];
            normalizedNodes.push(nodeElement);
        } else {
            normalizedNodes.push(nodeElement);
        }
    });

    if (nodesToBeNormalized.length > 0) {
        const newNode: ParagraphElement = { type: ElementType.paragraph, children: nodesToBeNormalized };
        normalizedNodes.push(newNode);
    }

    return normalizedNodes;
};

// Used to transform slate childrens into HTML
export const HTMLSerializer: any = (node: any) => {
    if (Text.isText(node)) {
        let string = node.text;
        // Setting format style
        if (node.bold) {
            string = `<strong>${string}</strong>`;
        }
        if (node.italic) {
            string = `<em>${string}</em>`;
        }
        if (node.underlined) {
            string = `<u>${string}</u>`;
        }
        return string;
    }
    // @ts-ignore
    const children = node.children.map((n) => HTMLSerializer(n)).join("");

    // Setting different types of elements
    switch (node.type) {
        case ElementType.paragraph:
            return `<div>${children}</div>`;
        case ElementType.link:
            return `<a href="${node.href}">${children}</a>`;
        case ElementType.video:
            return `<video thumbnailUrl="${node.thumbnailUrl}" src="${node.src}" videoId="${node.videoId}">${children}</video>`;
        case ElementType.image:
            return `<img src="${node.src.split("?")[0]}" alt="${node.alt}">${children}</img>`;
        default:
            return children;
    }
};

/**
 * Return the index of the closing pattern (in case of nested patterns)
 * Work only if there is a pattern first
 */
const endIndexOfClosingTag = (s: string, pattern: string, closingPattern: string) => {
    let lastOpenIndex = s.indexOf(pattern);

    if (lastOpenIndex === -1) {
        //VJU 22/11/2023 : si le premier pattern n'existe pas, on renvoie -1
        return -1;
    }

    //VJU 22/11/2023 : dans la boucle on va se décaller du dernier pattern (pour pas toujours trouver le même)
    //mais pour le premier tour on veut chercher à partir de 0 (donc on décalle en négatif pour que l'addition donne 0)
    let lastClosingIndex = -closingPattern.length;
    let count = 1, cpt = 0;
    do {
        cpt++;
        //VJU 22/11/2023 : on trouve l'index du prochain closing pattern
        const closingIndex = s.indexOf(closingPattern, lastClosingIndex + closingPattern.length)
        //VJU 22/11/2023 : on trouve l'index du prochain openning pattern
        const openIndex = s.indexOf(pattern, lastOpenIndex + pattern.length)
        if (openIndex >= 0 && openIndex < closingIndex) {
            //VJU 22/11/2023 : si on a trouvé un openning pattern et qu'il est avant le closing pattern, on est dans des tags imbriqués, il faut trouver un closing en plus
            count++;
            lastClosingIndex = closingIndex;
            lastOpenIndex = openIndex;
        } else {
            //VJU 22/11/2023 : si on a pas trouvé d'openning pattern ou qu'il est après le closing, on a fermé un tag
            count--;
            lastClosingIndex = closingIndex;
        }
    } while (count > 0 && cpt < 1_000);
    //VJU 22/11/2023 : cpt sert de protection contre les boucles infinis
    return lastClosingIndex + closingPattern.length;
}

/**
 * Wrap text not in p or div into div.
 * Img or video tag will not be in new div
 */
const wrapTextWithDiv = (html: string): string => {
    if (html === "") {
        //VJU 22/11/2023 : si html vide, on arrête la récursivité, on a tout traité
        return html;
    }

    //VJU 22/11/2023 : on trouve les index des img/video/p/div, on veut wrap tout ce qui est en dehors de ça
    //notamment tout ce qui est modificateur de texte, qui sont pas rendus s'ils sont à la racine
    let imgFirstIndex = html.indexOf("<img")
    let videoFirstIndex = html.indexOf("<video")
    let pFirstIndex = html.indexOf("<p>");
    let divFirstIndex = html.indexOf("<div>")

    //VJU 22/11/2023 : les index rendent -1 si non trouvé, mais comme on va faire des min, je les passe à Infinity (en priant pour que tout soit inférieur à Infinity)
    imgFirstIndex = imgFirstIndex < 0 ? Infinity : imgFirstIndex;
    videoFirstIndex = videoFirstIndex < 0 ? Infinity : videoFirstIndex;
    pFirstIndex = pFirstIndex < 0 ? Infinity : pFirstIndex;
    divFirstIndex = divFirstIndex < 0 ? Infinity : divFirstIndex;

    if (imgFirstIndex === 0) {
        //VJU 22/11/2023 : si on trouve img en premier, on peut skip le tag et essayer sur la suite
        let closingTag = "</img>";
        let closingIndex = html.indexOf(closingTag)

        //VJU 22/11/2023 : l'api peut supprimer les closingTag et les self closing, donc si on n'a pas trouvé le closingTag on cherche juste la fin du tag courant
        if (closingIndex === -1) {
            closingTag = ">";
            closingIndex = html.indexOf(closingTag);
        }

        //VJU 22/11/2023 : on veut split après le closingTag donc on rajoute length
        closingIndex = closingIndex + closingTag.length;
        const splittedHtml = { firstPart: html.substring(0, closingIndex), secondPart: html.substring(closingIndex) }
        return `${splittedHtml.firstPart}${wrapTextWithDiv(splittedHtml.secondPart)}`;
    }
    if (videoFirstIndex === 0) {
        //VJU 22/11/2023 : si on trouve video en premier, on peut skip le tag et essayer sur la suite
        let closingTag = "</video>";
        let closingIndex = html.indexOf(closingTag);

        //VJU 22/11/2023 : l'api peut supprimer les closingTag et les self closing, donc si on n'a pas trouvé le closingTag on cherche juste la fin du tag courant
        if (closingIndex === -1) {
            closingTag = ">";
            closingIndex = html.indexOf(">");
        }

        //VJU 22/11/2023 : on veut split après le closingTag donc on rajoute length
        closingIndex = closingIndex + closingTag.length;
        const splittedHtml = { firstPart: html.substring(0, closingIndex), secondPart: html.substring(closingIndex) }
        return `${splittedHtml.firstPart}${wrapTextWithDiv(splittedHtml.secondPart)}`;
    } else if (pFirstIndex === 0) {
        //VJU 22/11/2023 : si on trouve p, on trouve le tag fermant et on regarde la suite
        const closingIndex = endIndexOfClosingTag(html, "<p>", "</p>")
        const splittedHtml = { firstPart: html.substring(0, closingIndex), secondPart: html.substring(closingIndex) }
        return `${splittedHtml.firstPart}${wrapTextWithDiv(splittedHtml.secondPart)}`;
    } else if (divFirstIndex === 0) {
        //VJU 22/11/2023 : si on trouve div, on trouve le tag fermant et on regarde la suite
        const closingIndex = endIndexOfClosingTag(html, "<div>", "</div>")
        const splittedHtml = { firstPart: html.substring(0, closingIndex), secondPart: html.substring(closingIndex) }
        return `${splittedHtml.firstPart}${wrapTextWithDiv(splittedHtml.secondPart)}`;
    } else {
        const min = Math.min(imgFirstIndex, videoFirstIndex, pFirstIndex, divFirstIndex)
        if (min === Infinity) {
            //VJU 22/11/2023 : si le min est Infinity, alors il y a ni div ni p ni img ni video, on peut mettre un div autour de tout et renvoyer
            return `<div>${html}</div>`
        } else {
            //VJU 22/11/2023 : si on trouve un tag après 0, alors on a besoin de rajouter un div jusqu'au tag (et on regarde la suite)
            const splittedHtml = { firstPart: html.substring(0, min), secondPart: html.substring(min) };
            return `<div>${splittedHtml.firstPart}</div>${wrapTextWithDiv(splittedHtml.secondPart)}`
        }
    }
}

// Used before the deserializer to replace elements in received value
export const HTMLDeserializer = (html: string) => {
    let renderedHtml = html;

    if (!renderedHtml.startsWith("<div>") && !renderedHtml.startsWith("<p>")) {
        renderedHtml = wrapTextWithDiv(renderedHtml)
    }

    renderedHtml = renderedHtml.replaceAll('<br>', '\n')
        .replaceAll('</br>', '\n')
        .replaceAll('<br/>', '\n');

    const document = new DOMParser().parseFromString(renderedHtml, "text/html");
    // Sending the replaced HTML String to deserializer
    return deserializer(document.body);
};

// Used to transform HTML into readable slate childrens
const deserializer: any = (el: Element, markAttributes: any) => {
    // Checking if current node is text or not
    if (el.nodeType === Node.TEXT_NODE) {
        return jsx("text", markAttributes, el.textContent);
    } else if (el.nodeType !== Node.ELEMENT_NODE) {
        return null;
    }

    const nodeAttributes = { ...markAttributes };

    // define attributes for text nodes
    if (el.nodeName === "strong") {
        nodeAttributes.bold = true;
    }
    if (el.nodeName === "italic") {
        nodeAttributes.italic = true;
    }
    if (el.nodeName === "underlined") {
        nodeAttributes.underlined = true;
    }

    // Sending children of current node into deserializer
    const children = Array.from(el.childNodes)
        .map((node) => deserializer(node, nodeAttributes))
        .flat();

    if (children.length === 0) {
        children.push(jsx("text", nodeAttributes, ""));
    }

    // Setting current node type according to slate types
    switch (el.nodeName) {
        case "BODY":
            return jsx("fragment", {}, children);
        case "BR":
            return "\n";
        case "DIV":
            return jsx("element", { type: ElementType.paragraph }, children);
        case "P":
            return jsx("element", { type: ElementType.paragraph }, children);
        case "EMPTY":
            return jsx("text", { text: "" });
        case "A":
            return jsx("element", { type: ElementType.link, href: el.getAttribute("href") }, children);
        case "IMG":
            return jsx(
                "element",
                {
                    type: ElementType.image,
                    alt: el.getAttribute("alt"),
                    src: el.getAttribute("src"),
                },
                children
            );
        case "VIDEO":
            return jsx(
                "element",
                {
                    type: ElementType.video,
                    src: el.getAttribute("src"),
                    thumbnailUrl: el.getAttribute("thumbnailUrl"),
                    videoId: el.getAttribute("videoId"),
                },
                children
            );
        case "STRONG":
            return jsx("text", { bold: true }, children);
        case "EM":
            return jsx("text", { italic: true }, children);
        case "U":
            return jsx("text", { underlined: true }, children);
        default:
            return children;
    }
};
