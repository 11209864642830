import { Debugger } from "../../Debugger";
import { BaseViewModel, BaseViewState } from "./BaseViewModel";

export interface TabViewState extends BaseViewState {
  locationHashes: Array<string>;
  defaultTabId: string;
  selectedTabId: string;
}

export class BaseTabViewModel<T extends TabViewState> extends BaseViewModel<T> {
  private updateHash?: (hash: string) => void;

  public updateTabIfNeeded(hash: string, updateHash: (hash: string) => void): void {
    this.updateHash = updateHash;
    const { defaultTabId, locationHashes } = this.state;

    if (locationHashes.indexOf(hash) < 0) {
      updateHash(defaultTabId);
    }
    this.onSelectedTabChanged(hash);
  }

  public onSelectedTabChanged(tabId: string): void {
    if (tabId !== this.state.selectedTabId) {
      this.setState({ ...this.state, selectedTabId: tabId });
      //* load or refresh data for corresponding viewModel
      this.refresh(tabId);
    }
  }

  public refresh(viewModelId: string): void {
    Debugger.LogValue("🔥🔥🔥🔥🔥 | refresh tab", viewModelId);
  }

  protected switchToTab = (tabId: string): void => {
    this.WrapError(async () => {
      if (this.updateHash) {
        this.updateHash(tabId);
      }
    });
  };

  protected disposeManagedObject(): void {
    super.disposeManagedObject();
    this.updateHash = undefined;
  }
}
