import MuiButton from "@material-ui/core/Button";
import classNames from "classnames";
import React from "react";
import { RTEImageLoader } from "../Image/ImageDropzone/RTEImageLoader/RTEImageLoader";
import "./CustomButton.scss";
import styled from "styled-components";

export interface CustomButtonProps {
  title?: React.ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  isBusy?: boolean;
  fullWidth?: boolean;
  danger?: boolean;
}

export const CustomButton = ({
  title = "",
  className,
  onClick,
  disabled = false,
  isBusy,
  fullWidth,
  danger = false,
}: CustomButtonProps) => {
  const getContent = (): React.ReactNode => (
    <div>
      {danger ? (
        <DangerButton
          className={classNames("Button-content", {
            "Button-busy": isBusy,
            "Button-fullWidth": fullWidth,
          })}>
          {isBusy ? <RTEImageLoader /> : title}
        </DangerButton>
      ) : (
        <StandardButton
          className={classNames("Button-content", {
            "Button-busy": isBusy,
            "Button-fullWidth": fullWidth,
          })}>
          {isBusy ? <RTEImageLoader /> : title}
        </StandardButton>
      )}
    </div>
  );

  return (
    <div
      className={classNames("Button-root", {
        "Button-disabled": disabled,
        [className as string]: className,
      })}>
      <MuiButton onClick={onClick} disabled={disabled}>
        {getContent()}
      </MuiButton>
    </div>
  );
};

const StandardButton = styled.div`
  .Button-busy {
    .loader-container .app-loader-inner > div {
      background-color: ${(props) => props.theme.buttonStandardTextColor};
    }
  }
  background-color: ${(props) => props.theme.buttonStandardBackgroundColor};
  color: ${(props) => props.theme.buttonStandardTextColor};
  border: 1px solid ${(props) => props.theme.buttonStandardBackgroundColor};
  min-width: ${(props) => props.theme.customButtonWidth};
  height: ${(props) => props.theme.customButtonHeight};
  letter-spacing: 0;
  text-transform: initial;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: bold;
  &:hover {
    background-color: ${(props) => props.theme.buttonStandardBackgroundHoverColor};
  }
`;

const DangerButton = styled.div`
  background-color: ${(props) => props.theme.buttonDangerBackgroundColor};
  color: ${(props) => props.theme.buttonDangerTextColor};
  border: 1px solid ${(props) => props.theme.buttonDangerBorderColor};
  min-width: ${(props) => props.theme.customButtonWidth};
  height: ${(props) => props.theme.customButtonHeight};
  letter-spacing: 0;
  text-transform: initial;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: bold;

  &:hover {
    background-color: ${(props) => props.theme.buttonDangerBackgroundHoverColor};
  }
`;