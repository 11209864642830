import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { AppActions } from "../../../../../Core/App/AppActions";
import { setupIntl } from "../../../../../Core/Services/Data/I18n/SetupIntl";
import { LoadingPage } from "../../Loading/LoadingPage/LoadingPage";

/** Executes an array of prommises on mount */
class MountActionsGate extends React.Component {
  state = {
    ready: !this.props.actions.length && !this.props.setupIntl && !this.props.withEssentialReload,
  };

  componentDidMount() {
    const { actions, setupIntl: shouldSetupIntl, withEssentialReload, triggerEssentialReload } = this.props;

    const autoActions = [
      shouldSetupIntl ? setupIntl : undefined,
      withEssentialReload ? triggerEssentialReload : undefined,
    ].filter(Boolean);

    const allActions = [...autoActions, ...actions];

    const safeActions = allActions
      .map((action) => Promise.resolve(action())) // transform all functions to promises
      .map((action) => action.catch((err) => err)); // catch all errors so that we can execute all actions, even if some previous ones failed

    Promise.all(safeActions).then(() => this.setState({ ready: true }));
  }

  render() {
    const { ready } = this.state;
    const { children } = this.props;

    if (!ready) return <LoadingPage />;

    return children;
  }
}

MountActionsGate.propTypes = {
  children: PropTypes.node,
  /** Array of functions or promises */
  actions: PropTypes.arrayOf(PropTypes.func),
  /** If should automatically setup intl & react-intl */
  setupIntl: PropTypes.bool,
  /** If should trigger 'BaseActions.triggerEssentialReload' */
  withEssentialReload: PropTypes.bool,

  triggerEssentialReload: PropTypes.func,
};

MountActionsGate.defaultProps = {
  children: null,
  actions: [],

  setupIntl: false,
  withEssentialReload: false,

  triggerEssentialReload: undefined,
};

const ConnectedMountActionsGate = connect(undefined, {
  triggerEssentialReload: AppActions.triggerEssentialReload,
})(MountActionsGate);

export { ConnectedMountActionsGate as MountActionsGate };
