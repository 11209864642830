import { useEffect, useRef } from "react";
import { NewsDetailsViewModel, NewsDetailsViewState } from "../../../../Core/ViewModels/News/NewsDetailsViewModel";
import { ViewModelLocator } from "../../../../Core/ViewModels/ViewModelLocator";
import { useInputTextFieldProps } from "../../../Hooks/Form/useInputProps";
import { useLifecycle } from "../../../Hooks/Lifecycle/useLifecycle";
import { CustomPrompt } from "../../../Hooks/Prompt/CustomPrompt";

export const useNewsDetailsPageController = () => {
  const { vm, state } = useLifecycle<NewsDetailsViewState, NewsDetailsViewModel>({
    getRef: () => ViewModelLocator.NewsDetailsVM,
    setRefValue: (value) => (ViewModelLocator.NewsDetailsVM = value),
    newInstance: () => new NewsDetailsViewModel(),
    logName: "NewsDetailsVM",
  });

  useEffect(() => {
    vm.onItemChange("empty");
  }, [vm]);

  const prompt = useRef(new CustomPrompt());
  useEffect(() => {
    prompt.current.updateBlock(state.modified);
  }, [state.modified]);

  const currentLanguageState = {
    ...state.localized_content[state.currentLanguage],
    fieldsErrors: state.fieldsErrors?.localized_content?.[state.currentLanguage] ?? {},
  };

  const registerLocalizedField = useInputTextFieldProps(currentLanguageState, vm.onChangeLocalizedField, {
    hideLabel: true,
    i18nPrefix: "NewsDetailsPage",
  });

  const registerField = useInputTextFieldProps(state, vm.onChangeField, {
    hideLabel: true,
    i18nPrefix: "NewsDetailsPage",
  });

  return { vm, state, currentLanguageState, registerLocalizedField, registerField };
};
