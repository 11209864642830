import { XHRRequestCanceler } from "../../Libs/xhr/XHRRequestCanceler";
import { ApiUrls } from "../Models/ApiUrls";
import {
  CreateOrUpdateExhibitionRequest,
  ExhibitionCreateResponse,
  ExhibitionDataResponse,
  ExhibitionListResponse,
} from "../Models/Exhibition/Exhibition";
import { BaseDataService } from "./Base/BaseDataService";
import { GetSortedQuery } from "../Models/Commons/SortedQuery";
import { BaseApiDataResponse } from "../Models/ApiData";

export const ExhibitionDataServiceName: string = "ExhibitionDataService";

export interface IExhibitionDataService {
  getExhibitions: (params: GetSortedQuery, cts: XHRRequestCanceler) => Promise<ExhibitionListResponse>;

  getExhibition: (id: string, cts: XHRRequestCanceler) => Promise<ExhibitionDataResponse>;

  createExhibition: (
    data: CreateOrUpdateExhibitionRequest,
    cts: XHRRequestCanceler
  ) => Promise<ExhibitionCreateResponse>;

  updateExhibition: (
    id: string,
    data: CreateOrUpdateExhibitionRequest,
    cts: XHRRequestCanceler
  ) => Promise<ExhibitionCreateResponse>;

  publish: (id: string, cts: XHRRequestCanceler) => Promise<BaseApiDataResponse>;

  cancelPublish: (id: string, cts: XHRRequestCanceler) => Promise<BaseApiDataResponse>;
}

export class ExhibitionDataService extends BaseDataService implements IExhibitionDataService {
  public getExhibitions(params: GetSortedQuery, cts: XHRRequestCanceler): Promise<ExhibitionListResponse> {
    return this.get<ExhibitionListResponse>(ApiUrls.EXHIBITION_LIST, cts, { params });
  }

  public getExhibition(id: string, cts: XHRRequestCanceler): Promise<ExhibitionDataResponse> {
    return this.get<ExhibitionDataResponse>(ApiUrls.EXHIBITION(id), cts);
  }

  public createExhibition(
    data: CreateOrUpdateExhibitionRequest,
    cts: XHRRequestCanceler
  ): Promise<ExhibitionCreateResponse> {
    return this.post<ExhibitionCreateResponse>(ApiUrls.EXHIBITION_CREATE, data, cts);
  }

  public updateExhibition(
    id: string,
    data: CreateOrUpdateExhibitionRequest,
    cts: XHRRequestCanceler
  ): Promise<ExhibitionCreateResponse> {
    return this.put<ExhibitionCreateResponse>(ApiUrls.EXHIBITION(id), data, cts);
  }

  public publish(id: string, cts: XHRRequestCanceler): Promise<BaseApiDataResponse> {
    return this.patch<BaseApiDataResponse>(ApiUrls.EXHIBITION_PUBLISH(id), cts);
  }

  public cancelPublish(id: string, cts: XHRRequestCanceler): Promise<BaseApiDataResponse> {
    return this.patch<BaseApiDataResponse>(ApiUrls.EXHIBITION_UNPUBLISH(id), cts);
  }
}
