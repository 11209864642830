export const ApiUrls = {
  ME: "/api/v1/admins/me",

  //* ART WORKS
  ARTWORK_LIST: "api/v1/admins/artworks",
  ARTWORK_CREATE: "api/v1/admins/artworks",
  ARTWORK: (id: string) => `api/v1/admins/artworks/${id}`,
  ARTWORK_SECTIONS: "api/v1/admins/sections",

  //* MEDIA
  MEDIA_UPLOAD_IMAGE: "api/v1/media/pictures",
  MEDIA_GET_IMAGE: (pictureId: string) => `api/v1/media/pictures/${pictureId}`,
  GET_VIDEO_LIST: "api/v1/media/videos",
  GET_VIDEO_THUMBNAIL: (videoId: string) => `api/v1/media/videos/thumbnail/${videoId}`,
  GET_VIDEO_DETAILS: (videoLibraryId: string) => `api/v1/media/videos/title/${videoLibraryId}`,
  MEDIA_UPLOAD_VIDEO: "api/v1/media/videos",
  SET_VIDEO_TITLE: "/api/v1/media/videos/title",
  UPDATE_VIDEO_TITLE: (videoLibraryId: string) => `/api/v1/media/videos/title/${videoLibraryId}`,
  UPDATE_VIDEO_THUMBNAIL: (videoId: string) => `/api/v1/media/videos/${videoId}/preview`,
  //* EXHIBIITIONS
  EXHIBITION_LIST: "api/v1/admins/sections",
  EXHIBITION_CREATE: "api/v1/admins/sections",
  EXHIBITION: (id: string) => `api/v1/admins/sections/${id}`,
  EXHIBITION_PUBLISH: (id: string) => `api/v1/admins/sections/${id}/publish`,
  EXHIBITION_UNPUBLISH: (id: string) => `api/v1/admins/sections/${id}/unpublish`,

  NEWS: "api/v1/admins/temporary-news",
  NEWS_CREATE: "api/v1/admins/temporary-news",

  BO_USER_LIST: "api/v1/admins/users",
  BO_USER: (id: string) => `api/v1/admins/users/${id}`,
  BO_USER_UPDATE: (id: string) => `api/v1/admins/users/${id}`,
  BO_USER_CREATE: `api/v1/admins/users`,
};
