import { VideoListViewModel, VideoListViewState } from "../../../Core/ViewModels/Videos/VideoListViewModel";
import { ViewModelLocator } from "../../../Core/ViewModels/ViewModelLocator";
import { useLifecycle } from "../../Hooks/Lifecycle/useLifecycle";

export const useVideoListPageController = () => {
  const { vm, state } = useLifecycle<VideoListViewState, VideoListViewModel>({
    getRef: () => ViewModelLocator.VideoListVM,
    setRefValue: (value) => (ViewModelLocator.VideoListVM = value),
    newInstance: () => new VideoListViewModel(),
    logName: "VideoListVM",
  });

  return { vm, state };
};
