import { ApiDataResponse, BasePaginatedListResponse } from "../ApiData";

export type BoUsersDto = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  enabled: boolean;
  role: BoRoles;
};

export type CreateOrUpdateBoUser = {
  email: string;
  first_name: string;
  last_name: string;
  enabled: boolean;
  role: BoRoles;
};

export enum BoRoles {
  Administrator = "Administrator",
  ContentCreator = "ContentCreator",
}

export type BoUserResponse = ApiDataResponse<BoUsersDto>;
export type BoUsersListResponse = BasePaginatedListResponse<BoUsersDto>;
