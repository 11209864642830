import React from 'react';

import { MarkButton, MarkButtonProps } from './MarkButton/MarkButton';
import IconFormatBold from '@material-ui/icons/FormatBold';
import IconFormatItalic from '@material-ui/icons/FormatItalic';
import IconFormatUnderlined from '@material-ui/icons/FormatUnderlined';

export const MarkButtons = () => {
    return (
        <React.Fragment>
            <BoldButton type='bold' />
            <ItalicButton type='italic' />
            <UnderlineButton type='underlined' />
        </React.Fragment>
    );
};

export const BoldButton = ({ type }: MarkButtonProps) => (
    <MarkButton type={type} children={<IconFormatBold />} />
);

export const ItalicButton = ({ type }: MarkButtonProps) => (
    <MarkButton type={type} children={<IconFormatItalic />} />
);

export const UnderlineButton = ({ type }: MarkButtonProps) => (
    <MarkButton type={type} children={<IconFormatUnderlined />} />
);
