import * as internalLogger from 'loglevel';
import { Constants } from './Constants';

export class Debugger {
    public static Log(data: any) {
        if (Constants.isDev) {
            internalLogger.log(data);
        }
    }

    public static LogValue(message: string, value: any) {
        if (Constants.isDev) {
            internalLogger.log(message, value);
        }
    }
}
