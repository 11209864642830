import { AppRoutes } from "../../../Core/App/AppRoutes";
import { AppRouteType } from "../../Components/Templates/AppRouter/AppRouteType";
import { PrivateRoute } from "../../Components/Templates/AppRouter/PrivateRoute";
import { ExhibitionsListPage } from "../../Pages/Exhibitions/ExhibitionListPage";
import { ExhibitionDetailsPage } from "../../Pages/Exhibitions/ExhibitionDetailsPage/ExhibitionDetailsPage";

const exhibitionsListRoute: AppRouteType = {
  id: AppRoutes.EXHIBITIONS_LIST,
  render: PrivateRoute,
  props: {
    exact: true,
    path: AppRoutes.EXHIBITIONS_LIST,
    component: ExhibitionsListPage,
  },
};

const exhibitionsCreateRoute: AppRouteType = {
  id: AppRoutes.EXHIBITIONS_CREATE,
  render: PrivateRoute,
  props: {
    exact: true,
    path: AppRoutes.EXHIBITIONS_CREATE,
    component: ExhibitionDetailsPage,
  },
};

const exhibitionsDetailsRoute: AppRouteType = {
  id: AppRoutes.EXHIBITIONS_DETAILS,
  render: PrivateRoute,
  props: {
    exact: true,
    path: AppRoutes.EXHIBITIONS_DETAILS,
    component: ExhibitionDetailsPage,
  },
};

export const getExhibitionsRoutes = () => [exhibitionsListRoute, exhibitionsCreateRoute, exhibitionsDetailsRoute];
