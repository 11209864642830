import React from "react";
import Logo from "../../../assets/images/logo.png";

import "./AuthPageLayout.scss";

export interface AuthPageLayoutProps {
  children?: React.ReactNode;
}

const AuthPageLayout = ({ children }: AuthPageLayoutProps) => {
  return (
    <div className="AuthPageLayout-root">
      <div className="AuthPageLayout-navBar">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="AuthPageLayout-content">{children}</div>
    </div>
  );
};

export { AuthPageLayout };
