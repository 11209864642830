import { history } from "../../Configs/history/history";
import { AppRoutes } from "../../Core/App/AppRoutes";
import { INavigationService } from "../../Core/Services/Interfaces/INavigationService";

//* Generic detail route
export const getDetailsRoute = (id: string, detailsRoute: string): string => `${detailsRoute}`.replace(":id", id);

export const getProgramDetailsRoute = (programId: string, id: string, detailsRoute: string): string =>
  `${detailsRoute}`.replace(":programid", programId).replace(":id", id);

export const getRefundDetailsRoute = (refundId: string, id: string, detailsRoute: string): string =>
  `${detailsRoute}`.replace(":refundId", refundId).replace(":id", id);

export const getProgramLotDetailsRoute = (programId: string, lotid: string, id: string, detailsRoute: string): string =>
  `${detailsRoute}`.replace(":programid", programId).replace(":lotid", lotid).replace(":id", id);

export class NavigationService implements INavigationService {
  public getHomeLink = AppRoutes.ART_WORK_LIST;

  public goTo = (route: string, replace?: boolean): void => {
    if (replace) {
      history.replace(route);
    } else {
      history.push(route);
    }
  };

  public goToHome = (): void => history.push(this.getHomeLink);
  public goBack = (): void => history.goBack();

  public goToLogin = (): void => history.push(AppRoutes.LOGIN);
  public goToGetApp = (): void => history.push(AppRoutes.GET_APP);
  public goToForgotPassword = (): void => history.push(AppRoutes.FORGOT_PASSWORD);

  public goToArtWorkCreate = (): void => history.push(AppRoutes.ART_WORK_CREATE);
  public goToArtWorkDetails = (id: string): void => history.push(getDetailsRoute(id, AppRoutes.ART_WORK_DETAILS));

  public goToVideoCreate = (): void => history.push(AppRoutes.VIDEO_CREATE);
  public goToVideoDetails = (id: string): void => history.push(getDetailsRoute(id, AppRoutes.VIDEO_DETAILS));
  public goToVideoList = (): void => history.push(AppRoutes.VIDEO_LIST);

  public goToExhibitionsCreate = () => history.push(AppRoutes.EXHIBITIONS_CREATE);
  public goToExhibitionsDetails = (id: string) => history.push(getDetailsRoute(id, AppRoutes.EXHIBITIONS_DETAILS));
  public goToExhibitionsList = () => history.push(AppRoutes.EXHIBITIONS_LIST);
  public goToBoUserDetails = (id: string) => history.push(getDetailsRoute(id, AppRoutes.BO_USER_DETAILS));
  public goToBoUserCreate = () => history.push(AppRoutes.BO_USER_CREATE);
}
