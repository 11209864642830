import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";
import { AppRoutes } from "../../../../Core/App/AppRoutes";
import { CustomButton, CustomButtonClassType } from "../../../Components/Views/CustomButton/CustomButton";
import { CustomCard } from "../../../Components/Views/CustomCard/CustomCard";
import { Icons } from "../../../Components/Views/Icons/Icons";
import { CustomTabs } from "../../../Components/Views/Tabs/Tabs";
import { AuthenticatedPageLayout } from "../../AuthenticatedPageLayout/AuthenticatedPageLayout";
import "./ExhibitionDetailsPage.scss";
import { useExhibitionDetailsPageController } from "./ExhibitionDetailsPageController";
import { SelectLanguage } from "../../../Components/Views/SelectLanguage/SelectLanguages";
import { CustomInputText, InputType } from "../../../Components/Views/CustomInputText/CustomInputText";
import { MediaInput } from "../../../Components/Views/MediaInput/MediaInput";
import { Languages } from "../../../../Core/Models/Languages/Languages";
import { Messages } from "../../../../Core/Services/Data/I18n/typings/LocalMessageActionTypes";
import { NewsForm } from "../../News/ExhibitionDetailsPage/NewsDetailsPage";

export interface ExhibitionDetailsPageProps {}

const ExhibitionDetailsPage = () => {
  const {
    vm,
    state,
    currentLanguageState,
    registerLocalizedField,
    registerField,
  } = useExhibitionDetailsPageController();

  const nameProps = registerLocalizedField("name");
  const descriptionProps = registerLocalizedField("description");
  const orderProps = registerField("order");
  const title =
    state.mode === "create" ? <FormattedMessage id="ExhibitionsListPage_addExhibitions" /> : currentLanguageState.name;
  return (
    <AuthenticatedPageLayout
      previousTitle={<FormattedMessage id="ExhibitionsListPage_Title" />}
      previousTitleLink={AppRoutes.EXHIBITIONS_LIST}
      title={title}>
      <UniqueTab
        label={<FormattedMessage id="ExhibitionDetailsPage_Details_Label" />}
        changeCurrentLanguage={vm.changeCurrentLanguage}
        currentLanguage={state.currentLanguage}
        localizedErrors={state.fieldsErrors?.localized_content}>
        <Card titleIntlId="ExhibitionDetailsPage_Details_Label">
          <div className="col">
            <div className="ExhibitionDetailsPage-header">{title}</div>
            <div className="ExhibitionDetailsPage-body">
              <CustomInputText {...nameProps} labelIntlId="ExhibitionDetailsPage_ExhibitionsName" />
              <CustomInputText {...descriptionProps} labelIntlId="ExhibitionDetailsPage_ExhibitionsDescription" />
              <CustomInputText
                {...orderProps}
                type={InputType.Number}
                labelIntlId="ExhibitionDetailsPage_ExhibitionsOrder"
              />
            </div>
          </div>
          <div className="col">
            <MediaInput
              value={state.localized_content[state.currentLanguage].localPicture}
              onChange={(file) => vm.onChangeLocalizedField("localPicture", file)}
              labelIntlId="ArtWorkInformationTab_InputPictureLabel"
              maxCount={1}
            />
          </div>
        </Card>
        <Card titleIntlId="ExhibitionDetailsPage_NewsCard_Label">
          <NewsForm
            nameProps={registerLocalizedField("news_title")}
            descriptionProps={registerLocalizedField("news_content")}
            localPicture={state.localized_content[state.currentLanguage].news_localPicture}
            onChangePicture={(file) => vm.onChangeLocalizedField("news_localPicture", file)}
          />
        </Card>
        <Card
          titleIntlId="ExhibitionsListPage_Details_Actions_Label"
          renderHeader={
            <div className="ExhibitionDetailsPage-publicationTab-header-root">
              <div className="ExhibitionDetailsPage-publicationTab-header-title">
                <FormattedMessage id="ExhibitionDetailsPage_Publication" />
              </div>
              <div
                className={classNames({
                  "ExhibitionDetailsPage-publicationTab-header-statusColor": true,
                  [vm.getExhibitionStatus()]: true,
                })}
              />
              <div className="ExhibitionDetailsPage-publicationTab-header-status">{vm.getExhibitionStatus()}</div>
            </div>
          }>
          <div className="ExhibitionDetailsPage-actions">
            <div className="ExhibitionDetailsPage-publish-actions">
              {state.mode === "edit" ? (
                <>
                  <CustomButton
                    title={<FormattedMessage id="COMMON_PUBLISH" />}
                    onClick={() => vm.onPublish()}
                    disabled={state.is_published}
                  />

                  <CustomButton
                    title={<FormattedMessage id="COMMON_UNPUBLISH" />}
                    onClick={() => vm.onCancelPublish()}
                    disabled={!state.is_published}
                    variant={CustomButtonClassType.Outlined}
                  />
                </>
              ) : null}
            </div>

            <CustomButton
              variant={CustomButtonClassType.Success}
              title={<FormattedMessage id="ArtWorkInformationTab_SaveTitle" />}
              className="ArtWorkDescriptionTab-saveButton"
              onClick={vm.onSubmit}
              icon={Icons.IconSave}
            />
          </div>
        </Card>
      </UniqueTab>
    </AuthenticatedPageLayout>
  );
};

const Card = (props: React.ComponentProps<typeof CustomCard>) => (
  <CustomCard
    {...props}
    classes={{
      root: "ExhibitionDetailsPage-card",
      body: "ExhibitionDetailsPage-card-body",
    }}
  />
);

export const UniqueTab = ({
  children,
  label,
  changeCurrentLanguage,
  currentLanguage,
  localizedErrors,
}: React.PropsWithChildren<{
  label: React.ReactNode;
  changeCurrentLanguage: (l: Languages) => void;
  currentLanguage: Languages;
  localizedErrors?: Messages;
}>) => {
  const tabsValue = "#details";
  const noop = () => {};

  return (
    <CustomTabs
      value={tabsValue}
      onChange={noop}
      tabs={[
        {
          label: label,
          value: tabsValue,
          children: children,
        },
      ]}
      endActions={
        <SelectLanguage
          changeCurrentLanguage={changeCurrentLanguage}
          currentLanguage={currentLanguage}
          errors={localizedErrors}
        />
      }
    />
  );
};

export { ExhibitionDetailsPage };
