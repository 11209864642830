import { TableCell, TableRow } from "@material-ui/core";
import classNames from "classnames";
import { useCallback } from "react";
import { Icons } from "../../../Icons/Icons";
import "./ItemListTableRow.scss";

export interface ItemListTableRowProps<TItem> {
  item: TItem;
  getCellsData: (item: TItem) => React.ReactNode[];
  onRowClick?: (item: TItem) => void;
}

/**
 * Generate Mui TableRow and TableCss for a given item.
 */
export function ItemListTableRow<TItem>({
  item,
  getCellsData,
  onRowClick: onRowClickProp,
}: ItemListTableRowProps<TItem>) {
  const cells = getCellsData(item);

  const rowClasses = classNames({
    "item-list-table-row": true,
    clickable: onRowClickProp ?? false,
  });

  const onClick = useCallback(() => {
    onRowClickProp && onRowClickProp(item);
  }, [onRowClickProp, item]);

  return (
    <TableRow classes={{ root: rowClasses }}>
      {cells.map((v, i) => (
        <TableCell onClick={onClick} key={i}>
          {v}
        </TableCell>
      ))}
      {onRowClickProp && (
        <TableCell onClick={onClick} className="actions">
          {Icons.IconEndRow}
        </TableCell>
      )}
    </TableRow>
  );
}
