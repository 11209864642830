import "./NavBarLink.scss";

import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";

export interface NavBarLinkProps {
  title: React.ReactNode;
  to: string;
}

const NavBarLink = ({ title, to }: NavBarLinkProps) => {
  return (
    <NavLink to={to} className={classNames({ "NavBarLink-root": true })}>
      <div className="NavBarLink-select" />
      <div className="NavBarLink-title">{title}</div>
    </NavLink>
  );
};

export { NavBarLink };
