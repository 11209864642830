import { Languages } from "../../Models/Languages/Languages";
import { Messages } from "../../Services/Data/I18n/typings/LocalMessageActionTypes";
import { MessageUtils } from "../../Utils/MessageUtils";

/** check that each non-empty localize content is valid. This doesn't return errors for empty localized content EXCEPT when all content are empty */
export function validateLocalizedContent<T>(
  localized_content: Record<Languages, T>,
  validate: (content: T) => { isEmpty: boolean; errors: Messages | undefined }
): Messages | undefined {
  const validationResults = (Object.keys(localized_content) as Languages[]).map((lang) => ({
    lang,
    ...validate(localized_content[lang]),
  }));

  const allContentEmpty = validationResults.reduce((p, v) => p + (v.isEmpty ? 0 : 1), 0) === 0; // on compte le nb de contenu langue non-vide

  const errors = Object.assign(
      {},
      ...validationResults.map((r) => {
        // If r.errors = {} we change it to undefined
        const resultMessage = r.errors ? (Object.keys(r.errors).length === 0 ? undefined : r.errors) : r.errors;
        return { [r.lang]: (r.isEmpty && allContentEmpty) || !r.isEmpty ? resultMessage : undefined };
      })
  );

  return MessageUtils.isEmpty(errors) ? undefined : errors;
}
