import { CustomText, ElementType, LinkElement } from '../CustomTypes.d';

export const createParagraphNode = (children: LinkElement[] | CustomText[] = [{ text: '' }]) => ({
    type: ElementType.paragraph,
    children,
});

export const createLinkNode = (href: string, text: string) => ({
    type: ElementType.link,
    href,
    children: [{ text }],
});

export const createImageNode = (src: string, alt: string) => ({
    type: ElementType.image,
    alt,
    src,
    children: [{ text: '' }],
});

export const createVideoNode = (src: string, videoId: any, thumbnailUrl: string) => ({
    type: ElementType.video,
    thumbnailUrl,
    src,
    videoId,
    children: [{ text: '' }],
});
