import { Container } from 'inversify';
import { Lazy } from '../Helpers/Lazy';

const ServiceContainer = new Container();

export class IoC {
    public static bind<TService>(name: string, instance: TService): void {
        ServiceContainer.bind<TService>(name).toConstantValue(instance);
    }

    public static get<TService>(name: string): TService {
        try {
            return ServiceContainer.get<TService>(name);
        } catch (error) {
            console.log("IoC try get name", name, error);
            return ServiceContainer.get<TService>(name);
        }
    }

    public static getLazy<TService>(name: string): Lazy<TService> {
        return new Lazy<TService>(() => IoC.get<TService>(name));
    }
}