export const fr = {
  //* COMMONS
  COMMON_YES: "Oui",
  COMMON_NO: "Non",
  COMMON_REQUIRED_FIELD: "Champ obligatoire*",
  COMMON_EMPTY_MESSAGE: "Aucun résultat",
  COMMON_ADD: "Ajouter",
  COMMON_CHANGE: "Modifier",
  COMMON_BAD_VALUE_FIELD: "Champ erroné",
  COMMON_EXPORT: "Exporter",
  COMMON_IMPORT: "Parcourir",
  COMMON_SAVE: "Enregistrer",
  COMMON_CANCEL: "Annuler",
  COMMON_VALIDATE: "Valider",
  COMMON_PUBLISH: "Publier",
  COMMON_UNPUBLISH: "Annuler la publication",
  COMMON_DELETE: "Supprimer",
  COMMON_SEARCH: "Rechercher",
  COMMON_SEARCH_PLACEHOLDER: "Rechercher",
  COMMON_SEARCH_TITLE: "Rechercher",
  COMMON_PLACEHOLDER: "_",
  COMMON_ENABLED: "Actif",
  COMMON_DISABLED: "Inactif",
  COMMON_SAVE_SUCCESS: "Enregistrement réussi !",
  COMMON_DAY_DATE_FORMAT: `{year}-{month}-{day}`,
  COMMON_INVALID_EMAIL: "Format de l'adresse email invalide",
  COMMON_DATE_FORMAT: "YYYY-MM-dd",
  COMMON_TODAY: "Aujourd'hui",
  COMMON_YESTERDAY: "Hier",
  COMMON_FULLNAME: "{firstName} {lastName}",
  COMMON_INVITE: "Envoyer un email d'invitation",
  COMMON_ALL: "Tout",
  COMMON_CONFIRM: "Confirmer",
  COMMON_NOT_SAVED: "Des données modifiées seront perdues si vous quittez sans enregistrer, voulez vous continuer ?",

  //* Toasts
  Error_GenericError: "Quelque chose ne s'est pas passé comme prévu, merci de réessayer.",
  Toast_TitleError: "Erreur",
  Toast_TitleInfo: "Information",
  Toast_TitleSuccess: "Succès",
  Toast_GenericSuccessUpdateData: "Données mises à jour avec succès",
  Toast_LoginFailed: "La connexion a échoué. Vérifiez vos identifiants et veuillez réessayer.",

  //* Login page
  LoginPage_WelcomeText: "Bienvenue",
  LoginPage_HelpText: "Identifiez vous",
  LoginPage_LoginPlaceholder: "Email",
  LoginPage_PasswordPlaceholder: "Mot de passe",
  LoginPage_ForgotPassword: "Mot de passe oublié ?",
  LoginPage_Login: "Se connecter",
  LoginPage_EmailNotRecognized: "Email non reconnu",
  LoginPage_PasswordNotRecognized: "Mot de passe non reconnu",

  //* Forgot password page
  ForgotPasswordPage_EmailPlaceHolder: "Email",
  ForgotPasswordPage_ButtonTitle: "Envoyer",
  ForgotPasswordPage_HelpText1: "Réinitialiser votre mot de passe",
  ForgotPasswordPage_HelpText2: "Merci de renseigner votre adresse mail afin de réinitialiser votre mot de passe.",
  ForgotPasswordPage_Success:
    "Votre demande a bien été prise en compte. Si vous faites partie de la base de données, un email de réinitialisation vous sera envoyé dans les plus brefs délais.",
  ForgotPasswordPage_ToLoginLink: "Retour à la connexion",

  //* Reset password page
  ResetPasswordPage_InfoText: "Merci de saisir votre nouveau mot de passe.",
  ResetPasswordPage_InputPlaceholder_password: "Mot de passe",
  ResetPasswordPage_InputPlaceholder_passwordConfirm: "Confirmation mot de passe",
  ResetPasswordPage_ToLoginLink: "Retour à la connexion",
  ResetPasswordPage_PasswordPolicyFieldError: "Mot de passe trop faible",
  ResetPasswordPage_PasswordPolicyError:
    "Votre mot de passe est trop faible, veillez à ce qu'il soit composé d'au moins 8 caractères, une majuscule, une minuscule et un chiffre.",
  InitializePasswordPage_SuccessText: "Mot de passe réinitialisé avec succès.",
  ResetPasswordPage_PasswordMustMatch: "Le mot de passe doit être identique dans les deux champs",

  //* NavBar
  NavBar_ArtWorkTitle: "Oeuvres",
  NavBar_VideoTitle: "Vidéos",
  NavBar_NewsTitle: "Actualités",
  NavBar_ExhibitionTitle: "Expositions",
  NavBar_BOUserTitle: "Utilisateurs",

  //* Header
  Header_Logout: "Se déconnecter",

  //* ArtWork list page
  ArtWorkListPage_Title: "Oeuvres",
  ArtWorkListPage_addArtWork: "Ajouter une oeuvre",
  ArtWorkListPage_ArtWorkTitle: "Titre de l'oeuvre",
  ArtWorkListPage_ArtWorkArtist: "Artiste",
  ArtWorkListPage_ArtWorkStatus: "Statut",
  ArtWorkListPage_ArtWorkSection: "Section",
  ArtWorkListPage_StatusPublished: "Publiée",
  ArtWorkListPage_StatusCancelled: "Brouillon",
  ArtWorkListPage_StatusBrouillon: "Brouillon",

  //* ArtWork details page
  ArtWorkDetailsPage_Title: "Ajouter une oeuvre",

  ArtWorkDetailsPage_InfoTabTitle: "Informations",
  ArtWorkDetailsPage_DescriptionTabTitle: "Descriptif détaillé",
  ArtWorkDetailsPage_PublishSuccess: "Oeuvre publiée avec succès",
  ArtWorkDetailsPage_PublishError: "Erreur lors de la publication de l'oeuvre",
  ArtWorkDetailsPage_UnpublishSuccess: "Publication annulée avec succès",

  //* ArtWork information tab
  ArtWorkInformationTab_Cartel: "Cartel",
  ArtWorkInformationTab_Location: "Localisation",
  ArtWorkInformationTab_Publication: "Publication",
  ArtWorkInformationTab_InputArtistLabel: "Artiste*",
  ArtWorkInformationTab_InputCountryLabel: "Pays",
  ArtWorkInformationTab_InputTitleLabel: "Titre*",
  ArtWorkInformationTab_InputYearLabel: "Année",
  ArtWorkInformationTab_InputSubTitleLabel: "Sous-titre",
  ArtWorkInformationTab_InputSectionLabel: "Section*",
  ArtWorkInformationTab_InputPictureLabel: "Photo de présentation",
  ArtWorkInformationTab_InputLatLabel: "Latitude",
  ArtWorkInformationTab_InputLongLabel: "Longitude",
  ArtWorkInformationTab_InputURLLabel: "URL de l'oeuvre dans l'app",
  ArtWorkInformationTab_InputPlaceLabel: "Placement",
  ArtWorkInformationTab_InputShortDescriptionLabel: "Description courte",
  ArtWorkInformationTab_InputCopyRightsLabel: "Copyrights",
  ArtWorkInformationTab_PublishTitle: "Publier",
  ArtWorkInformationTab_CancelPublishTitle: "Annuler la publication",
  ArtWorkInformationTab_SaveTitle: "Enregistrer",
  ArtWorkInformationTab_Indoor: "Intérieur",
  ArtWorkInformationTab_OutDoor: "Extérieur",

  ArtWorkInformationTab_InputPlaceholder_artist_name: "Artiste",
  ArtWorkInformationTab_InputPlaceholder_country: "Pays",
  ArtWorkInformationTab_InputPlaceholder_title: "Titre",
  ArtWorkInformationTab_InputPlaceholder_year: "Année",
  ArtWorkInformationTab_InputPlaceholder_subtitle: "Sous titre",
  ArtWorkInformationTab_InputPlaceholder_latitude: "Latitude",
  ArtWorkInformationTab_InputPlaceholder_longitude: "Longitude",
  ArtWorkInformationTab_InputPlaceholder_app_url: "URL",
  ArtWorkInformationTab_InputPlaceholder_short_description: "Description courte",
  ArtWorkInformationTab_InputPlaceholder_copyrights: "copyrights",
  ArtWorkInformationTab_StatusPublished: "Publiée - le {date}",
  ArtWorkInformationTab_StatusCancelled: "Brouillon - dernière publication le {date}",
  ArtWorkInformationTab_StatusBrouillon: "Brouillon - jamais publiée",

  //* Artwork Description Tab
  ArtWorkDescriptionTab_Description: "Descriptif de l'œuvre",

  //* Rich Text
  "editor.placeholder": "Description...",
  "editor.button.cancel.label": "Annuler",
  "editor.button.add.label": "Ajouter",

  "editor.link.input.target.label": "Cible du lien",
  "editor.link.input.target.placeholder": "Saisissez la cible du lien",
  "editor.link.input.title.label": "Titre du lien",
  "editor.link.input.title.placeholder": "Saisissez le titre du lien",

  "editor.image.dropzone.title": "Ajouter une photo",
  "editor.image.dropzone.label": "Visuel photo",
  "editor.image.dropzone.cancel.button.label": "Annuler",
  "editor.image.dropzone.loading.message": "Chargement de l'image",

  "ValidatorsUtils.isRequired": "Ce champ est requis",
  "ValidatorsUtils.isNotUrl": "Veuillez entrer un lien valide",

  ExhibitionsListPage_Title: "Expositions",
  ExhibitionsListPage_IndexHeader: "Ordre",
  ExhibitionsListPage_NameHeader: "Titre",
  ExhibitionsListPage_StatusHeader: "Statut",
  ExhibitionsListPage_addExhibitions: "Ajouter une exposition",
  ExhibitionsListPage_Status_NotPublished: "Non active",
  ExhibitionsListPage_Status_Published: "Publié",
  ExhibitionsListPage_Status_Others: "Autre exposition",

  ExhibitionDetailsPage_Details_Label: "DÉTAILS DE L'EXPO",
  ExhibitionDetailsPage_Publication: "PUBLICATION",

  ExhibitionDetailsPage_StatusPublished: "Publiée - le {date}",
  ExhibitionDetailsPage_StatusCancelled: "Non active - dernière publication le {date}",
  ExhibitionDetailsPage_StatusBrouillon: "Non active - jamais publiée",

  ExhibitionDetailsPage_ExhibitionsName: "Titre",
  ExhibitionDetailsPage_InputPlaceholder_name: "Titre de l'expo",
  ExhibitionDetailsPage_ExhibitionsDescription: "Un texte chapeau",
  ExhibitionDetailsPage_InputPlaceholder_description: "Une description courte",
  ExhibitionDetailsPage_InputSectionLabel: "Statut",
  ExhibitionDetailsPage_ExhibitionsOrder: "Ordre d'affichage",
  ExhibitionDetailsPage_InputPlaceholder_order: "Ordre d'affichage",

  ExhibitionDetailsPage_StatusMain: "Principale",
  ExhibitionDetailsPage_StatusOthers: "Autres expositions",

  ExhibitionDetailsPage_PublishSuccess: "Exposition publiée avec succès",
  ExhibitionDetailsPage_PublishError: "Erreur lors de la publication de l'exposition",
  ExhibitionDetailsPage_UnpublishSuccess: "Publication annulée avec succès",

  //* Video List Page
  VideoList_VideoTitle: "Vidéos",
  VideoList_AddVideo: "Ajouter une vidéo",

  //* Video Details Page
  VideoDetails_AddVideo: "Ajouter une vidéo",
  VideoDetails_EditVideo: "Modifier le titre d'une vidéo",
  VideoDetails_TitleLabel: "Titre de la vidéo*",
  VideoDetailsPage_InputPlaceholder_title: "Titre",
  VideoDetails_VideoLabel: "Vidéo*",
  VideoDetailsPage_SaveTitle: "Enregistrer",
  VideoDetails_ThumbnailOffsetLabel: "Durée à laquelle récupérer l'image (en secondes)",
  VideoDetailsPage_InputPlaceholder_thumbnailOffset: "Durée à laquelle récupérer l'image",

  //* Exhibition
  NewsListPage_addNews: "Actualités",
  NewsDetailsPage_Details_Label_Tab: "DÉTAILS DE L'ACTUALITE",
  NewsDetailsPage_Details_Label: "ACTUALITE",
  NewsDetailsPage_Publication: "PUBLICATION",

  NewsDetailsPage_StatusPublished: "Publiée - le {date}",
  NewsDetailsPage_StatusCancelled: "Non active - dernière publication le {date}",
  NewsDetailsPage_StatusBrouillon: "Non active - jamais publiée",

  NewsDetailsPage_NewsName: "Titre",
  NewsDetailsPage_InputPlaceholder_title: "Titre de l'actu",
  NewsDetailsPage_NewssDescription: "Un texte chapeau",
  NewsDetailsPage_NewsDescription: "Message",
  NewsDetailsPage_InputPlaceholder_content: "...",
  NewsDetailsPage_InputSectionLabel: "Statut",
  NewsDetailsPage_NewssOrder: "Ordre d'affichage",
  NewsDetailsPage_InputPlaceholder_order: "Ordre d'affichage",

  NewsDetailsPage_StatusMain: "Principale",
  NewsDetailsPage_StatusOthers: "Autres expositions",

  NewsDetailsPage_PublishSuccess: "Exposition publiée avec succès",
  NewsDetailsPage_UnpublishSuccess: "Publication annulée avec succès",

  UserListPage_Title: "Utilisateurs",
  UserListPage_Header_Name: "Utilisateurs",
  UserListPage_Create: "Créer un utilisateur",
  UserListPage_Header_Role: "Rôles",
  BoUser_Roles_undefined: "?",
  BoUser_Roles_ContentCreator: "Collaborateurs",
  BoUser_Roles_Administrator: "Administrateur",

  UserDetailsPage_InputLabel_first_name: "Prénom*",
  UserDetailsPage_InputPlaceholder_first_name: "Prénom",
  UserDetailsPage_InputLabel_last_name: "Nom*",
  UserDetailsPage_InputPlaceholder_last_name: "Nom",
  UserDetailsPage_Role_Label: "Rôles*",
  UserDetailsPage_IsEnabled_Label: "État",
  UserDetailsPage_IsEnabled_True_Label: " (Activé)",
  UserDetailsPage_IsEnabled_False_Label: " (Archivé)",
  UserDetailsPage_InputLabel_email: "Email*",
  UserDetailsPage_InputPlaceholder_email: "Email",
  UserDetailsPage_Invite_Success: "Email d'invitation envoyé",

  ExhibitionDetailsPage_NewsCard_Label: "Actualité de l'expo",
  ExhibitionDetailsPage_InputPlaceholder_news_title: "Titre de l'actu",
  ExhibitionDetailsPage_InputPlaceholder_news_content: "...",
};
