import "./Tabs.scss";

import { Tabs as MuiTabs } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import React from "react";

import { CustomButton, CustomButtonProps } from "../CustomButton/CustomButton";

export interface CustomTabProps {
  label: React.ReactNode;
  value: string;
  children: React.ReactNode;
}

export interface CustomTabsProps {
  value: string;
  onChange: (event: React.ChangeEvent<{}>, value: string) => void;
  tabs: Array<CustomTabProps>;
  buttons?: Array<CustomButtonProps>;
  endActions?: React.ReactNode;
}

const CustomTabs = ({ value, onChange, tabs, buttons, endActions }: CustomTabsProps) => {
  const mappedTabs = tabs.map((tabProps: CustomTabProps, index: number) => <Tab key={index} {...tabProps} />);
  const mappedTabPanels = tabs.map((child, index: number) => {
    return (
      <div key={index} hidden={value !== child.value} className="Tabs-children">
        {child.children}
      </div>
    );
  });
  let mappedButtons: Array<React.ReactNode> = [];
  if (buttons) {
    mappedButtons = buttons.map((item: CustomButtonProps, index: number) => <CustomButton key={index} {...item} />);
  }

  return (
    <div className="Tabs-root">
      <div className="Tabs-withButtons">
        <MuiTabs value={value} onChange={onChange}>
          {mappedTabs}
        </MuiTabs>
        {endActions}
        <div className="Tabs-borderBottom" />
        {mappedButtons}
      </div>
      {mappedTabPanels}
    </div>
  );
};

export { CustomTabs };
