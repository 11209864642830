import React from 'react';

export interface UseViewPropsParams<TProps extends {}> {
    readonly props: TProps;
}

export interface UseViewPropsResult<TProps extends {}> {
    readonly prevProps?: TProps;
    readonly currentProps: TProps;
}

export const useViewProps = <TProps extends {}>(props: TProps): UseViewPropsResult<TProps> => {
    const refPrevProps = React.useRef<TProps | undefined>(undefined);
    React.useEffect(() => {
        refPrevProps.current = props;
    });
    return {
        prevProps: refPrevProps.current,
        currentProps: props,
    };
};
