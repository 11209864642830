import { Languages } from "../../Models/Languages/Languages";
import { CreateNewsLocalizedContentRequest, CreateNewsRequest } from "../../Models/News/News";
import { NewsDetailsViewState } from "./NewsDetailsViewModel";

export function newsDetailsViewStatetoCreateNewsRequest(s: NewsDetailsViewState): CreateNewsRequest {
  const localized_content = Object.assign(
    {},
    ...(Object.keys(s.localized_content) as Languages[])
      .map((lang): Record<string, CreateNewsLocalizedContentRequest> | undefined => {
        const lc = s.localized_content[lang];
        return {
          [lang]: {
            title: lc.title,
            picture_id: lc.picture_id,
            content: lc.content,
          },
        };
      })
      .filter((c) => Boolean(c))
  ) as Record<Languages, CreateNewsLocalizedContentRequest>;

  return {
    localized_content,
  };
}
