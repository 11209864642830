import classNames from "classnames";
import { useCallback } from "react";
import { FileInputValue } from "../../../../Core/Models/Files/File";
import { InputBaseProps } from "../InputBase/InputBaseProps";
import { InputWrapper } from "../InputBase/InputWrapper";
import { AddNewFileButtonProps, FileInputItemProps } from "./FilesInputItem";
import { MediaType } from "./MediaInput";

const getFileTempId = (file: File) => `${new Date().toISOString()}${file.name}`;

export interface FileInputChildComponentRenderProps {
  renderAddNewButton: (props: AddNewFileButtonProps) => React.ReactNode;
  renderItem: (props: FileInputItemProps) => React.ReactNode;
  buttonTitle: React.ReactNode;
}
export interface FileInputPropsBase {
  resolveUrl?: (file: FileInputValue) => string;
  isBusy?: boolean;
  accept: string;
}

export type FilesInputProps = InputBaseProps<FileInputValue[]> &
  FileInputPropsBase &
  FileInputChildComponentRenderProps & { maxCount: number };
export type FileInputProps = InputBaseProps<FileInputValue | null> &
  FileInputChildComponentRenderProps &
  FileInputPropsBase;

export const FilesInput = (props: FilesInputProps) => {
  const {
    className,
    onChange,
    value,
    disabled,
    isBusy,
    resolveUrl: resolveUrlProp,
    accept,
    maxCount,
    renderAddNewButton,
    renderItem,
    buttonTitle,
  } = props;

  // Get the url from an 'Media' object
  const resolveUrl = useCallback(
    (file: FileInputValue) => {
      if (resolveUrlProp) {
        return resolveUrlProp(file);
      }

      return file.url ?? "";
    },
    [resolveUrlProp]
  );

  const addFiles = useCallback(
    (files: File[]) => {
      onChange(
        [
          ...value,
          ...files.map((f) => {
            return {
              id: getFileTempId(f),
              file: f,
              fileName: f.name,
              type: f.type.startsWith("image") ? MediaType.image : MediaType.video,
            };
          }),
        ].slice(0, maxCount)
      );
    },
    [onChange, value, maxCount]
  );

  const editFile = useCallback(
    (file: File, fileIndex: number) => {
      onChange(
        value.map((v, i) =>
          i === fileIndex
            ? {
                id: getFileTempId(file),
                file,
                fileName: file.name,
                type: file.type.startsWith("image") ? MediaType.image : MediaType.video,
              }
            : v
        )
      );
    },
    [onChange, value]
  );

  const deleteFile = useCallback(
    (fileIndex: number) => {
      onChange(value.filter((_, i) => i !== fileIndex));
    },
    [onChange, value]
  );

  return (
    <InputWrapper {...props} className={classNames("FilesInput-root", className)}>
      <div className="FilesInput-list">
        {renderAddNewButton({
          accept,
          onNewFiles: addFiles,
          disabled: disabled || value.length >= maxCount,
          buttonTitle: buttonTitle,
        })}

        {value &&
          value.map((v, i) =>
            renderItem({
              key: v.id ?? i,
              resolveUrl,
              value: v,
              isBusy,
              accept,
              disabled: disabled,
              onNewFile: (file) => editFile(file, i),
              onDelete: () => deleteFile(i),
              type: v.type,
            })
          )}
      </div>
    </InputWrapper>
  );
};

export const FileInput = ({ onChange, value, ...props }: FileInputProps) => {
  const onMultipleChange = useCallback(
    (val: FileInputValue[]) => {
      onChange(val && val.length > 0 ? val[0] : null);
    },
    [onChange]
  );

  return <FilesInput {...props} onChange={onMultipleChange} value={value ? [value] : []} maxCount={1} />;
};
