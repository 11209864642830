import React from "react";
import { BaseEditor, Descendant, Editor } from "slate";
import { ReactEditor } from "slate-react";
import { HTMLDeserializer, HTMLSerializer } from "./Serializers";
import { ImageMedia } from "../Context/RichtextConfig";

export type SlateEditorValue = Descendant[];
export type SlateEditorChange = { children: SlateEditorValue };

export interface SlateEditorValueChanged {
    readonly editorState?: SlateEditorValue;
    readonly modified?: boolean;
}

export class SlateRichText {

    public static IMG_REGEX: RegExp = /src="([^"]*)"/g;

    public static addMark = (event: React.MouseEvent | React.KeyboardEvent, type: string, editor: BaseEditor & ReactEditor) => {
        event.preventDefault();
        if (SlateRichText.isMarkActive(type, editor)) {
            Editor.removeMark(editor, type);
        } else {
            Editor.addMark(editor, type, true);
        }
    };

    public static isMarkActive = (type: string, editor: BaseEditor & ReactEditor) => {
        const marks = Editor.marks(editor) as any;
        return marks ? marks[type] === true : false;
    }

    public static getEmptyState = () => {
        return HTMLDeserializer("<div></div>");
    };

    public static toRichTextDescription = (description: string): {
        description: string;
        editorState: SlateEditorValue
    } => ({
        description,
        editorState: SlateRichText.toDescriptionRTE(description),
    });

    public static toDescriptionRTE = (description?: string) => description ? HTMLDeserializer(description) : SlateRichText.getEmptyState();

    public static toEditorState = (description: string = ""): SlateEditorValue => HTMLDeserializer(description);

    public static getDescriptionFromRTE = (basicDescription?: string, editorState?: SlateEditorValue): string | undefined => editorState ? HTMLSerializer({ children: editorState }) : basicDescription;

    public static toDescriptionFromRTE = (editorState?: SlateEditorValue): string | undefined => editorState ? HTMLSerializer({ children: editorState }) : undefined;

    public static didContentChange = (prev?: SlateEditorValue, next?: SlateEditorValue) => {
        const prevSerialized = prev ? HTMLSerializer({ children: prev }) : "";
        const nextSerialized = next ? HTMLSerializer({ children: next }) : "";
        return prevSerialized !== nextSerialized;
    };

    public static getEditorChangedState = (editorChange: SlateEditorChange) => ({
                                                                                    editorState: prevValue,
                                                                                    modified: prevModified,
                                                                                }: SlateEditorValueChanged) => {
        return {
            editorState: editorChange.children,
            modified: prevModified || SlateRichText.didContentChange(prevValue, editorChange.children),
        };
    };

    public static toEditorChangedState = (
        editorChange: SlateEditorChange,
        { editorState: prevValue, modified: prevModified }: SlateEditorValueChanged
    ) => ({
        editorState: editorChange.children,
        modified: prevModified || SlateRichText.didContentChange(prevValue, editorChange.children),
    });

    public static toDescriptionWithAuthorizedImg = (rteDesc: string, getUrl: (imageMedia?: ImageMedia) => any): string => {
        // @ts-ignore
        return !rteDesc ? '' : rteDesc.replace(this.IMG_REGEX, (_, capturedSrcValue) => (`src="${getUrl({
            id: '',
            url: capturedSrcValue,
        })}"`));
    }

    public static toDescriptionWithBlobImgAsync = async (rteDesc: string, getUrl: (imageMedia?: ImageMedia) => Promise<Blob | MediaSource>): Promise<string> => {
        if (!rteDesc) {
            return '';
        }

        const images: Array<Promise<Blob | MediaSource>> = [];
        const IMG_ID_REGEX = /[^/]*$/;

        let match;
        while ((match = SlateRichText.IMG_REGEX.exec(rteDesc))) {
            const imageId = IMG_ID_REGEX.exec(match[1])?.[0];
            images.push(getUrl({ id: imageId ?? '', url: '' }));
        }

        // @ts-ignore
        return Promise.all(images).then((values: Array<Blob | MediaSource>) => rteDesc.replace(this.IMG_REGEX, () => `src="${window.URL.createObjectURL(values.shift()!)}"`));
    };
}
