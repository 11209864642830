import { MediaType } from "../../../Web/Components/Views/MediaInput/MediaInput";
import { ArtWorkDetailsResponse } from "../../Models/ArtWork/ArtWork";
import { FileInputValue } from "../../Models/Files/File";
import { Languages } from "../../Models/Languages/Languages";
import {
  ArtWorkInformationTabViewState,
  ArtWorkInformationContent,
  DefaultLocalizedContent,
} from "./ArtWorkInformationTabViewModel";

export function artWorkDetailsResponseToArtWorkInformationTabViewState(
  response: ArtWorkDetailsResponse,
  existingState: ArtWorkInformationTabViewState
) {
  // Make sure that the received object is complet without any undefined value
  const apiLocalizedContent = response.data.localized_content;
  let localized_content: Record<Languages, ArtWorkInformationContent> = {
    fr: DefaultLocalizedContent,
    nl: DefaultLocalizedContent,
  };

  for (let lang of Object.keys(apiLocalizedContent) as Languages[]) {
    const currentContent = apiLocalizedContent[lang];
    const picture: FileInputValue[] = [];
    if (currentContent.picture_url) {
      picture.push({ id: currentContent.picture_id, type: MediaType.image, url: currentContent.picture_url });
    }

    localized_content[lang] = {
      ...DefaultLocalizedContent,
      ...currentContent,
      localPicture: picture,
      editorState: currentContent.description || "",
    };
  }

  return {
    ...existingState,
    ...response.data,
    localized_content: localized_content,
  };
}
