import { CreateOrUpdateArtworkRequest, LocalizedContentRequest } from "../../Models/ArtWork/ArtWork";
import { Languages } from "../../Models/Languages/Languages";
import { ArtWorkInformationTabViewState } from "./ArtWorkInformationTabViewModel";
import { isArtWorkInformationContentEmpty } from "./isArtWorkInformationContentEmpty";

export function artWorkInformationTabViewStateToCreateOrUpdateRequest(
  s: ArtWorkInformationTabViewState
): CreateOrUpdateArtworkRequest {
  const localized_content = Object.assign(
    {},
    ...(Object.keys(s.localized_content) as Languages[])
      .map((lang): Record<string, LocalizedContentRequest> | undefined => {
        const lc = s.localized_content[lang];
        if (isArtWorkInformationContentEmpty(lc)) {
          return undefined;
        }
        return {
          [lang]: {
            artist_name: lc.artist_name,
            country: lc.country,
            title: lc.title,
            picture_id: lc.picture_id,
            subtitle: lc.subtitle,
            description: lc.description,
            short_description: lc.short_description,
            copyrights: lc.copyrights,
          },
        };
      })
      .filter((c) => Boolean(c))
  ) as Record<Languages, LocalizedContentRequest>;

  return {
    year: s.year,
    section_id: s.section!.id,
    latitude: s.latitude,
    longitude: s.longitude,
    is_outside: s.is_outside,
    is_published: s.is_published,
    published_date: s.published_date,
    localized_content,
  };
}
