import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { OptionalRouteParams } from "../../../../Core/Models/RouteParams";
import { VideoDetailsViewModel, VideoDetailsViewState } from "../../../../Core/ViewModels/Videos/VideoDetailsViewModel";
import { ViewModelLocator } from "../../../../Core/ViewModels/ViewModelLocator";
import { useInputTextFieldProps } from "../../../Hooks/Form/useInputProps";
import { useLifecycle } from "../../../Hooks/Lifecycle/useLifecycle";

export const useVideoDetailsPageController = () => {
  const { id } = useParams<OptionalRouteParams>();

  const { vm, state } = useLifecycle<VideoDetailsViewState, VideoDetailsViewModel>({
    getRef: () => ViewModelLocator.VideoDetailsVM,
    setRefValue: (value) => (ViewModelLocator.VideoDetailsVM = value),
    newInstance: () => new VideoDetailsViewModel(),
    logName: "VideoDetailsVM",
  });

  useEffect(() => {
    vm.onItemChange(id);
  }, [id, vm]);

  const registerField = useInputTextFieldProps(state, vm.onChangeField, {
    hideLabel: true,
    i18nPrefix: "VideoDetailsPage",
  });

  return { vm, state, registerField };
};
