import { Lazy } from "../Helpers/Lazy";
import { getI18nService, II18nService } from "../Services/Data/I18n/I18nService";

export type UserInfoType = {
  first_name: string;
  last_name: string;
};

export class UserUtils {
  static getFullName = (user?: UserInfoType): string => {
    const i8nService: Lazy<II18nService> = getI18nService();

    return i8nService.value().formatMessage("COMMON_FULLNAME", {
      firstName: user?.first_name,
      lastName: user?.last_name,
    });
  };
}
