import "./VideoDetailsPage.scss";
import { AuthenticatedPageLayout } from "../../AuthenticatedPageLayout/AuthenticatedPageLayout";
import { FormattedMessage } from "react-intl";
import { AppRoutes } from "../../../../Core/App/AppRoutes";
import { CustomInputText, InputType } from "../../../Components/Views/CustomInputText/CustomInputText";
import { useVideoDetailsPageController } from "./VideoDetailsPageController";
import { MediaInput } from "../../../Components/Views/MediaInput/MediaInput";
import { CustomButton, CustomButtonClassType } from "../../../Components/Views/CustomButton/CustomButton";
import { Icons } from "../../../Components/Views/Icons/Icons";

const VideoDetailsPage = () => {
  const { state, vm, registerField } = useVideoDetailsPageController();

  const titleProps = registerField("title");
  const thumbnailOffsetProps = registerField("thumbnailOffset");
  const videoProps = registerField("video");

  return (
    <AuthenticatedPageLayout
      previousTitle={<FormattedMessage id="VideoList_VideoTitle" />}
      title={
        state.id ? <FormattedMessage id="VideoDetails_EditVideo" /> : <FormattedMessage id="VideoDetails_AddVideo" />
      }
      previousTitleLink={AppRoutes.VIDEO_LIST}
      loading={state.busy}>
      <div className="VideoDetailsPage-root">
        <div className="VideoDetailsPage-content">
          <div className="VideoDetailsPage-contentLeft">
            <CustomInputText
              {...titleProps}
              labelIntlId="VideoDetails_TitleLabel"
              classes={{ root: "VideoDetailsPage-title" }}
            />

            {state.mode === "edit" && (
              <div className="VideoDetailsPage-contentLeft-thumbnail">
                <CustomInputText
                  {...thumbnailOffsetProps}
                  labelIntlId="VideoDetails_ThumbnailOffsetLabel"
                  type={InputType.Number}
                  classes={{ root: "VideoDetailsPage-thumbnailOffset" }}
                />
              </div>
            )}
          </div>

          {state.id ? (
            <video src={state.video[0].url} controls poster={state.thumbnailUrl} />
          ) : (
            <MediaInput
              {...videoProps}
              acceptedFileType="video/*"
              maxCount={1}
              labelIntlId="VideoDetails_VideoLabel"
              className="VideoDetailsPage-videoInput"
            />
          )}
        </div>
        <div className="VideoDetailsPage-actions">
          <CustomButton
            variant={CustomButtonClassType.Success}
            title={<FormattedMessage id="VideoDetailsPage_SaveTitle" />}
            className="VideoDetailsPage-saveButton"
            onClick={vm.onSubmit}
            icon={Icons.IconSave}
          />
        </div>
      </div>
    </AuthenticatedPageLayout>
  );
};

export { VideoDetailsPage };
