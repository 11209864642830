import { Editor, Element, Node, Path, Range, Transforms } from "slate";
import { ReactEditor, useSlate } from "slate-react";
import { CustomElement, ElementType, LinkElement, ParagraphElement } from "../CustomTypes.d";
import { createLinkNode, createParagraphNode } from "./CreateCustomNodes";

// Used to unwrap link from a node
export const removeLink = (editor: Editor, opts = {}) => {
  Transforms.unwrapNodes(editor, {
    ...opts,
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === ElementType.link,
  });
};

// Function to check if current selection is a link
export const HasLinks = () => {
  const editor = useSlate();
  if (editor && editor.selection) {
    const parentPath = Path.parent(editor.selection.focus.path);
    const parentNode = Node.get(editor, parentPath) as CustomElement;
    return parentNode.type === ElementType.link;
  }
}

export interface InsertLinkProps {
  editor: Editor;
  url: string;
  title: string;
}

// Used to insert a new link into the editor
export const insertLink = ({ editor, url, title }: InsertLinkProps) => {
  if (!url) return;

  const { selection } = editor;
  const link = createLinkNode(url, title) as LinkElement;

  ReactEditor.focus(editor);

  if (!!selection) {
    const parentPath = Path.parent(selection.focus.path);
    const parentNode = Node.get(editor, parentPath) as CustomElement;
    // Remove the Link node if we're inserting a new link node inside another link.
    if (parentNode.type === ElementType.link) {
      removeLink(editor);
    }

    if (editor.isVoid(parentNode as CustomElement)) {
      // Checking is the node is void and inserting the new link after it
      Transforms.insertNodes(editor, createParagraphNode([link as any]) as ParagraphElement, {
        at: Path.next(parentPath),
        select: true,
      });
    } else if (Range.isCollapsed(selection)) {
      // Insert the new link in our last known location.
      Transforms.insertNodes(editor, link, { select: true });
    } else {
      // Wrap the currently selected range of text into a Link.
      Transforms.wrapNodes(editor, link, { split: true });
      Transforms.collapse(editor, { edge: "end" });
    }
  } else {
    // Insert the new link node at the bottom of the Editor when nothing is selected
    Transforms.insertNodes(editor, createParagraphNode([link as any]) as ParagraphElement);
  }
};
