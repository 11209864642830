import React, { useCallback, useMemo } from 'react';
import { createEditor, Descendant, Editor, Transforms } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { Paragraph } from './Components/Paragraph/Paragraph';
import { Image } from './Components/Image/Image';
import { withImageAndVideo } from './Plugins/withImageAndVideo';
import pipe from 'lodash/fp/pipe';
import withKeyCommands from './Plugins/withKeyCommands';
import { Toolbar } from './Toolbar/Toolbar';
import { Link } from './Components/Link/Link';
import withLinks from './Plugins/withLinks';
import { Video } from './Components/Video/Video';
import { HTMLDeserializer, HTMLSerializer, normalizeNodes } from './Utils/Serializers';
import { CustomElement, ElementType } from './CustomTypes.d';
import { useRTEConfig } from './Context/RichtextConfig';
import styled, { ThemeProvider } from 'styled-components';
import { SlateRichText } from './Utils/SlateRichText';

type VideoType = {
    id: string;
    video_id: string;
    url: string;
    creation_date: Date;
    in_progress: boolean;
    title: string;
    thumbnailUrl: string;
    thumbnail_offset: number;
};

export interface XRichTextProps {
    updateState?: (editorState: Editor) => void;
    videoList?: VideoType[];
    busyLoadingVideos?: boolean;
    value?: any;
    placeholder?: string;
    currentLanguage?: string;
    readonly?: boolean;
    tabIndex?: number;
    withoutContainer?: boolean;
}

const initialValue: Descendant[] = [
    {
        type: ElementType.paragraph,
        children: [{ text: '' }],
    },
];

const createEditorWithPlugins = pipe(withReact, withHistory, withImageAndVideo, withLinks, withKeyCommands);

export const XRichText = ({
    updateState,
    videoList,
    busyLoadingVideos,
    value,
    placeholder = 'Commencez à écrire ici...',
    currentLanguage = 'fr',
    readonly = false,
    tabIndex,
}: XRichTextProps) => {
    const editor = useMemo(() => createEditorWithPlugins(createEditor()), []);
    const [editorValue, setEditorValue] = React.useState(initialValue);
    const [initialization, setInitialization] = React.useState(true);
    const [initializedLanguage, setInitializedLanguage] = React.useState(currentLanguage);
    const desc = editorValue ? HTMLSerializer(editor) : '';
    const RTEConfig = useRTEConfig();

    const renderElement = useCallback((props) => {
        return <Element {...props} />;
    }, []);

    const renderLeaf = useCallback((props) => {
        return <Leaf {...props} />;
    }, []);

    React.useEffect(() => {
        if ((value && initialization && typeof value !== 'object') || initializedLanguage !== currentLanguage) {
            // When value change delete all the nodes in the editor
            Transforms.delete(editor, {
                at: {
                    anchor: Editor.start(editor, []),
                    focus: Editor.end(editor, []),
                },
            });

            // Removes empty node
            Transforms.removeNodes(editor, {
                at: [0],
            });

            // And finally insert array of children nodes into the editor
            const deserialized = HTMLDeserializer(value);
            Transforms.insertNodes(editor, deserialized);
            setEditorValue(deserialized);
            setInitialization(false);
            setInitializedLanguage(currentLanguage ? currentLanguage : initializedLanguage);
        } else if (
            value &&
            initialization &&
            typeof value === 'object' &&
            (value.length > 1 ||
                (value[0].children.length === 1 && value[0].children[0].text !== '') ||
                value[0].children.length > 1)
        ) {
            // When value change delete all the nodes in the editor
            Transforms.delete(editor, {
                at: {
                    anchor: Editor.start(editor, []),
                    focus: Editor.end(editor, []),
                },
            });

            // Removes empty node
            Transforms.removeNodes(editor, {
                at: [0],
            });

            // And finally insert array of children nodes into the editor
            if (value.find((e: CustomElement) => e.type === undefined)) {
                let normalizedNodes = normalizeNodes({ children: value }, RTEConfig.getUrl);
                Transforms.insertNodes(editor, normalizedNodes);
                setEditorValue(normalizedNodes);
            } else {
                Transforms.insertNodes(editor, value);
                setEditorValue(value);
            }
            setInitialization(false);
        }
        // eslint-disable-next-line
    }, [value, currentLanguage]);

    return (
        <ThemeProvider theme={RTEConfig.rteStyle}>
            <DescriptionEditorWrapper>
                <Slate editor={editor} initialValue={editorValue} onChange={() => updateState?.(editor)}>
                    <Toolbar videoList={videoList} busyLoadingVideos={busyLoadingVideos} />
                    <Editable
                        className="description-editor-editable"
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        placeholder={placeholder}
                        renderPlaceholder={({ children, attributes }) => (
                            <div {...attributes}>
                                <p>{children}</p>
                            </div>
                        )}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' && !event.shiftKey) {
                                event.preventDefault();
                                editor.insertBreak();
                            } else if (event.key === 'Enter' && event.shiftKey) {
                                event.preventDefault();
                                editor.insertSoftBreak();
                            } else if (event.key === 'b' && event.ctrlKey) {
                                SlateRichText.addMark(event, 'bold', editor);
                            } else if (event.key === 'i' && event.ctrlKey) {
                                SlateRichText.addMark(event, 'italic', editor);
                            } else if (event.key === 'u' && event.ctrlKey) {
                                SlateRichText.addMark(event, 'underlined', editor);
                            }
                        }}
                        readOnly={readonly}
                        tabIndex={tabIndex}
                    />
                </Slate>
            </DescriptionEditorWrapper>
            {RTEConfig.isDev?.valueOf() && (
                <React.Fragment>
                    <div style={{ marginTop: '10px' }}>{desc}</div>
                    <div
                        style={{ marginTop: '5px' }}
                        dangerouslySetInnerHTML={{
                            __html: desc,
                        }}
                    />
                </React.Fragment>
            )}
        </ThemeProvider>
    );
};

const Element = (props: any) => {
    switch (props.element.type) {
        case ElementType.image:
            return <Image {...props} />;
        case ElementType.link:
            return <Link {...props} />;
        case ElementType.video:
            return <Video {...props} />;
        default:
            return <Paragraph {...props} />;
    }
};

const Leaf = ({ attributes, children, leaf }: any) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }
    if (leaf.italic) {
        children = <em>{children}</em>;
    }
    if (leaf.underlined) {
        children = <u>{children}</u>;
    }
    return <span {...attributes}>{children}</span>;
};

const DescriptionEditorWrapper = styled.div`
    border: 1px solid #949494;
    background-color: ${(props) => props.theme.editorBackgroundColor ?? 'transparent'};
    min-height: ${(props) => props.theme.editorMinHeight ?? 'initial'};
    :focus {
        outline: none;
    }
    &:not(.readonly) {
        [data-slate-editor="true"] {
            -webkit-user-modify: read-write !important;
        }
    }
    .description-editor-editable {
        padding: ${(props) => props.theme.editorDescriptionPadding ?? '16px'};
        :focus {
            outline: none;
        }
    }
`;
