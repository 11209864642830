import { AppRoutes } from "../../../Core/App/AppRoutes";
import { AppRouteType } from "../../Components/Templates/AppRouter/AppRouteType";
import { PrivateRoute } from "../../Components/Templates/AppRouter/PrivateRoute";
import { UserDetailsPage } from "../../Pages/Users/UserDetailsPage";
import { UserListPage } from "../../Pages/Users/UserListPage";

const BOUserListRoute: AppRouteType = {
  id: AppRoutes.BO_USER_LIST,
  render: PrivateRoute,
  props: {
    exact: true,
    path: AppRoutes.BO_USER_LIST,
    component: UserListPage,
  },
};

const BOUserDetailsRoute: AppRouteType = {
  id: AppRoutes.BO_USER_DETAILS,
  render: PrivateRoute,
  props: {
    exact: true,
    path: AppRoutes.BO_USER_DETAILS,
    component: UserDetailsPage,
  },
};

const BOUserCreateRoute: AppRouteType = {
  id: AppRoutes.BO_USER_CREATE,
  render: PrivateRoute,
  props: {
    exact: true,
    path: AppRoutes.BO_USER_CREATE,
    component: UserDetailsPage,
  },
};

export const getBOUserListRoutes = () => [BOUserListRoute, BOUserCreateRoute, BOUserDetailsRoute];
