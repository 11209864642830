import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { Video } from "../../../Core/Models/Video/Video";
import { getNavigationService } from "../../../Core/Services/Interfaces/INavigationService";
import { DateUtils } from "../../../Core/Utils/DateUtils";
import { CustomButton } from "../../Components/Views/CustomButton/CustomButton";
import { Icons } from "../../Components/Views/Icons/Icons";
import { Loader } from "../../Components/Views/Loader";
import { AuthenticatedPageLayout } from "../AuthenticatedPageLayout/AuthenticatedPageLayout";
import "./VideoListPage.scss";
import { useVideoListPageController } from "./VideoListPageController";

const VideoListPage = () => {
  const { state } = useVideoListPageController();

  return (
    <AuthenticatedPageLayout title={<FormattedMessage id="VideoList_VideoTitle" />}>
      <div className="VideoListPage-root">
        <div className="VideoListPage-actions">
          <CustomButton
            title={<FormattedMessage id="VideoList_AddVideo" />}
            icon={Icons.IconAdd}
            onClick={() => getNavigationService().value().goToVideoCreate()}
            className="VideoListPage-addVideo"
          />
        </div>
        {state.busy && <Loader classes={{ container: "VideoListPage-loader" }} />}
        <div className="VideoListPage-list">
          {state.items?.map((video: Video) => (
            <div
              key={video.id}
              onClick={() => getNavigationService().value().goToVideoDetails(video.id)}
              className={classNames({
                "VideoListPage-listElement": true,
                "VideoListPage-elementInProgress": video.in_progress,
              })}>
              {video.in_progress ? <div className="VideoListPage-videoInProgress" /> : <video src={video.url} />}
              <div className="VideoListPae-listElementDate">{DateUtils.formatDateLong(video.creation_date)}</div>
              <div className="VideoListPae-listElementTitle">{video.title}</div>
            </div>
          ))}
        </div>
      </div>
    </AuthenticatedPageLayout>
  );
};

export { VideoListPage };
