import { AppRoutes } from "../../../Core/App/AppRoutes";
import { AppRouteType } from "../../Components/Templates/AppRouter/AppRouteType";
import { PrivateRoute } from "../../Components/Templates/AppRouter/PrivateRoute";
import { VideoListPage } from "../../Pages/Videos/VideoListPage";
import { VideoDetailsPage } from "../../Pages/Videos/VideoDetails/VideoDetailsPage";

const videoListRoute: AppRouteType = {
  id: AppRoutes.VIDEO_LIST,
  render: PrivateRoute,
  props: {
    exact: true,
    path: AppRoutes.VIDEO_LIST,
    component: VideoListPage,
  },
};

const videoCreateRoute: AppRouteType = {
  id: AppRoutes.VIDEO_CREATE,
  render: PrivateRoute,
  props: {
    exact: true,
    path: AppRoutes.VIDEO_CREATE,
    component: VideoDetailsPage,
  },
};

const videoDetailsRoute: AppRouteType = {
  id: AppRoutes.VIDEO_DETAILS,
  render: PrivateRoute,
  props: {
    exact: true,
    path: AppRoutes.VIDEO_DETAILS,
    component: VideoDetailsPage,
  },
};

export const getVideoRoutes = () => [videoListRoute, videoCreateRoute, videoDetailsRoute];
