import "./CustomSwitch.scss";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

export interface SwitchProps {
  onChange: (checked: boolean) => void;
  checked: boolean;
  labelIntlId: string;
  className?: string;
  disabled?: boolean;
  sufix?: React.ReactNode;
}

const CustomSwitch = ({ onChange, checked, labelIntlId, className, disabled, sufix }: SwitchProps) => {
  return (
    <div
      className={classNames({
        "CustomSwitch-wrap": true,
        "CustomSwitch-disabled": disabled,
        [className as string]: className,
      })}>
      <div className="CustomSwitch-label">
        <FormattedMessage id={labelIntlId} />
      </div>
      <div
        onClick={() => !disabled && onChange(!checked)}
        className={classNames({
          "CustomSwitch-root": true,
          "CustomSwitch-disabled": disabled,
        })}>
        <div className={classNames({ "CustomSwitch-track": true, "CustomSwitch-trackChecked": checked })} />
        <div className={classNames({ "CustomSwitch-thumb": true, "CustomSwitch-thumbChecked": checked })} />
      </div>

      {sufix}
    </div>
  );
};

export { CustomSwitch };
