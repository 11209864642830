import { CancelTokenSource } from 'axios';
import React from 'react';

export type ImageMedia = {
  id: string;
  url: string;
};

export type RTEStyleType = {
  editorBackgroundColor?: string;
  editorMinHeight?: string;
  editorDescriptionPadding?: string;

  popupButtonHoverColor: string;

  buttonStandardTextColor: string;
  buttonStandardBackgroundColor: string;
  buttonStandardBackgroundHoverColor: string;

  buttonDangerBackgroundColor: string;
  buttonDangerTextColor: string;
  buttonDangerBorderColor: string;
  buttonDangerBackgroundHoverColor: string;

  inputTextTextColor: string;
  inputTextErrorColor: string;

  inputTextStandardBorderColor: string;
  inputTextStandardBorderColorFocus: string;

  videoListPageItemBackgroundColor?: string;
  videoListPageItemTextColor?: string;
  videoListPageItemInProgressBackgroundColor?: string;
  videoListPageProgressBarThumb?: string;

  customButtonWidth: string;
  customButtonHeight: string;
};

export type RTEContextValue = {
  linkTargetLabel?: string; // "Cible du lien"
  linkTargetPlaceholder?: string; // "Saisissez la cible du lien"
  linkTitleLabel?: string; // "Titre du lien"
  linkTitlePlaceholder?: string; // "Saisissez le titre du lien"
  linkErrorMessageNotUrl?: string; // "Veuillez entrer un lien valide"
  linkErrorMessageRequired?: string; // "Ce champ est requis"
  imageDropzoneTitle?: string; // "Ajouter une photo"
  imageDropzoneLabel?: string; // "Visuel photo"
  imageDropzoneCancelLabel?: string; // "Annuler"
  imageDropzoneLoadingMessage?: string; // "Chargement de l'image"
  rteButtonAddLabel?: string; // "Ajouter"
  rteButtonCancelLabel?: string; // "Annuler"
  rteStyle?: any;
  isDev?: boolean;
  getUrl?: (imageMedia?: ImageMedia, params?: { height: number }) => string;
  onImageUpload?: (file: File, onProgress?: (progress: number) => void, cts?: CancelTokenSource) => Promise<ImageMedia>;
};

export const RTEContext = React.createContext<RTEContextValue>({});

export const useRTEConfig = () => React.useContext(RTEContext);
