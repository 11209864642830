import { FormattedMessage } from "react-intl";
import { AppRoutes } from "../../../../Core/App/AppRoutes";
import { ArtWorkDetailsTabs } from "../../../../Core/ViewModels/ArtWork/ArtWorkDetailsViewModel";
import { CustomTabProps, CustomTabs } from "../../../Components/Views/Tabs/Tabs";
import { AuthenticatedPageLayout } from "../../AuthenticatedPageLayout/AuthenticatedPageLayout";
import { ArtWorkDescriptionTab } from "./ArtWorkDescriptionTab/ArtWorkDescriptionTab";
import "./ArtWorkDetailsPage.scss";
import { useArtWorkDetailsPageController } from "./ArtWorkDetailsPageController";
import { ArtWorkInformationTab } from "./ArtWorkInformationTab/ArtWorkInformationTab";
import { useArtWorkInformationTabController } from "./ArtWorkInformationTab/ArtWorkInformationTabController";
import { SelectLanguage } from "../../../Components/Views/SelectLanguage/SelectLanguages";

const ArtWorkDetailsPage = () => {
    const { vm, onSelectedTab, state } = useArtWorkDetailsPageController();
    const artworkController = useArtWorkInformationTabController(vm.setName);

    const getTabs = (): Array<CustomTabProps> => {
        return [
            {
                children: <ArtWorkInformationTab artworkController={artworkController}/>,
                value: ArtWorkDetailsTabs.Informations,
                label: <FormattedMessage id="ArtWorkDetailsPage_InfoTabTitle"/>,
            },
            {
                children: <ArtWorkDescriptionTab artworkController={artworkController}/>,
                value: ArtWorkDetailsTabs.Description,
                label: <FormattedMessage id="ArtWorkDetailsPage_DescriptionTabTitle"/>,
            },
        ];
    };

    return (
        <AuthenticatedPageLayout
            previousTitle={<FormattedMessage id="ArtWorkListPage_Title"/>}
            previousTitleLink={AppRoutes.ART_WORK_LIST}
            title={state.mode === "create" ? <FormattedMessage id="ArtWorkDetailsPage_Title"/> : state.name}>
            <div className="ArtWorkDetailsPage-root">
                <CustomTabs
                    tabs={getTabs()}
                    onChange={(_, value) => onSelectedTab(value as ArtWorkDetailsTabs)}
                    value={vm.state.selectedTabId}
                    endActions={
                        <SelectLanguage
                            changeCurrentLanguage={artworkController.vm.changeCurrentLanguage}
                            currentLanguage={artworkController.state.currentLanguage}
                            errors={artworkController.state.fieldsErrors.localized_content}
                        />
                    }
                />
            </div>
        </AuthenticatedPageLayout>
    );
};

export { ArtWorkDetailsPage };
