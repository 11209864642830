import "./CustomRadioGroup.scss";

import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import classNames from "classnames";
import React from "react";

import { CustomRadioButton, CustomRadioButtonProps } from "./CustomRadioButton/CustomRadioButton";

export interface CustomRadioGroupProps {
  label: React.ReactNode;
  value?: string;
  items: Array<CustomRadioButtonProps>;
  onChange(value: string): void;
  loading?: boolean;
  classes?: { root?: string; label?: string };
  errorMessage?: string;
}

export const CustomRadioGroup: React.FunctionComponent<CustomRadioGroupProps> = ({
  label,
  value,
  items,
  onChange,
  loading,
  errorMessage,
  classes,
}: CustomRadioGroupProps) => {
  //#region //* CLASSES

  const error: boolean = errorMessage !== undefined && errorMessage !== "";

  const { root: rootClasses, label: labelClasses } = classes ?? { root: "", label: "" };
  const enhancedRootClasses = classNames({
    "CustomRadioGroup-root": true,
    [rootClasses || ""]: rootClasses,
    error,
  });
  const enhancedLabelClasses = classNames({
    "CustomRadioGroup-label": true,
    [labelClasses || ""]: labelClasses,
    error,
  });
  const enhancedGroupClasses = classNames({
    "CustomRadioGroup-group": true,
    error,
  });
  const enhancedGroupItemsClasses = classNames({
    "CustomRadioGroup-items": true,
  });
  const enhancedErrorClasses = classNames({
    "CustomRadioGroup-error-message": true,
    [labelClasses || ""]: labelClasses,
    error,
  });

  //#endregion

  const RadioButtons = () => {
    return items.map((props: CustomRadioButtonProps) => {
      return (
        <CustomRadioButton
          key={props.value}
          {...props}
          selected={props.value === value}
          disabled={props.disabled}
          classes={{ root: "CustomRadioGroup-item" }}
        />
      );
    });
  };

  return (
    <FormControl className={enhancedRootClasses}>
      {label && <div className={enhancedLabelClasses}>{label}</div>}
      <RadioGroup classes={{ root: enhancedGroupClasses }} value={value} onChange={(_, value) => onChange(value)}>
        <div className={enhancedGroupItemsClasses}>{RadioButtons()}</div>
      </RadioGroup>
      {error && <div className={enhancedErrorClasses}>{errorMessage}</div>}
    </FormControl>
  );
};
