import classNames from "classnames";
import { AvailableLanguages } from "../../../../Core/Models/Languages/Languages";
import { Languages } from "../../../../Core/Models/Languages/Languages";
import { Messages } from "../../../../Core/Services/Data/I18n/typings/LocalMessageActionTypes";
import "./SelectLanguage.scss";

export type SelectLanguageProps = {
  changeCurrentLanguage: (l: Languages) => void;
  currentLanguage: Languages;
  errors?: Messages;
};

const SelectLanguage = ({ changeCurrentLanguage, currentLanguage, errors }: SelectLanguageProps) => {
  return (
    <div className="SelectLanguageOptions_Container">
      {AvailableLanguages.map((el, i) => (
        <p
          key={el + i}
          onClick={() => {
            changeCurrentLanguage(el as Languages);
          }}
          className={classNames("SelectLanguageOptions_Option", errors?.[el] ? "errors" : "")}
          style={currentLanguage === el ? { fontWeight: 800 } : {}}>
          {el}
        </p>
      ))}
    </div>
  );
};

export { SelectLanguage };
