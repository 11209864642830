import React from "react";
import styled, { css } from "styled-components";

import "./RTELinkInput.scss";

export interface RTELinkInputProps {
  placeholder?: string;
  label?: string;
  onChange: (event: string) => void;
  value: string;
  errorMessage?: any;
  disabled?: boolean;
}

export const RTELinkInput = ({
  placeholder = "",
  label,
  onChange,
  value,
  errorMessage,
  disabled = false,
}: RTELinkInputProps) => {
  return (
    <div className="RTELinkInput-root">
      {label && <InputLabel>{label}</InputLabel>}
      <div className="RTELinkInput-input">
        <StandardInput
          onChange={(event: { target: { value: string; }; }) => onChange(event.target.value)}
          value={value}
          maxLength={100}
          placeholder={placeholder}
          disabled={disabled}
          errorMessage={errorMessage}
        />
      </div>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </div>
  );
};

const InputLabel = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.inputTextTextColor};
  margin-bottom: 4px;
  height: 16px;
`;

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.inputTextErrorColor};
  height: 0px;
`;

const StandardInput = styled.input<{ errorMessage: any }>`
  width: 100%;
  height: 100%;
  font-size: 18px;
  transition: all 200ms;
  color: ${(props) => props.theme.inputTextTextColor};
  font-family: Roboto, "Roboto", sans-serif;
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.inputTextStandardBorderColor};
  height: 34px;
  &:focus {
    outline: none;
    border-bottom: 1px solid ${(props) => props.theme.inputTextStandardBorderColorFocus};
  }
  ${({ errorMessage }) =>
    errorMessage &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.inputTextErrorColor};
    `}
`;
