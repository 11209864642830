import Pagination from "@material-ui/lab/Pagination";
import React from "react";
import { renderPaginationItem } from "./paginationUtils";

export interface PaginationComponentProps {
  page: number;
  onPageChange?: (page: number) => void;
  pageCount: number;
}

const PaginationComponent = ({ page, pageCount, onPageChange }: PaginationComponentProps) => {
  const onChange = (newPage: number) => {
    if (onPageChange) {
      onPageChange(newPage);
    }
  };
  return (
    <Pagination
      count={pageCount}
      siblingCount={1}
      boundaryCount={1}
      page={page}
      renderItem={(params) => renderPaginationItem({ params, pageCount, onChange, page })}
    />
  );
};

export { PaginationComponent as CustomPagination };
