import { CustomHttpErrors, ErrorHandler } from "../../Libs/xhr/ErrorHandler";
import { HttpStatusCode } from "../../Libs/xhr/HttpStatusCode";
import { Debugger } from "../Debugger";
import { Lazy } from "../Helpers/Lazy";
import { AuthServiceName, IAuthService } from "../Services/Data/Authentication/AuthenticationService";
import { II18nService } from "../Services/Data/I18n/I18nService";
import { getToastService, IToastService } from "../Services/Interfaces/IToastService";
import { ILogService } from "../Services/LogService";
import { IoC } from "../Services/ServicesContainer";

export class CustomErrorHandler extends ErrorHandler {
  //* Services
  private readonly i18nService: II18nService;
  private readonly logService: ILogService;
  private readonly authService: Lazy<IAuthService>;

  constructor(i18nService: II18nService, logService: ILogService) {
    super();
    this.i18nService = i18nService;
    this.logService = logService;
    this.authService = IoC.getLazy<IAuthService>(AuthServiceName);
  }

  public handle = async (error: any): Promise<boolean> => {
    const toastService = getToastService();

    //If we throw the response we want a generic error.
    if (error.is_success !== undefined && !error.is_success) {
      this.showGenericError(error.error_code, toastService);
      return Promise.resolve(this.ERROR_HANDLED);
    }

    const customHttpErrors = ErrorHandler.getCustomHttpErrors(error);

    const {
      isApiError,
      httpStatusCode,
      errorCode,
      //errorMessage,
      checkError,
    }: CustomHttpErrors = customHttpErrors;

    const dataError = {
      log_level: "error",
      timestamp: new Date(),
      context: { userAgent: navigator.userAgent, location: window.location },
      error,
    };

    if (httpStatusCode === HttpStatusCode.Unauthorized) {
      //* soft logout user : remove authentication data
      //* then redirect to login page
      this.LogError("🔥🔥🔥🔥🔥 | ErrorHandler Unauthorized", customHttpErrors, dataError);
    }

    if (httpStatusCode === HttpStatusCode.Forbidden) {
      this.LogError("🔥🔥🔥🔥🔥 | ErrorHandler Forbidden", customHttpErrors, dataError);

      await this.authService.value().logout("from Forbidden request");
      toastService.value().showError(this.i18nService.getString("Error_FORBIDDEN"));
      return Promise.resolve(this.ERROR_HANDLED);
    }

    if (httpStatusCode === HttpStatusCode.BadRequest) {
      this.LogError("🔥🔥🔥🔥🔥 | ErrorHandler BadRequest", customHttpErrors, dataError);
    }

    if (isApiError && checkError) {
      //* let message = 'An error occurred with the server';
      this.LogError("🔥🔥🔥🔥🔥 | ErrorHandler  Api", customHttpErrors, dataError);
      this.showGenericError(errorCode, toastService);
    }

    return Promise.resolve(this.ERROR_HANDLED);
  };

  private LogError = (logMessage: string, customHttpErrors: CustomHttpErrors, dataError: any) => {
    Debugger.LogValue(`${logMessage}`, { ...customHttpErrors, dataError });
  };

  private showGenericError(errorCode: string, toastService: Lazy<IToastService>) {
    const message =
      this.i18nService.getString(`Error_${errorCode}`) ||
      //errorMessage || //* can be generic error so not usable
      this.i18nService.getString(`Error_GenericError`) ||
      //* default message in FR 🇫🇷
      "Quelque chose ne s'est pas passé comme prévu, merci de réessayer.";

    toastService.value().showError(message);
  }
}
