import { XHRRequestCanceler } from "../../Libs/xhr/XHRRequestCanceler";
import { BaseApiDataResponse } from "../Models/ApiData";
import { ApiUrls } from "../Models/ApiUrls";
import {
  ArtWorkCreateResponse,
  ArtWorkDetailsResponse,
  ArtWorkListResponse,
  ArtWorkSectionsResponse,
  CreateOrUpdateArtworkRequest,
} from "../Models/ArtWork/ArtWork";
import { GetPaginatedQueryParameter } from "../Models/Commons/PaginatedQuery";
import { BaseDataService } from "./Base/BaseDataService";

export const ArtWorkDataServiceName: string = "ArtWorkDataService";

export interface IArtWorkDataService {
  getArtWorks: (parameters: GetPaginatedQueryParameter, cts: XHRRequestCanceler) => Promise<ArtWorkListResponse>;

  getArtWork: (id: string, cts: XHRRequestCanceler) => Promise<ArtWorkDetailsResponse>;

  publishArtWork: (id: string, cts: XHRRequestCanceler) => Promise<BaseApiDataResponse>;

  cancelPublishArtWork: (id: string, cts: XHRRequestCanceler) => Promise<BaseApiDataResponse>;

  getSections: (cts: XHRRequestCanceler) => Promise<ArtWorkSectionsResponse>;

  createArtWork: (data: CreateOrUpdateArtworkRequest, cts: XHRRequestCanceler) => Promise<ArtWorkCreateResponse>;

  updateArtWork: (
    id: string,
    data: CreateOrUpdateArtworkRequest,
    cts: XHRRequestCanceler
  ) => Promise<ArtWorkCreateResponse>;
}
export class ArtWorkDataService extends BaseDataService implements IArtWorkDataService {
  public getArtWorks(parameters: GetPaginatedQueryParameter, cts: XHRRequestCanceler): Promise<ArtWorkListResponse> {
    const params = {
      ...parameters,
    };

    return this.get<ArtWorkListResponse>(ApiUrls.ARTWORK_LIST, cts, { params });
  }

  public createArtWork(data: CreateOrUpdateArtworkRequest, cts: XHRRequestCanceler): Promise<ArtWorkCreateResponse> {
    return this.post<ArtWorkCreateResponse>(ApiUrls.ARTWORK_CREATE, data, cts);
  }

  public updateArtWork(
    id: string,
    data: CreateOrUpdateArtworkRequest,
    cts: XHRRequestCanceler
  ): Promise<ArtWorkCreateResponse> {
    return this.put<ArtWorkCreateResponse>(ApiUrls.ARTWORK(id), data, cts);
  }

  public getArtWork(id: string, cts: XHRRequestCanceler): Promise<ArtWorkDetailsResponse> {
    return this.get<ArtWorkDetailsResponse>(ApiUrls.ARTWORK(id), cts);
  }

  public publishArtWork(id: string, cts: XHRRequestCanceler): Promise<BaseApiDataResponse> {
    return this.patch<BaseApiDataResponse>(ApiUrls.ARTWORK(id), cts);
  }

  public cancelPublishArtWork(id: string, cts: XHRRequestCanceler): Promise<BaseApiDataResponse> {
    return this.delete<BaseApiDataResponse>(ApiUrls.ARTWORK(id), cts);
  }

  public getSections(cts: XHRRequestCanceler): Promise<ArtWorkSectionsResponse> {
    return this.get<ArtWorkSectionsResponse>(ApiUrls.ARTWORK_SECTIONS, cts);
  }
}
