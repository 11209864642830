import React from 'react';
import classNames from 'classnames';
import './Button.scss';

export interface ButtonProps {
    className?: string;
    active?: boolean;
    reversed?: boolean;
    onMouseOver?: (e: React.MouseEvent) => void;
    onMouseOut?: (e: React.MouseEvent) => void;
    onMouseDown?: (e: React.MouseEvent) => void;
    onClick?: (e: any) => void;
    children?: React.ReactNode;
}

export const Button = React.forwardRef<HTMLDivElement, ButtonProps>(
    ({ className, active, reversed, ...props }: ButtonProps, ref: React.Ref<HTMLDivElement>) => (
        <div
            {...props}
            ref={ref}
            className={classNames('editor-button', className, {
                active,
                reversed,
            })}
        />
    )
);