import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import './progressUpload.scss';

export interface ProgressUploadProps {
    readonly progress?: number;
    readonly cancelLabel?: React.ReactNode;
    readonly abort: () => void;
    readonly indeterminate?: boolean;
}

export const ProgressUpload = ({
    progress,
    abort = () => {},
    cancelLabel,
    indeterminate = false,
}: ProgressUploadProps) => {
    return (
        <div className="progress-upload-container">
            {!indeterminate && <LinearProgress variant="determinate" value={progress} style={{ width: '100%' }} />}
            {indeterminate && <LinearProgress variant="indeterminate" style={{ width: '100%' }} />}
            <div className="cancel-label" onClick={abort}>
                {cancelLabel}
            </div>
        </div>
    );
};
