import { MediaType } from "../../../Web/Components/Views/MediaInput/MediaInput";
import { FileInputValue } from "../../Models/Files/File";
import { Languages } from "../../Models/Languages/Languages";
import { NewsDataResponse } from "../../Models/News/News";
import { NewsDetailsViewState, NewsDetailsContent, DefaultLocalizedContent } from "./NewsDetailsViewModel";

export function newsDataResponseToNewsDetailsViewState(
  response: NewsDataResponse,
  existingState: NewsDetailsViewState
): NewsDetailsViewState {
  // Make sure that the received object is complet without any undefined value
  const apiLocalizedContent = response.data.localized_content;
  let localized_content: Record<Languages, NewsDetailsContent> = {
    fr: DefaultLocalizedContent,
    nl: DefaultLocalizedContent,
  };

  for (let lang of Object.keys(apiLocalizedContent) as Languages[]) {
    const currentContent = apiLocalizedContent[lang];
    const picture: FileInputValue[] = [];
    if (currentContent.picture_url) {
      picture.push({ id: currentContent.picture_id, type: MediaType.image, url: currentContent.picture_url });
    }

    localized_content[lang] = {
      ...DefaultLocalizedContent,
      ...currentContent,
      localPicture: picture,
    };
  }

  return {
    ...existingState,
    ...response.data,
    localized_content: localized_content,
  };
}
