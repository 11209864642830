import { CancelTokenSource } from "axios";
import { useIntl } from "react-intl";
import { Constants } from "../Core/Constants";
import { IMediaDataService, MediaDataServiceName } from "../Core/DataServices/MediaDataService";
import { IoC } from "../Core/Services/ServicesContainer";
import {
  ImageMedia,
  RTEContext,
  RTEContextValue,
  RTEStyleType,
} from "../Libs/ideine-web-richtext/Context/RichtextConfig";

export const cpubRteConfig: RTEContextValue = {
  linkTargetLabel: "editor.link.input.target.label",
  linkTargetPlaceholder: "editor.link.input.target.placeholder",
  linkTitleLabel: "editor.link.input.title.label",
  linkTitlePlaceholder: "editor.link.input.title.placeholder",
  linkErrorMessageNotUrl: "ValidatorsUtils.isNotUrl",
  linkErrorMessageRequired: "ValidatorsUtils.isRequired",
  imageDropzoneTitle: "editor.image.dropzone.title",
  imageDropzoneLabel: "editor.image.dropzone.label",
  imageDropzoneCancelLabel: "editor.image.dropzone.cancel.button.label",
  imageDropzoneLoadingMessage: "editor.image.dropzone.loading.message",
  rteButtonAddLabel: "editor.button.add.label",
  rteButtonCancelLabel: "editor.button.cancel.label",
};

const Colors = {
  tango: "#ee7624",
  alizarin_crimson: "#e22926",
  abbey: "#565a5e",
  purple: "rebeccapurple",

  black: "#000000",
  black_44: "#444444",
  black_e4: "#e4e4e4",
  white: "#ffffff",
};

const RTEStyle: RTEStyleType = {
  editorDescriptionPadding: '14px 16px',

  popupButtonHoverColor: Colors.purple,

  buttonStandardTextColor: Colors.white,
  buttonStandardBackgroundColor: Colors.tango,
  buttonStandardBackgroundHoverColor: Colors.tango + "70",

  buttonDangerBackgroundColor: Colors.white,
  buttonDangerTextColor: Colors.alizarin_crimson,
  buttonDangerBorderColor: Colors.alizarin_crimson,
  buttonDangerBackgroundHoverColor: Colors.alizarin_crimson + "25",

  inputTextTextColor: Colors.abbey,
  inputTextErrorColor: Colors.alizarin_crimson,

  inputTextStandardBorderColor: Colors.black_e4,
  inputTextStandardBorderColorFocus: Colors.black_44,

  videoListPageItemBackgroundColor: Colors.tango,
  videoListPageItemTextColor: Colors.white,
  videoListPageItemInProgressBackgroundColor: Colors.tango,
  videoListPageProgressBarThumb: Colors.tango,

  customButtonWidth: "225px",
  customButtonHeight: "54px",
};

export type RTEProviderProps = {
  value: RTEContextValue;
};

export const RTEProvider = ({ children, value }: React.PropsWithChildren<RTEProviderProps>) => {
  const i18n = useIntl();
  return (
    <RTEContext.Provider
      value={{
        linkTargetLabel: i18n.formatMessage({ id: value.linkTargetLabel }),
        linkTargetPlaceholder: i18n.formatMessage({ id: value.linkTargetPlaceholder }),
        linkTitleLabel: i18n.formatMessage({ id: value.linkTitleLabel }),
        linkTitlePlaceholder: i18n.formatMessage({ id: value.linkTitlePlaceholder }),
        linkErrorMessageNotUrl: i18n.formatMessage({ id: value.linkErrorMessageNotUrl }),
        linkErrorMessageRequired: i18n.formatMessage({ id: value.linkErrorMessageRequired }),
        imageDropzoneTitle: i18n.formatMessage({ id: value.imageDropzoneTitle }),
        imageDropzoneLabel: i18n.formatMessage({ id: value.imageDropzoneLabel }),
        imageDropzoneCancelLabel: i18n.formatMessage({ id: value.imageDropzoneCancelLabel }),
        imageDropzoneLoadingMessage: i18n.formatMessage({ id: value.imageDropzoneLoadingMessage }),
        rteButtonAddLabel: i18n.formatMessage({ id: value.rteButtonAddLabel }),
        rteButtonCancelLabel: i18n.formatMessage({ id: value.rteButtonCancelLabel }),
        rteStyle: RTEStyle,
        isDev: Constants.isDev,
        getUrl: (imageMedia?: ImageMedia) => imageMedia?.url || "",
        onImageUpload: onImageUpload,
      }}>
      {children}
    </RTEContext.Provider>
  );
};

const onImageUpload = async (
  file: File,
  onProgress?: (value: number) => void,
  cts?: CancelTokenSource
): Promise<ImageMedia> => {
  const mediaService = IoC.getLazy<IMediaDataService>(MediaDataServiceName);
  const response = await mediaService.value().uploadImage(file, onProgress, cts);
  return response.data;
};
