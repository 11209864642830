import React from "react";
import { useSelected, useFocused, useSlateStatic } from "slate-react";
import ArrowIcon from "@material-ui/icons/Publish";
import DeleteIcon from "@material-ui/icons/Delete";
import styled from "styled-components";
import "./Image.scss";
import { useRTEConfig } from "../../Context/RichtextConfig";

export const Image = ({ attributes, element, children }: any) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();
  const RTEConfig = useRTEConfig();

  return (
    <div
      {...attributes}
      className="editor-image-root">
      <div
        contentEditable={false}
        style={{
          position: "relative",
        }}>
        <img alt={element.alt} src={RTEConfig.getUrl ? RTEConfig.getUrl({id: "", url: element.src}) : element.src} />
        {selected && focused && (
          <PopupElement className="popup" contentEditable={false}>
            <button
              onMouseDown={(e) => {
                e.preventDefault();
                editor.insertSoftBreak();
              }}>
              <ArrowIcon />
            </button>
            <button
              onMouseDown={(e) => {
                e.preventDefault();
                editor.insertBreak();
              }}>
              <ArrowIcon style={{ transform: "rotate(180deg)" }} />
            </button>
            <button
              onMouseDown={(e) => {
                e.preventDefault();
                editor.deleteBackward("block");
              }}>
              <DeleteIcon />
            </button>
          </PopupElement>
        )}
      </div>
      {children}
    </div>
  );
};

export const PopupElement = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 6px 10px;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid lightgray;
  button {
    border: none;
    background: transparent;
    &:not(:last-child) {
      padding-right: 15px;
      border-right: 1px solid lightgrey;
    }
  }
  button:hover {
    color: ${(props) => props.theme.popupButtonHoverColor};
    cursor: pointer;
  }
}
`;
