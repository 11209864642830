import { BaseDataService } from "../Core/DataServices/Base/BaseDataService";
import { ApiDataResponse } from "../Core/Models/ApiData";

export const dataService = new BaseDataService();

const fetcherBase = async <T extends any = any> (url: string, postBody?: any): Promise<ApiDataResponse<T>> => {
    const res = await (async () => {
        if (postBody) {
            return await dataService.post<ApiDataResponse<T>>(url, postBody);
        }
        return await dataService.get<ApiDataResponse<T>>(url);
    })();

    if (!res.is_success) {
        throw res.data;
    }

    return res;
};

export const fetcherWithToastOnError = <T extends any = any> (url: string, postBody?: any) =>
    fetcherBase<T>(url, postBody);
export const fetcherWithoutToastOnError = <T extends any = any> (url: string, postBody?: any) =>
    fetcherBase<T>(url, postBody);
