import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { ExhibitionDataServiceName, IExhibitionDataService } from "../../DataServices/ExhibitionDataService";
import { IMediaDataService, MediaDataServiceName } from "../../DataServices/MediaDataService";
import { ExhibitionContent } from "../../Models/Exhibition/Exhibition";
import { ExhibitionStatus } from "../../Models/Exhibition/ExhibitionStatus";
import { Languages } from "../../Models/Languages/Languages";
import { Messages } from "../../Services/Data/I18n/typings/LocalMessageActionTypes";
import { IoC } from "../../Services/ServicesContainer";
import { DateUtils } from "../../Utils/DateUtils";
import { MessageUtils } from "../../Utils/MessageUtils";
import { ValidatorsUtils } from "../../Utils/ValidatorsUtils";
import { BaseEditOrCreateViewModel } from "../Base/BaseEditOrCreateViewModel";
import { BaseViewState } from "../Base/BaseViewModel";
import { NewsDetailsContent } from "../News/NewsDetailsViewModel";
import { addPrefixToObject } from "./addPrefixToObject";
import { exhibitionDataResponseToViewState } from "./exhibitionDataResponseToViewState";
import { exhibitionStateToCreateOrUpdateRequest } from "./exhibitionStateToCreateOrUpdateRequest";
import {
  LocalizedContentWithImageState,
  uploadLocalizedContentPictures,
  uploadLocalizedContentPicturesBase,
} from "./uploadLocalizedContentPictures";
import { validateLocalizedContent } from "./validateLocalizedContent";

export type ExhibitionLocalizedContentViewState = ExhibitionContent &
  LocalizedContentWithImageState &
  addPrefixToObject<NewsDetailsContent, "news_">;

export interface ExhibitionsDetailsViewState extends BaseViewState {
  localized_content: Record<Languages, ExhibitionLocalizedContentViewState>;

  currentLanguage: Languages;
  id: string | undefined;
  is_main_section: boolean;
  is_published: boolean;
  published_date: string | undefined;
  order: number | string;

  fieldsErrors: Messages;
  invalidCredentials: boolean;
  mode: "create" | "edit";
}

export const DefaultLanguageContent: ExhibitionLocalizedContentViewState = {
  description: "",
  name: "",
  picture_id: "",
  picture_url: "",
  localPicture: [],

  news_title: "",
  news_content: "",
  news_picture_id: "",
  news_picture_url: "",
  news_localPicture: [],
};

const InitialLocalizedContent = { fr: DefaultLanguageContent, nl: DefaultLanguageContent };

export const InitialExhibitionsDetailsViewState: ExhibitionsDetailsViewState = {
  localized_content: InitialLocalizedContent,
  currentLanguage: "fr",
  id: undefined,
  is_main_section: false,
  is_published: false,
  published_date: undefined,
  order: "",

  fieldsErrors: [],
  invalidCredentials: false,
  mode: "create",
};

export class ExhibitionsDetailsViewModel extends BaseEditOrCreateViewModel<ExhibitionsDetailsViewState> {
  private readonly exhibitionDataService: IExhibitionDataService;
  protected readonly mediaService: IMediaDataService;

  constructor() {
    super(InitialExhibitionsDetailsViewState);
    this.exhibitionDataService = IoC.get<IExhibitionDataService>(ExhibitionDataServiceName);
    this.mediaService = IoC.get<IMediaDataService>(MediaDataServiceName);
  }

  public onChangeLocalizedField = (field: any, value: any) => {
    const fieldsErrors = { ...this.state.fieldsErrors };
    delete fieldsErrors["localized_content"]?.[this.state.currentLanguage]?.[field];

    this.setState({
      ...this.state,
      localized_content: {
        ...this.state.localized_content,
        [this.state.currentLanguage]: {
          ...this.state.localized_content[this.state.currentLanguage],
          [field]: value,
        },
      },
      modified: true,
      fieldsErrors,
    });
  };

  public changeCurrentLanguage = (value: Languages) => {
    this.setState({
      ...this.state,
      currentLanguage: value,
    });
  };

  public getEditItemState = async (itemId: string, cts: XHRRequestCanceler) => {
    const response = await this.exhibitionDataService.getExhibition(itemId, cts);

    return {
      ...exhibitionDataResponseToViewState(response, this.state),
    };
  };

  public getExhibitionStatus = () => {
    if (this.state.is_published) {
      return this.i18n.value().formatMessage(`ExhibitionDetailsPage_StatusPublished`, {
        date: this.state.published_date ? DateUtils.formatDateNormal(new Date(this.state.published_date)) : "",
      });
    } else if (this.state.published_date) {
      return this.i18n.value().formatMessage(`ExhibitionDetailsPage_StatusCancelled`, {
        date: this.state.published_date ? DateUtils.formatDateNormal(new Date(this.state.published_date)) : "",
      });
    }
    return this.i18n.value().getString(`ExhibitionDetailsPage_StatusBrouillon`);
  };

  protected validateFields = () => {
    const errors: Messages = {};
    const requiredFieldError = this.getString("COMMON_REQUIRED_FIELD");

    errors.localized_content = validateLocalizedContent(this.state.localized_content, (content) => {
      const locErrors: Messages = {};
      const { name } = content;
      ValidatorsUtils.checkRequired(name, locErrors, "name", requiredFieldError);

      if (content.news_title !== "" || content.news_content !== "") {
        ValidatorsUtils.checkRequired(content.news_title, locErrors, "news_title", requiredFieldError);
        ValidatorsUtils.checkRequired(content.news_content, locErrors, "news_content", requiredFieldError);
      }

      return {
        isEmpty:
          content.name === "" &&
          content.description === "" &&
          content.localPicture === undefined &&
          content.news_title === "" &&
          content.news_content === "" &&
          content.news_localPicture === undefined,
        errors: locErrors,
      };
    });

    this.setState({ ...this.state, fieldsErrors: errors });
    return MessageUtils.isEmpty(errors);
  };

  protected save = async (cts: XHRRequestCanceler) => {
    const updatedContent = await uploadLocalizedContentPictures(this.mediaService, this.state.localized_content, cts);
    this.setState({ ...this.state, localized_content: updatedContent });

    const uploadNewsContent = await uploadLocalizedContentPicturesBase(
      this.mediaService,
      this.state.localized_content,
      (c) => (c.news_localPicture.length !== 0 ? c.news_localPicture[0] : undefined),
      (c, pId) => {
        c.news_picture_id = pId;
        return c;
      },
      cts
    );
    this.setState({ ...this.state, localized_content: uploadNewsContent });

    if (this.state.mode === "create") {
      this.setState({ ...this.state, modified: false });
      const response = await this.exhibitionDataService.createExhibition(
        exhibitionStateToCreateOrUpdateRequest(this.state),
        cts
      );
      if (response.is_success) {
        this.toastService.value().showSuccess("Catégorie crée");
        const newState: ExhibitionsDetailsViewState = await this.getEditItemState(response.data.id, cts);
        this.setState({ ...newState });
        this.navigationService.value().goToExhibitionsDetails(response.data.id);
      } else {
        this.toastService.value().showError("Erreur lors de la création de la catégorie");
      }
    } else if (this.state.mode === "edit") {
      this.setState({ ...this.state, modified: false });
      const response = await this.exhibitionDataService.updateExhibition(
        this.state.id!,
        exhibitionStateToCreateOrUpdateRequest(this.state),
        cts
      );
      if (response.is_success) {
        this.toastService.value().showSuccessUpdate();
        const newState: ExhibitionsDetailsViewState = await this.getEditItemState(this.state.id || "", cts);
        this.setState({ ...newState });
        this.navigationService.value().goToExhibitionsList();
      } else {
        this.toastService.value().showError("Erreur lors de la modification de l'œuvre");
      }
    }
  };

  public onPublish = () => {
    this.WrapError(
      async (cts: XHRRequestCanceler) => {
        const response = await this.exhibitionDataService.publish(this.state.id!, cts);
        const newState: ExhibitionsDetailsViewState = await this.getEditItemState(this.state.id!, cts);
        if (response.is_success) {
          this.toastService.value().showSuccess(this.i18n.value().getString("ExhibitionDetailsPage_PublishSuccess"));
          this.setState({ ...newState });
        } else {
          this.toastService.value().showError(this.i18n.value().getString("ExhibitionDetailsPage_PublishError"));
          throw "Error";
        }
      },
      { withBusy: true }
    );
  };

  public onCancelPublish = () => {
    this.WrapError(
      async (cts: XHRRequestCanceler) => {
        await this.exhibitionDataService.cancelPublish(this.state.id!, cts);
        const newState: ExhibitionsDetailsViewState = await this.getEditItemState(this.state.id!, cts);
        this.toastService.value().showSuccess(this.i18n.value().getString("ExhibitionDetailsPage_UnpublishSuccess"));
        this.setState({ ...newState });
      },
      { withBusy: true }
    );
  };

  public onChangeStatus = (value: ExhibitionStatus | undefined) => {
    if (value === ExhibitionStatus.Main) {
      this.onChangeField("is_main_section", true);
    } else if (value === ExhibitionStatus.Others) {
      this.onChangeField("is_main_section", false);
    }
  };
}
