import React, { useState } from 'react';
import IconLink from '@material-ui/icons/Link';
import IconLinkOff from '@material-ui/icons/LinkOff';
import Popover from '@material-ui/core/Popover';
import { useSlate } from 'slate-react';
import { Button } from '../../MarkButtons/Button/Button';
import { LinkChoice } from '../LinkChoice/LinkChoice';
import { HasLinks, removeLink } from '../../../Utils/LinkHandler';

export const LinkButton = () => {
    const editor = useSlate();
    const [open, setOpen] = useState(false);
    const anchorEl = React.useRef<HTMLDivElement | null>(null);
    const hasLinks = HasLinks();

    const handleClick = (e: any) => {
        if (e) {
            e.preventDefault();
        }
        if (hasLinks) {
            removeLink(editor);
        } else {
            setOpen(true);
        }
    };

    return (
        <>
            <Button
                ref={(node) => {
                    anchorEl.current = node;
                }}
                active={hasLinks}
                onClick={handleClick}
            >
                {!hasLinks && <IconLink />}
                {hasLinks && <IconLinkOff />}
            </Button>

            <Popover
                open={open}
                anchorEl={anchorEl.current}
                anchorReference="anchorEl"
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <LinkChoice editor={editor} handleClose={() => setOpen(false)} />
            </Popover>
        </>
    );
};
