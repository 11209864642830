import classNames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { XRichText } from "../../../../../Libs/ideine-web-richtext";
import { CustomButton, CustomButtonClassType } from "../../../../Components/Views/CustomButton/CustomButton";
import { CustomCard } from "../../../../Components/Views/CustomCard/CustomCard";
import { Icons } from "../../../../Components/Views/Icons/Icons";
import { ArtWorkInformationTabControllerData } from "../ArtWorkInformationTab/ArtWorkInformationTabController";
import "./ArtWorkDescriptionTab.scss";

export interface ArtWorkDescriptionTabProps {
  artworkController: ArtWorkInformationTabControllerData;
}

const ArtWorkDescriptionTab = ({ artworkController }: ArtWorkDescriptionTabProps) => {
  const { vm, state } = artworkController;
  const i18n = useIntl();

  return (
    <div className="ArtWorkDescriptionTab-root">
      <CustomCard
        titleIntlId="ArtWorkDescriptionTab_Description"
        classes={{
          root: "ArtWorkDescriptionTab-artworkDescriptionRoot",
          body: "ArtWorkDescriptionTab-artworkDescriptionBody",
        }}>
          <XRichText
            value={state.localized_content[state.currentLanguage].description}
            updateState={vm.onEditorStateChange}
            placeholder={i18n.formatMessage({ id: "editor.placeholder" })}
            videoList={state.videoList}
            busyLoadingVideos={state.busyLoadingVideos}
            currentLanguage={state.currentLanguage}
          />
      </CustomCard>
      <CustomCard
        titleIntlId="ArtWorkInformationTab_Publication"
        classes={{ root: "ArtWorkDescriptionTab-publicationRoot", body: "ArtWorkDescriptionTab-publicationBody" }}
        renderHeader={
          <div className="ArtWorkDescriptionTab-publicationTab-header-root">
            <div className="ArtWorkDescriptionTab-publicationTab-header-title">
              <FormattedMessage id="ArtWorkInformationTab_Publication" />
            </div>
            <div
              className={classNames({
                "ArtWorkDescriptionTab-publicationTab-header-statusColor": true,
                [vm.getArtWorkStatus()]: true,
              })}
            />
            <div className="ArtWorkDescriptionTab-publicationTab-header-status">{vm.getArtWorkStatus()}</div>
          </div>
        }>
        {state.mode === "edit" && (
          <CustomButton
            variant={CustomButtonClassType.Standard}
            title={<FormattedMessage id="ArtWorkInformationTab_PublishTitle" />}
            className="ArtWorkDescriptionTab-publishButton"
            onClick={vm.onPublish}
            disabled={state.is_published}
          />
        )}
        {state.mode === "edit" && (
          <CustomButton
            variant={CustomButtonClassType.Outlined}
            title={<FormattedMessage id="ArtWorkInformationTab_CancelPublishTitle" />}
            className="ArtWorkDescriptionTab-cancelButton"
            onClick={vm.onCancelPublish}
            disabled={!state.is_published}
          />
        )}
        <CustomButton
          variant={CustomButtonClassType.Success}
          title={<FormattedMessage id="ArtWorkInformationTab_SaveTitle" />}
          className="ArtWorkDescriptionTab-saveButton"
          onClick={vm.onSubmit}
          icon={Icons.IconSave}
        />
      </CustomCard>
    </div>
  );
};

export { ArtWorkDescriptionTab };
