import './CustomRadioButton.scss';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import classNames from 'classnames';
import React from 'react';

import { Icons } from '../../Icons/Icons';

export interface CustomRadioButtonProps {
    label: React.ReactNode;
    value: string;
    selected?: boolean;
    disabled?: boolean;
    classes?: { root?: string };
}

export const CustomRadioButton: React.FunctionComponent<CustomRadioButtonProps> = ({
    label,
    value,
    selected,
    disabled,
    classes,
}: CustomRadioButtonProps) => {
    const { root: rootClasses } = classes ?? { root: '' };

    const enhancedRootClasses = classNames({
        'CustomRadioButton-root': true,
        [rootClasses || '']: rootClasses,
    });

    const enhancedLabelClasses = classNames({
        'CustomRadioButton-label': true,
        'CustomRadioButton-label-checked': selected && !disabled,
    });
    return (
        <FormControlLabel
            label={label}
            value={value}
            classes={{ root: enhancedRootClasses, label: enhancedLabelClasses }}
            control={
                <Radio
                    classes={{
                        root: 'CustomRadioButton-radio-root',
                        checked: 'CustomRadioButton-radio-checked',
                        disabled: 'CustomRadioButton-radio-disabled'
                    }}
                    icon={Icons.IconRadioNormal}
                    checkedIcon={Icons.IconRadioChecked}
                    disabled={disabled}
                />
            }
        />
    );
};
