export enum AppRoutes {
  //* Auth
  LOGIN = "/login",
  FORGOT_PASSWORD = "/forgot/password",
  RESET_PASSWORD = "/passwords/reset/:email/:code",
  GET_APP = "/get/app",

  //* Art work
  ART_WORK_LIST = "/artwork",
  ART_WORK_CREATE = "/artwork/create",
  ART_WORK_DETAILS = "/artwork/:id",

  //* Exhibitions
  EXHIBITIONS_LIST = "/exhibitions",
  EXHIBITIONS_CREATE = "/exhibitions/create",
  EXHIBITIONS_DETAILS = "/exhibitions/:id",

  //* Videos
  VIDEO_LIST = "/video",
  VIDEO_CREATE = "/video/create",
  VIDEO_DETAILS = "/video/:id",

  NEWS_DETAILS = "/news",

  BO_USER_LIST = "/users",
  BO_USER_DETAILS = "/users/:id",
  BO_USER_CREATE = "/users/create",
}
