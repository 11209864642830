import { ButtonBase } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { FileInputValue } from "../../../../Core/Models/Files/File";
import { Icons } from "../Icons/Icons";
import { MediaType } from "./MediaInput";

interface FileInputItemBaseProps {
  key?: React.Key;
  accept: string;
  disabled?: boolean;
}

export interface AddNewFileButtonBaseProps extends FileInputItemBaseProps {
  onNewFiles: (files: File[]) => void;
  isDocument?: boolean;
  children?: React.ReactNode;
  buttonTitle: React.ReactNode;
}

export type AddNewFileButtonProps = Omit<AddNewFileButtonBaseProps, "children">;

export interface FileInputItemProps extends FileInputItemBaseProps {
  value: FileInputValue;
  resolveUrl: (media: FileInputValue) => string;
  isBusy?: boolean;
  onNewFile: (file: File) => void;
  onDelete: () => void;
  type: MediaType;
}

export const AddNewFileButtonBase = ({
  accept,
  onNewFiles,
  disabled,
  children,
  isDocument,
  buttonTitle,
}: AddNewFileButtonBaseProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    disabled: disabled,
    accept: accept,
    onDrop: (files) => {
      if (files.length > 0) {
        onNewFiles(files);
      }
    },
  });

  if (disabled) {
    return null;
  }

  if (isDocument) {
    return (
      <ButtonBase {...getRootProps()} disabled={disabled} className="add-document-button">
        {Icons.IconImport}
        {buttonTitle}
        <input {...getInputProps()} />
      </ButtonBase>
    );
  } else {
    return (
      <ButtonBase {...getRootProps()} className="add-file-button" disabled={disabled}>
        <input {...getInputProps()} />
        {children}
      </ButtonBase>
    );
  }
};
