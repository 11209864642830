import { persistor, store } from "./Configs/store/store";
import { CoreBootstrapper } from "./Core/CoreBootstrapper";
import { DialogServiceName, IDialogService } from "./Core/Services/Interfaces/IDialogService";
import { INavigationService, NavigationServiceName } from "./Core/Services/Interfaces/INavigationService";
import { IToastService, ToastServiceName } from "./Core/Services/Interfaces/IToastService";
import { IoC } from "./Core/Services/ServicesContainer";
import { AppRouteType } from "./Web/Components/Templates/AppRouter/AppRouteType";
import { DEFAULT_ROOT, getAppRoutes } from "./Web/Routers/AppRoutes";
import { DialogService } from "./Web/Services/DialogService";
import { NavigationService } from "./Web/Services/NavigationService";
import { ToastService } from "./Web/Services/ToastService";

export interface BootstrapperInitialized {
  store: any;
  persistor: any;
  defaultRoot: string;
  routes: Array<AppRouteType>;

  mountActions: Array<Promise<boolean>>;
}

export class AppBootstrapper extends CoreBootstrapper {
  public initialize = (): BootstrapperInitialized => {
    this.initializeCoreServices();
    this.initializeWebServices();
    this.initializeCore();

    const routes = getAppRoutes();

    return {
      store,
      persistor,
      defaultRoot: DEFAULT_ROOT,
      routes: routes,
      mountActions: [],
    };
  };

  private initializeWebServices = (): void => {
    IoC.bind<IDialogService>(DialogServiceName, new DialogService());
    IoC.bind<INavigationService>(NavigationServiceName, new NavigationService());
    IoC.bind<IToastService>(ToastServiceName, new ToastService());
  };
}
