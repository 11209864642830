import { AuthPageLayout } from "../AuthPageLayout/AuthPageLayout";
import { useForgotPasswordPageController } from "./ForgotPasswordPageController";

import "./ForgotPasswordPage.scss";
import { CustomInputText, InputVariant } from "../../../Components/Views/CustomInputText/CustomInputText";
import { CustomButton, CustomButtonClassType } from "../../../Components/Views/CustomButton/CustomButton";
import { FormattedMessage } from "react-intl";
import { getNavigationService } from "../../../../Core/Services/Interfaces/INavigationService";

const ForgotPasswordPage = () => {
  const { onSubmit, registerField } = useForgotPasswordPageController();

  const emailProps = registerField("email");

  return (
    <AuthPageLayout>
      <form onSubmit={onSubmit}>
        <div className="ForgotPasswordPage-helpText1">
          <FormattedMessage id="ForgotPasswordPage_HelpText1" />
        </div>
        <div className="ForgotPasswordPage-helpText2">
          <FormattedMessage id="ForgotPasswordPage_HelpText2" />
        </div>
        <CustomInputText
          {...emailProps}
          placeholderIntlId="ForgotPasswordPage_EmailPlaceHolder"
          inputVariant={InputVariant.Outlined}
          classes={{ root: "ForgotPasswordPage-email" }}
        />
        <CustomButton
          title={<FormattedMessage id="ForgotPasswordPage_ButtonTitle" />}
          variant={CustomButtonClassType.Standard}
          className="ForgotPasswordPage-submit"
          type={"submit"}
        />
      </form>
      <CustomButton
        variant={CustomButtonClassType.Link}
        title={<FormattedMessage id="ForgotPasswordPage_ToLoginLink" />}
        className="LoginPage-forgotButton"
        onClick={() => getNavigationService().value().goToLogin()}
      />
    </AuthPageLayout>
  );
};

export { ForgotPasswordPage };
