import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';

export interface TableHeaderClass {
    className: string;
}

export interface TableHeaderClasses {
    headerProps: TableHeaderClass;
    headerRowProps: TableHeaderClass;
    headerCellProps: TableHeaderClass;
}

export interface TableHeaderProps {
    //* Array of columns for the header */
    headerColumns: Array<React.ReactNode>;

    //* function({headerProps, rowProps, cellProps}) => PropTypes.node
    //* Provide this function to render a custom header, instead of passing 'headerColumns'
    tableHeader?: (classes: TableHeaderClasses) => React.ReactNode;
}

export const TableHeader = ({ headerColumns = [], tableHeader }: TableHeaderProps) => {
    const showHeader = headerColumns.length > 0 && !tableHeader;

    const tableHeaderClasses: TableHeaderClasses = {
        headerProps: { className: 'pl-header' },
        headerRowProps: { className: 'pl-header-row' },
        headerCellProps: { className: 'pl-header-cell' },
    };

    if (tableHeader) {
        return tableHeader(tableHeaderClasses);
    }

    if (showHeader)
        return (
            <TableHead {...tableHeaderClasses.headerProps}>
                <TableRow {...tableHeaderClasses.headerRowProps}>
                    {headerColumns.map((name, index) => (
                        <TableCell key={index} {...tableHeaderClasses.headerCellProps}>
                            {name}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
};
