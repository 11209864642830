import { BaseEditor } from 'slate';
import { ReactEditor } from 'slate-react';

export enum ElementType {
    video = 'video',
    image = 'image',
    link = 'link',
    paragraph = 'paragraph',
}

export type CustomText = {
    text: string;
    bold?: true;
    italic?: true;
    underlined?: true;
};

export type EmptyText = {
    text: string;
};

export type LinkElement = {
    type: ElementType.link;
    href: string;
    children: EmptyText[];
};

export type ParagraphElement = {
    type: ElementType.paragraph;
    children: LinkElement[] | CustomText[];
};

export type ImageElement = {
    type: ElementType.image;
    alt: string;
    src: string;
    children: EmptyText[];
};

export type VideoElement = {
    type: ElementType.video;
    thumbnailUrl: string;
    src: string;
    videoId: any;
    children: EmptyText[];
};

export type CustomElement = ParagraphElement | LinkElement | ImageElement | VideoElement;

declare module 'slate' {
    interface CustomTypes {
        Editor: BaseEditor & ReactEditor;
        Element: CustomElement;
        Text: CustomText;
        EmptyText: EmptyText;
    }
}
