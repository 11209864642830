import "./AuthenticatedPageLayout.scss";

import { NavBar } from "./NavBar/NavBar";
import { Header, HeaderProps } from "./Header/Header";
import { Loader } from "../../Components/Templates/Loader/Loader";
import classNames from "classnames";

export interface AuthenticatedPageLayoutProps extends HeaderProps {
  children: React.ReactNode;
  addDefaultPadding?: boolean;
  loading?: boolean;
}

const AuthenticatedPageLayout = ({
  children,
  previousTitleLink,
  title,
  previousTitle,
  loading,
  addDefaultPadding,
}: AuthenticatedPageLayoutProps) => {
  return (
    <div className="AuthenticatedPageLayout-root">
      <NavBar />
      <div className="AuthenticatedPageLayout-content">
        <Header previousTitle={previousTitle} title={title} previousTitleLink={previousTitleLink} />
        <div
          className={classNames("AuthenticatedPageLayout-children", {
            "AuthenticatedPageLayout-children-padding": addDefaultPadding ?? false,
          })}>
          {loading ? <Loader /> : children}
        </div>
      </div>
    </div>
  );
};

export { AuthenticatedPageLayout };
