import { IoC } from '../ServicesContainer';

export type DialogAction = () => void | Promise<void>;

export enum ConfirmDialogStyle {
    Delete,
    Validate
}

export interface IDialogService {
    showConfirm(titleIntlId: string, action: Function, variant?: ConfirmDialogStyle): void;
}

const DialogServiceName = 'DialogService';
const getDialogService = () => IoC.getLazy<IDialogService>(DialogServiceName);

export { DialogServiceName, getDialogService };

