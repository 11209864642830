import { useSelector } from "react-redux";
import { AppState } from "../../../../Configs/store/AppReducer";

export interface UsePrivateRouteAccessApi {
  isAuthenticated: boolean;
  loading: boolean;
}

export const usePrivateRouteAccess = (): UsePrivateRouteAccessApi =>
  useSelector<AppState, UsePrivateRouteAccessApi>(
    (state): UsePrivateRouteAccessApi => {
      return {
        isAuthenticated: state.Authentication.isAuthenticated,
        loading: !state.Authentication.initialized || !state.Me.initialized,
      };
    }
  );
