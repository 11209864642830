import React from 'react';
import { Loader } from '../../Loader/Loader';
import './loadingPage.scss';


const LoadingPage = () => {
    return (
        <div className="app-loading-page">
            <div className="content-container">
                <Loader />
            </div>
        </div>
    );
};

export { LoadingPage };
