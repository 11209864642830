import { AppRoutes } from "../../../../Core/App/AppRoutes";
import { LoginViewModel, LoginViewState } from "../../../../Core/ViewModels/Auth/LoginViewModel";
import { useRedirectIfAuthenticated } from "../../../Hooks/Authentication/useRedirectIfAuthenticated";
import { useInputTextFieldProps } from "../../../Hooks/Form/useInputProps";
import { ViewModelLocator } from "../../../../Core/ViewModels/ViewModelLocator";
import { usePreventDefault } from "../../../Hooks/Form/usePreventDefault";
import { useLifecycle } from "../../../Hooks/Lifecycle/useLifecycle";

export const useLoginPageController = () => {
  const { vm, state } = useLifecycle<LoginViewState, LoginViewModel>({
    getRef: () => ViewModelLocator.LoginVM,
    setRefValue: (value) => (ViewModelLocator.LoginVM = value),
    newInstance: () => new LoginViewModel(),
    logName: "LoginVM",
  });

  const onSubmit = usePreventDefault(vm.onSubmit);
  const registerField = useInputTextFieldProps(state, vm.onChangeField, { hideLabel: true, i18nPrefix: "LoginPage" });
  useRedirectIfAuthenticated(AppRoutes.ART_WORK_LIST);

  return { vm, state, onSubmit, registerField };
};
