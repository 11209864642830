import React from "react";
import classNames from "classnames";

import "./CustomInputText.scss";
import { FormattedMessage, useIntl } from "react-intl";

export enum InputType {
  Text = "text",
  TextEmail = "email",
  Password = "password",
  Number = "number",
}

export enum InputVariant {
  Standard = "standard",
  Outlined = "outlined",
  Filled = "filled",
}

export interface CustomInputTextClassNames {
  root?: string;
  input?: string;
}

export interface CustomInputTextProps {
  placeholderIntlId?: string;
  labelIntlId?: string;
  type?: InputType;
  multiline?: boolean;
  maxLength?: number;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onChange: (event: string) => void;
  value: string;
  inputVariant?: InputVariant;
  autoFocus?: boolean;
  classes?: CustomInputTextClassNames;
  errorMessage?: string;
}

const CustomInputText = ({
  placeholderIntlId,
  labelIntlId,
  type = InputType.Text,
  multiline = false,
  maxLength = 100,
  onBlur,
  onClick,
  onChange,
  value,
  inputVariant = InputVariant.Standard,
  autoFocus = false,
  classes,
  errorMessage,
}: CustomInputTextProps) => {
  const i18n = useIntl();

  return (
    <div
      className={classNames({
        "CustomInputText-root": true,
        [classes?.root || ""]: classes?.root,
      })}>
      {labelIntlId && (
        <div className={classNames({ "CustomInputText-label": true, [inputVariant]: true })}>
          <FormattedMessage id={labelIntlId} />
        </div>
      )}
      <div
        className={classNames({
          "CustomInputText-input": true,
          [classes?.input || ""]: classes?.input,
          [inputVariant]: true,
          "CustomInputText-error": errorMessage,
        })}>
        {multiline ? (
          <textarea
            onBlur={onBlur}
            onChange={(event) => onChange(event.target.value)}
            value={value}
            autoFocus={autoFocus}
            placeholder={placeholderIntlId ? i18n.formatMessage({ id: placeholderIntlId }) : ""}
          />
        ) : (
          <input
            onClick={onClick}
            onBlur={onBlur}
            onChange={(event) => onChange(event.target.value)}
            value={value}
            type={type}
            autoFocus={autoFocus}
            multiple={multiline}
            maxLength={maxLength}
            placeholder={placeholderIntlId ? i18n.formatMessage({ id: placeholderIntlId }) : ""}
          />
        )}
      </div>
      <div className="CustomInputText-errorMessage">{errorMessage}</div>
    </div>
  );
};

export { CustomInputText };
