import { XHRRequestConfig } from './XHRRequest';
import { AxiosResponse } from 'axios';

/**
 * Creates a response interceptor that will check the `is_succcess` key in the data returned.
 * If falsy, will call handleError and then reject, both with an object with the key `response` that was the reponse
 * of the request.
 * Otherwise will resolve with the data
 * @param {Function} handleError - Called if `is_success` is falsy
 * @param {Function} handleSuccess - Called if `if_success` is truthy
 */
const createResponseInterceptor = (handleError: any, handleSuccess: any) => (
    response: AxiosResponse
) => {
    const { config } = response as { config: XHRRequestConfig };
    if (!response.data.is_success && config.checkError) {
        if (handleError) handleError({ response });
        return Promise.reject({ response });
    }
    if (handleSuccess) handleSuccess(response);
    return Promise.resolve(response.data);
};

/**
 * Creates an error interceptor that will call `handleError` and reject, both with an objec twith keys `config` and `response`.
 * If the reponse was an HTTP success, the config key will be undefined, but the same config may be found withing the `reponse` key.
 * If falsy, will call handleError and then reject, both with an object with the key `response` that was the reponse
 * @param {Function} handleError - Called with keys `config` and `response`. Response may be undefined if nothing was returned
 */
const createErrorInterceptor = (handleError: any) => (error: any) => {
    if (handleError) handleError(error);
    return Promise.reject(error);
};

export { createResponseInterceptor, createErrorInterceptor };
