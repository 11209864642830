import { Editor } from 'slate';
import { ElementType } from '../CustomTypes.d';

export const withLinks = (editor: Editor) => {
    const { isInline } = editor;

    // Making the links inline
    editor.isInline = (element) => (element.type === ElementType.link ? true : isInline(element));

    return editor;
};

export default withLinks;
