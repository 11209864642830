import { FileInputValue } from "../../Models/Files/File";
import { MediaType } from "../../../Web/Components/Views/MediaInput/MediaInput";
import { Languages } from "../../Models/Languages/Languages";
import { ExhibitionDataResponse } from "../../Models/Exhibition/Exhibition";
import {
  ExhibitionsDetailsViewState,
  ExhibitionLocalizedContentViewState,
  DefaultLanguageContent,
} from "./ExhibitionsDetailsViewModel";

/** Handle api response, to create a new ExhibitionsDetailsViewState */
export function exhibitionDataResponseToViewState(
  response: ExhibitionDataResponse,
  existingState: ExhibitionsDetailsViewState
): ExhibitionsDetailsViewState {
  // Make sure that the received object is complet without any undefined value
  const apiLocalizedContent = response.data.localized_content;
  let localized_content: Record<Languages, ExhibitionLocalizedContentViewState> = {
    fr: DefaultLanguageContent,
    nl: DefaultLanguageContent,
  };

  for (let lang of Object.keys(apiLocalizedContent) as Languages[]) {
    const currentContent = apiLocalizedContent[lang];
    const picture: FileInputValue[] = [];
    if (currentContent.picture_url) {
      picture.push({ id: currentContent.picture_id, type: MediaType.image, url: currentContent.picture_url });
    }

    const newsLocalizedContent = response.data.news?.localized_content?.[lang];
    const news_title = newsLocalizedContent?.title ?? "";
    const news_content = newsLocalizedContent?.content ?? "";

    const news_localPicture: FileInputValue[] = [];
    if (newsLocalizedContent?.picture_url) {
      news_localPicture.push({
        id: newsLocalizedContent.picture_id,
        type: MediaType.image,
        url: newsLocalizedContent.picture_url,
      });
    }

    localized_content[lang] = {
      ...DefaultLanguageContent,
      ...currentContent,
      localPicture: picture,

      news_title,
      news_content,
      news_localPicture,
    };
  }

  return {
    ...existingState,
    ...response.data,
    localized_content: localized_content,
  };
}
