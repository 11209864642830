import Popover from '@material-ui/core/Popover';
import IconVideo from '@material-ui/icons/VideoCall';
import React from 'react';
import { useSlate } from 'slate-react';
import { XRichTextProps } from '../../..';

import { Button } from '../../MarkButtons/Button/Button';
import { VideoChoice } from '../VideoChoice/VideoChoice';

export interface VideoButtonProps extends XRichTextProps {}

export const VideoButton = ({ videoList, busyLoadingVideos }: VideoButtonProps) => {
    const editor = useSlate();
    const anchorEl = React.useRef<HTMLDivElement | null>(null);
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <Button ref={(ref) => {
                anchorEl.current = ref;
            }} onClick={() => setOpen(true)}>
                <IconVideo />
            </Button>

            <Popover
                open={open}
                anchorEl={anchorEl.current}
                anchorReference="anchorEl"
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <VideoChoice
                    editor={editor}
                    handleClose={() => setOpen(false)}
                    videoList={videoList}
                    busyLoadingVideos={busyLoadingVideos}
                />
            </Popover>
        </React.Fragment>
    );
};
