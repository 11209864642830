export class Constants {
  public static CLIENT_ID = "BACK_WEB ";
  public static CLIENT_SECRET = "back_web";
  private static API_BASE_URL = "__API_HOST__";
  private static API_BASE_URL_DEV = "https://interreg-api-dev.azurewebsites.net/";

  public static DEFAULT_ITEM_COUNT_PER_PAGE = 15;

  public static isDev = process.env.NODE_ENV === "development";

  public static getRoot = (): string => (Constants.isDev ? Constants.API_BASE_URL_DEV : Constants.API_BASE_URL);
}
