import { FormattedMessage } from "react-intl";
import { getNavigationService } from "../../../../Core/Services/Interfaces/INavigationService";
import { CustomButton, CustomButtonClassType } from "../../../Components/Views/CustomButton/CustomButton";
import { CustomInputText, InputType, InputVariant } from "../../../Components/Views/CustomInputText/CustomInputText";
import { AuthPageLayout } from "../AuthPageLayout/AuthPageLayout";

import "./LoginPage.scss";
import { useLoginPageController } from "./LoginPageController";

const LoginPage = () => {
  const { state, onSubmit, registerField } = useLoginPageController();

  const loginProps = registerField("email");
  const passwordProps = registerField("password");

  return (
    <AuthPageLayout>
      <div className="LoginPage-root">
        <div className="LoginPage-welcomeText">
          <FormattedMessage id="LoginPage_WelcomeText" />
        </div>
        <div className="LoginPage-helpText">
          <FormattedMessage id="LoginPage_HelpText" />
        </div>
        <div className="LoginPage-actions">
          <form
            onSubmit={(event) => {
              event.preventDefault();
            }}>
            <CustomInputText
              {...loginProps}
              placeholderIntlId="LoginPage_LoginPlaceholder"
              inputVariant={InputVariant.Outlined}
              classes={{ root: "LoginPage-loginInput" }}
            />
            <CustomInputText
              {...passwordProps}
              placeholderIntlId="LoginPage_PasswordPlaceholder"
              inputVariant={InputVariant.Outlined}
              classes={{ root: "LoginPage-passwordInput" }}
              type={InputType.Password}
            />
            <CustomButton
              variant={CustomButtonClassType.Link}
              title={<FormattedMessage id="LoginPage_ForgotPassword" />}
              className="LoginPage-forgotButton"
              onClick={() => getNavigationService().value().goToForgotPassword()}
            />
            <CustomButton
              title={<FormattedMessage id="LoginPage_Login" />}
              className="LoginPage-submitButton"
              onClick={onSubmit}
              type="submit"
              isBusy={state.busy}
              disabled={state.busy}
            />
          </form>
        </div>
      </div>
    </AuthPageLayout>
  );
};

export { LoginPage };
