import { XHRRequestCanceler } from "../../Libs/xhr/XHRRequestCanceler";
import { ApiUrls } from "../Models/ApiUrls";
import { CreateNewsRequest, NewsCreateResponse, NewsDataResponse } from "../Models/News/News";
import { BaseDataService } from "./Base/BaseDataService";

export const NewsDataServiceName: string = "NewsDataService";

export interface INewsDataService {
  getNews: (cts: XHRRequestCanceler) => Promise<NewsDataResponse>;
  createNews(data: CreateNewsRequest, cts: XHRRequestCanceler): Promise<NewsCreateResponse>;
}

export class NewsDataService extends BaseDataService implements INewsDataService {
  public getNews(cts: XHRRequestCanceler): Promise<NewsDataResponse> {
    return this.get<NewsDataResponse>(ApiUrls.NEWS, cts);
  }

  public createNews(data: CreateNewsRequest, cts: XHRRequestCanceler): Promise<NewsCreateResponse> {
    return this.post<NewsCreateResponse>(ApiUrls.NEWS_CREATE, data, cts);
  }
}
