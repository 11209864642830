import React from "react";
import classNames from "classnames";
import { Img } from "react-image";
import { RTEImageLoader } from "../RTEImageLoader/RTEImageLoader";
import "./loadableImage.scss";

export interface LoadableImageProps {
  className?: string;
  classes?: {
    root?: string;
    img?: string;
    loader?: string;
  };
  src?: string | Array<string>;
  alt?: string;
}

export const LoadableImage = ({ className = "", classes = {}, src = "", ...rest }: LoadableImageProps) => (
  <div className={classNames("loadable-image-root", classes.root)}>
    <Img
      {...rest}
      src={src}
      className={classNames(className, classes.img)}
      loader={
        <RTEImageLoader
          classes={{
            container: classNames("loadable-image-loader-container", classes.loader),
          }}
        />
      }
    />
  </div>
);