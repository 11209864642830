import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { BaseAppViewModel } from "./BaseAppViewModel";
import { BaseViewState } from "./BaseViewModel";

export type FieldsErrors<T> = { [P in keyof T]?: string };

export interface BaseFormViewState<T> extends BaseViewState {
  fieldsErrors: FieldsErrors<T>;
  unblock?: () => void;
}

export const InitialBaseFormViewState: BaseFormViewState<any> = {
  fieldsErrors: {},
  unblock: () => {},
};

export type OnChangeFieldFunctionType<TViewState> = <T extends keyof TViewState>(
  field: T,
  value: TViewState[T]
) => void;

export abstract class BaseFormViewModel<
  TViewState extends BaseFormViewState<any>
> extends BaseAppViewModel<TViewState> {
  public onChangeField: OnChangeFieldFunctionType<TViewState> = <T extends keyof TViewState>(
    field: T,
    value: TViewState[T]
  ) => {
    this.setState({
      ...this.state,
      [field as string]: value,
      modified: true,
      fieldsErrors: {
        ...this.state.fieldsErrors,
        [field as string]: undefined,
      },
    });
  };

  public onSubmit = () => {
    this.submit();
  };

  protected submit = async (saveOptions?: any): Promise<void> => {
    if (this.validateFields()) {
      await this.WrapError((cts) => this.save(cts, saveOptions), {
        withBusy: true,
      });
    }
  };

  protected abstract validateFields(): boolean;
  protected abstract save(cts: XHRRequestCanceler, saveOptions?: any): Promise<void>;

  public setUnblock = (value: any) => {
    this.onChangeField("unblock", value);
  };
}
