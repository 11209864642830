import { BaseTabViewModel, TabViewState } from "../Base/BaseTabViewModel";

export enum ArtWorkDetailsTabs {
  Informations = "#info",
  Description = "#description",
}

export interface ArtWorkDetailsViewState extends TabViewState {
  mode: "edit" | "create";
  name: string;
}

const InitialArtWorkDetailsViewState: ArtWorkDetailsViewState = {
  locationHashes: [ArtWorkDetailsTabs.Informations, ArtWorkDetailsTabs.Description],
  defaultTabId: ArtWorkDetailsTabs.Informations,
  selectedTabId: ArtWorkDetailsTabs.Informations,
  mode: "create",
  name: "",
};

export class ArtWorkDetailsViewModel extends BaseTabViewModel<ArtWorkDetailsViewState> {
  constructor() {
    super(InitialArtWorkDetailsViewState);
  }

  public setName = (name: string) => {
    this.setState({ ...this.state, name: name, mode: "edit" });
  };
}
