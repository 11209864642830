import { IoC } from "../ServicesContainer";

export interface INavigationService {
  getHomeLink: string;

  goTo: (route: string, replace?: boolean) => void;
  goBack: () => void;

  goToHome: () => void;
  goToLogin: () => void;
  goToGetApp: () => void;
  goToForgotPassword: () => void;

  goToArtWorkCreate: () => void;
  goToArtWorkDetails: (id: string) => void;

  goToExhibitionsCreate: () => void;
  goToExhibitionsDetails: (id: string) => void;
  goToExhibitionsList: () => void;

  goToVideoCreate: () => void;
  goToVideoDetails: (id: string) => void;
  goToVideoList: () => void;

  goToBoUserDetails: (id: string) => void;
  goToBoUserCreate: () => void;
}

const NavigationServiceName = "NavigationService";
const getNavigationService = () => IoC.getLazy<INavigationService>(NavigationServiceName);

export { NavigationServiceName, getNavigationService };
