import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { IMediaDataService } from "../../DataServices/MediaDataService";
import { FileInputValue } from "../../Models/Files/File";
import { Languages } from "../../Models/Languages/Languages";

export type LocalizedContentWithImageState = {
  localPicture: FileInputValue[];
  picture_id: string;
  picture_url: string;
};

export async function uploadLocalizedContentPictures<T extends LocalizedContentWithImageState>(
  mediaService: IMediaDataService,
  localizedContent: Record<Languages, T>,
  cts: XHRRequestCanceler
): Promise<Record<Languages, T>> {
  return uploadLocalizedContentPicturesBase<T>(
    mediaService,
    localizedContent,
    (c) => (c.localPicture.length !== 0 ? c.localPicture[0] : undefined),
    (c, pId) => {
      c.picture_id = pId;
      return c;
    },
    cts
  );
}

export async function uploadLocalizedContentPicturesBase<T>(
  mediaService: IMediaDataService,
  localizedContent: Record<Languages, T>,
  getLocalPicture: (content: T) => FileInputValue | undefined,
  setPictureId: (content: T, pictureId: string) => T,
  cts: XHRRequestCanceler
): Promise<Record<Languages, T>> {
  let localizedState: Record<Languages | string, T> = {};
  for (let lang of Object.keys(localizedContent) as Languages[]) {
    let currentContent = localizedContent[lang];
    const file = getLocalPicture(currentContent)?.file;
    if (file) {
      const pictureUploadResponse = await mediaService.uploadImage(file, () => { }, undefined);
      currentContent = setPictureId(currentContent, pictureUploadResponse?.data.id || "");
    }

    localizedState[lang] = currentContent;
  }

  return localizedState;
}
