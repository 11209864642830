import { useCallback } from "react";
import { Messages } from "../../../Core/Services/Data/I18n/typings/LocalMessageActionTypes";
import { OnChangeFieldFunctionType } from "../../../Core/ViewModels/Base/BaseFormViewModel";
import { InputBaseProps } from "../../Components/Views/InputBase/InputBaseProps";

export interface InputTextFieldPropsOptions {
  i18nPrefix?: string;
  hideLabel?: boolean;
}

type FormStateBase = { fieldsErrors: Messages; busy?: boolean };

/**
 * Provide a method to generate basic input props based on the current state.
 * @param state, the state
 * @param onChangeField onChangeField
 */
export const useInputBaseProps = <FormState extends FormStateBase>(
  state: FormState,
  onChangeField: OnChangeFieldFunctionType<FormState>,
  defaultOptions?: InputTextFieldPropsOptions
) => {
  type FormStateKeys = keyof FormState;

  return useCallback(
    <FieldKeys extends FormStateKeys>(field: FieldKeys, specificOption?: InputTextFieldPropsOptions) => {
      type InputValueType = FormState[FieldKeys];
      const options: InputTextFieldPropsOptions = {
        hideLabel: defaultOptions?.hideLabel,
        i18nPrefix: defaultOptions?.i18nPrefix,
        ...specificOption,
      };

      let props: Partial<InputBaseProps<FormState[FieldKeys]>> = {};
      if (options?.i18nPrefix) {
        if (!options.hideLabel) {
          props = {
            ...props,
            labelIntlId: `${options.i18nPrefix}_InputLabel_${String(field)}`,
          };
        }
      }

      return {
        onChange: (v: InputValueType) => onChangeField(field, v),
        errorMessage: state.fieldsErrors[field as string],
        value: state[field],
        disabled: state.busy,
        ...props,
      } as InputBaseProps<InputValueType>;
    },
    [state, onChangeField, defaultOptions?.hideLabel, defaultOptions?.i18nPrefix]
  );
};

type InputTextValue = string | number;
export type InputTextFieldProps = Pick<any, "placeholderIntlId" | keyof InputBaseProps<InputTextValue>>;

/**
 * Provide a method to generate basic text input props based on the current state.
 * @param state, the state
 * @param onChangeField onChangeField
 */
export const useInputTextFieldProps = <FormState extends FormStateBase>(
  state: FormState,
  onChangeField: OnChangeFieldFunctionType<FormState>,
  options?: InputTextFieldPropsOptions
) => {
  const baseCallback = useInputBaseProps(state, onChangeField, options);

  return useCallback(
    (field: keyof FormState): InputTextFieldProps => {
      //Paul: I can't manage to filter 'keyof FormState' by field type so for now 'as unknow as' is better than nothing
      const baseProps = (baseCallback(field) as unknown) as InputTextFieldProps;
      if (options?.i18nPrefix) {
        return { ...baseProps, placeholderIntlId: `${options.i18nPrefix}_InputPlaceholder_${String(field)}` };
      }

      return baseProps;
    },
    [baseCallback, options?.i18nPrefix]
  );
};
