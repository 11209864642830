import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { ArtWorkDataServiceName, IArtWorkDataService } from "../../DataServices/ArtWorkDataService";
import { IMediaDataService, MediaDataServiceName } from "../../DataServices/MediaDataService";
import { Lazy } from "../../Helpers/Lazy";
import { ArtWorkSection, ArtWorkStatus } from "../../Models/ArtWork/ArtWork";
import { FileInputValue } from "../../Models/Files/File";
import { Video } from "../../Models/Video/Video";
import { AuthServiceName, IAuthService } from "../../Services/Data/Authentication/AuthenticationService";
import { IoC } from "../../Services/ServicesContainer";
import { DateUtils } from "../../Utils/DateUtils";
import { MessageUtils } from "../../Utils/MessageUtils";
import { ValidatorsUtils } from "../../Utils/ValidatorsUtils";
import { BaseEditOrCreateViewModel } from "../Base/BaseEditOrCreateViewModel";
import { BaseViewState } from "../Base/BaseViewModel";
import { Messages } from "../../Services/Data/I18n/typings/LocalMessageActionTypes";
import { Languages } from "../../Models/Languages/Languages";
import { validateLocalizedContent } from "../Exhibitions/validateLocalizedContent";
import { uploadLocalizedContentPictures } from "../Exhibitions/uploadLocalizedContentPictures";
import { artWorkInformationTabViewStateToCreateOrUpdateRequest } from "./artWorkInformationTabViewStateToCreateOrUpdateRequest";
import { isArtWorkInformationContentEmpty } from "./isArtWorkInformationContentEmpty";
import { artWorkDetailsResponseToArtWorkInformationTabViewState } from "./artWorkDetailsResponseToArtWorkInformationTabViewState";
import { HTMLSerializer } from "../../../Libs/ideine-web-richtext/Utils/Serializers";

export interface ArtWorkInformationContent {
  title: string;
  subtitle: string;
  description: string;
  short_description: string;
  country: string;
  artist_name: string;
  copyrights: string;
  picture_id: string;
  picture_url: string;
  localPicture: FileInputValue[];
  editorState: any;
  fieldsErrors: Messages;
  modified: boolean;
}

export interface ArtWorkInformationTabViewState extends BaseViewState {
  localized_content: Record<Languages, ArtWorkInformationContent>;
  currentLanguage: Languages;
  id: string;
  year: string;
  section: ArtWorkSection | undefined;
  latitude: string;
  longitude: string;
  is_outside: boolean;
  app_url: string;
  is_published: boolean;
  is_cancelled: boolean;
  published_date: Date | undefined;
  sections: ArtWorkSection[];
  videoList: Video[];
  busyLoadingVideos: boolean;
  fieldsErrors: Messages;
  invalidCredentials: boolean;
  mode: "create" | "edit";
  modified: boolean;
  unblock: () => void;
}

export const DefaultLocalizedContent: ArtWorkInformationContent = {
  title: "",
  subtitle: "",
  description: "",
  short_description: "",
  country: "",
  artist_name: "",
  copyrights: "",
  picture_id: "",
  picture_url: "",
  localPicture: [],
  editorState: "",
  modified: false,
  fieldsErrors: [],
};

export const InitialArtWorkInformationTabViewState: ArtWorkInformationTabViewState = {
  localized_content: {
    fr: DefaultLocalizedContent,
    nl: DefaultLocalizedContent,
  },
  currentLanguage: "fr",
  id: "",
  year: "",
  section: { id: "", name: "", is_main_section: false },
  latitude: "",
  longitude: "",
  is_outside: false,
  app_url: "",
  is_published: false,
  is_cancelled: false,
  published_date: undefined,
  sections: [],
  videoList: [],
  busyLoadingVideos: false,
  fieldsErrors: [],
  invalidCredentials: false,
  mode: "create",
  modified: false,
  unblock: () => {},
};

export class ArtWorkInformationTabViewModel extends BaseEditOrCreateViewModel<ArtWorkInformationTabViewState> {
  protected readonly authService: Lazy<IAuthService>;
  private readonly artWorkDataService: Lazy<IArtWorkDataService>;
  protected readonly mediaService: Lazy<IMediaDataService>;
  private readonly setName: (name: string) => void;

  constructor(setName: (name: string) => void) {
    super(InitialArtWorkInformationTabViewState);
    this.authService = IoC.getLazy<IAuthService>(AuthServiceName);
    this.mediaService = IoC.getLazy<IMediaDataService>(MediaDataServiceName);
    this.artWorkDataService = IoC.getLazy<IArtWorkDataService>(ArtWorkDataServiceName);
    this.setName = setName;
    this.loadSections();
    this.loadVideoList();
  }

  public onChangeLocalizedField = (field: any, value: any) => {
    this.setState({
      ...this.state,
      localized_content: {
        ...this.state.localized_content,
        [this.state.currentLanguage]: {
          ...this.state.localized_content[this.state.currentLanguage],
          [field]: value,
          modified: true,
          fieldsErrors: {
            ...this.state.localized_content[this.state.currentLanguage].fieldsErrors,
            [field as string]: undefined,
          },
        },
      },
    });
  };

  public changeCurrentLanguage = (value: Languages) => {
    this.setState({
      ...this.state,
      currentLanguage: value,
    });
  };

  private loadVideoList = () => {
    this.WrapError(
      async (cts: XHRRequestCanceler) => {
        const response = await this.mediaService.value().getVideoList(cts);
        const mappedVideos: Video[] = await Promise.all(
          response.data.map(
            async (video): Promise<Video> => {
              const thumbnail = await this.mediaService.value().getVideoThumbnailUrl(video.video_id, cts);
              return {
                ...video,
                thumbnailUrl: thumbnail.data.url,
              };
            }
          )
        );
        this.setState({ ...this.state, videoList: mappedVideos });
      },
      { busyField: "busyLoadingVideos", withBusy: true }
    );
  };

  public onEditorStateChange = (change: any) => {
    if (this.state.localized_content[this.state.currentLanguage].description.startsWith("<div>")) {
      return this.setState({
        ...this.state,
        localized_content: {
          ...this.state.localized_content,
          [this.state.currentLanguage]: {
            ...this.state.localized_content[this.state.currentLanguage],
            description: HTMLSerializer(change),
            editorState: change.children,
            modified: true,
          },
        },
      });
    } else {
      return this.setState({
        ...this.state,
        localized_content: {
          ...this.state.localized_content,
          [this.state.currentLanguage]: {
            ...this.state.localized_content[this.state.currentLanguage],
            description: HTMLSerializer(change),
            editorState: change.children,
          },
        },
      });
    }
  };

  public loadSections = () => {
    this.WrapError(async (cts: XHRRequestCanceler) => {
      const sections = await this.artWorkDataService.value().getSections(cts);
      this.setState({ ...this.state, sections: sections.data });
    });
  };

  public getEditItemState = async (itemId: string, cts: XHRRequestCanceler) => {
    const response = await this.artWorkDataService.value().getArtWork(itemId, cts);
    return artWorkDetailsResponseToArtWorkInformationTabViewState(response, this.state);
  };

  protected validateFields = () => {
    const errors: Messages = {};
    const requiredFieldError = this.getString("COMMON_REQUIRED_FIELD");

    errors.localized_content = validateLocalizedContent(this.state.localized_content, (content) => {
      const locErrors: Messages = {};
      ValidatorsUtils.checkRequired(content.title, locErrors, "title", requiredFieldError);
      ValidatorsUtils.checkRequired(content.artist_name, locErrors, "artist_name", requiredFieldError);

      return {
        isEmpty: isArtWorkInformationContentEmpty(content),
        errors: locErrors,
      };
    });

    ValidatorsUtils.checkRequired(this.state.section?.id, errors, "section", requiredFieldError);
    this.setState({ ...this.state, fieldsErrors: errors });
    return MessageUtils.isEmpty(errors);
  };

  protected save = async (cts: XHRRequestCanceler) => {
    const updatedContent = await uploadLocalizedContentPictures(
      this.mediaService.value(),
      this.state.localized_content,
      cts
    );
    this.setState({ ...this.state, localized_content: updatedContent });

    if (this.state.mode === "create") {
      this.setState({ ...this.state, modified: false });
      const response = await this.artWorkDataService
        .value()
        .createArtWork(artWorkInformationTabViewStateToCreateOrUpdateRequest(this.state), cts);
      if (response.is_success) {
        this.toastService.value().showSuccess("Oeuvre crée");
        const newState: ArtWorkInformationTabViewState = await this.getEditItemState(response.data, cts);
        this.setState({ ...newState });
        this.navigationService.value().goToArtWorkDetails(response.data);
      } else {
        this.toastService.value().showError("Erreur lors de la création de l'œuvre");
      }
    } else if (this.state.mode === "edit") {
      this.setState({ ...this.state, modified: false });
      const response = await this.artWorkDataService
        .value()
        .updateArtWork(this.state.id, artWorkInformationTabViewStateToCreateOrUpdateRequest(this.state), cts);
      if (response.is_success) {
        this.toastService.value().showSuccessUpdate();
        const newState: ArtWorkInformationTabViewState = await this.getEditItemState(this.state.id, cts);
        this.setState({ ...newState });
      } else {
        this.toastService.value().showError("Erreur lors de la modification de l'œuvre");
      }
    }
  };

  public getArtWorkStatus = () => {
    if (this.state.is_published) {
      return this.i18n.value().formatMessage(`ArtWorkInformationTab_Status${ArtWorkStatus.Published}`, {
        date: this.state.published_date ? DateUtils.formatDateNormal(new Date(this.state.published_date)) : "",
      });
    } else if (this.state.published_date) {
      return this.i18n.value().formatMessage(`ArtWorkInformationTab_Status${ArtWorkStatus.Cancelled}`, {
        date: this.state.published_date ? DateUtils.formatDateNormal(new Date(this.state.published_date)) : "",
      });
    }
    return this.i18n.value().getString(`ArtWorkInformationTab_Status${ArtWorkStatus.Brouillon}`);
  };

  public onPublish = () => {
    this.WrapError(
      async (cts: XHRRequestCanceler) => {
        const response = await this.artWorkDataService.value().publishArtWork(this.state.id, cts);
        const newState: ArtWorkInformationTabViewState = await this.getEditItemState(this.state.id, cts);
        if(response.is_success) {
          this.toastService.value().showSuccess(this.i18n.value().getString("ArtWorkDetailsPage_PublishSuccess"));
          this.setState({ ...newState });
        }
        else {
          this.toastService.value().showError(this.i18n.value().getString("ArtWorkDetailsPage_PublishError"));
          throw "Error";
        }
      },
      { withBusy: true }
    );
  };

  public onCancelPublish = () => {
    this.WrapError(
      async (cts: XHRRequestCanceler) => {
        await this.artWorkDataService.value().cancelPublishArtWork(this.state.id, cts);
        const newState: ArtWorkInformationTabViewState = await this.getEditItemState(this.state.id, cts);
        this.toastService.value().showSuccess(this.i18n.value().getString("ArtWorkDetailsPage_UnpublishSuccess"));
        this.setState({ ...newState });
      },
      { withBusy: true }
    );
  };
}
