import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../Configs/store/AppReducer';
import { Debugger } from '../../../Core/Debugger';
import { getNavigationService } from '../../../Core/Services/Interfaces/INavigationService';

export const useRedirectIfAuthenticated = (redirectUrl: string) => {
    const { isAuthenticated, initialized } = useSelector((state: AppState) => state.Authentication);
    React.useEffect(() => {
        Debugger.LogValue('🍉🍉 | HOOK useRedirectIfAuthenticated', { isAuthenticated });
        if (isAuthenticated && initialized) {
            Debugger.LogValue('🍉🍉🍉 | HOOK useRedirectIfAuthenticated', { isAuthenticated });
            getNavigationService().value().goTo(redirectUrl);
            Debugger.LogValue('🍉🍉🍉🍉 | HOOK useRedirectIfAuthenticated', { isAuthenticated });
        }
        return undefined;
    }, [isAuthenticated, initialized, redirectUrl]);
};
