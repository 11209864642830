import Popover from '@material-ui/core/Popover';
import IconImage from '@material-ui/icons/AddPhotoAlternate';
import React from 'react';
import { useSlate } from 'slate-react';

import { Button } from '../../MarkButtons/Button/Button';
import { ImageChoice } from '../ImageChoice/ImageChoice';


export const ImageButton = () => {
	const editor = useSlate();
	const anchorEl = React.useRef<HTMLDivElement | null>(null);
	const [open, setOpen] = React.useState(false);

	return (
		<React.Fragment>
			<Button ref={(ref) => (anchorEl.current = ref)} onClick={() => setOpen(true)}>
				<IconImage />
			</Button>

			<Popover
				open={open}
				anchorEl={anchorEl.current}
				anchorReference="anchorEl"
				onClose={() => setOpen(false)}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
			>
				<ImageChoice
					editor={editor}
					handleClose={() => setOpen(false)}
				/>
			</Popover>
		</React.Fragment>
	);
};
