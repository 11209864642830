import { Editor, Element } from "slate";
import { ElementType } from "../CustomTypes.d";

export const withImageAndVideo = (editor: Editor) => {
  const { isVoid } = editor;

  // Making the image and video nodes void
  editor.isVoid = (element: Element) => (element.type === ElementType.image || element.type === ElementType.video ? true : isVoid(element));

  return editor;
};