import React from 'react';
import { Debugger } from '../../../Core/Debugger';
import { BaseViewModel, BaseViewState } from '../../../Core/ViewModels/Base/BaseViewModel';


export interface UseLifecycleParams<TViewState extends BaseViewState, TViewModel extends BaseViewModel<TViewState> | null> {
    getRef: () => TViewModel | null;
    setRefValue: (value: TViewModel | null) => void;
    newInstance: () => TViewModel;
    setToNullOnDispose?: boolean;
    logName?: string;
}

export interface UseLifecycleReturn<TViewState extends BaseViewState, TViewModel extends BaseViewModel<TViewState>> {
    vm: TViewModel,
    state: TViewState
}

export const useLifecycle = <TViewState extends BaseViewState, TViewModel extends BaseViewModel<TViewState>>({
    getRef,
    newInstance,
    setRefValue,
    setToNullOnDispose = true,
    logName,
}: UseLifecycleParams<TViewState, TViewModel>): UseLifecycleReturn<TViewState, TViewModel> => {
    React.useEffect(() => {
        //* 🔥 LIFECYCLE : onViewMounted #vDM #mountedByReact like : onCreateView
        //*    Do Nothing

        //* 🔥 LIFECYCLE : onViewUnMounted #unMountedByReact : onDestroyView
        //? CLEAN UP
        //? return a function will be triggered by react
        //? when the view did mount for clean up
        return () => {
            const refValue = getRef();

            if (refValue) {
                refValue.dispose();
                if (setToNullOnDispose) {
                    setRefValue(null);
                }

                Debugger.Log(`🔥🎆🔥🎆🔥 | useLifecycle :  ${logName} disposed`);
            }
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    let refValue = getRef();
    if (refValue === null) {
        refValue = newInstance();
        Debugger.Log(`🔥🎆🔥🎆🔥 | useLifecycle : newInstance of ${logName}`);
        setRefValue(refValue);
    }

    const { state } = useViewModelState<TViewState, TViewModel>(refValue)
    return { vm: refValue, state };
};

export const useViewModelState = <TViewState extends BaseViewState, TViewModel extends BaseViewModel<TViewState>>(vm: TViewModel) => {
    const [state, setState] = React.useState(vm.state);

    React.useEffect(() => {
        vm.setStateUpdate(setState);
        //* Debugger.Log(`🔥🎆🔥🎆🔥 | useViewModelState : bind ViewModel state`);
        vm.initialize();
        //* Debugger.Log(`🔥🎆🔥🎆🔥 | useViewModelState : initialize`);
    }, [vm]);

    return { state, updateState: setState };
}
