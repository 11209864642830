import { useEffect, useState } from "react";

/**
 * Create a temporary url for the file
 * @param file
 */
export const useFilePreview = (file: File | null | undefined): string | null | undefined => {
    const [preview, setPreview] = useState<string | null>(null);

    useEffect(() => {
        if (!file) {
            setPreview(null);
        }
        else {
            const currentPreview = URL.createObjectURL(file);
            setPreview(currentPreview);

            return () => {
                URL.revokeObjectURL(currentPreview);
            }
        }

    }, [file]);

    return preview;
}