import "./linkChoice.scss";

import React, { useState } from "react";
import { Editor, Location } from "slate";
import { insertLink } from "../../../Utils/LinkHandler";

import { CustomButton } from "../../CustomButton/CustomButton";
import { RTELinkInput } from "../RTELinkInput/RTELinkInput";
import { useRTEConfig } from "../../../Context/RichtextConfig";

export interface LinkChoiceProps {
  editor: Editor;
  handleClose: () => void;
}

export const LinkChoice = ({ editor, handleClose }: LinkChoiceProps) => {
  const { selection } = editor;
  let selectedText = "";
  if (!!selection) {
    selectedText = Editor.string(editor, selection as Location);
  }

  const [linkTarget, setLinkTarget] = useState("");
  const [linkTitle, setLinkTitle] = useState(selectedText || "");
  const [isNotUrl, setIsNotUrl] = useState(false);

  const addLink = () => {
    let url;
    try {
      url = new URL(linkTarget);
    } catch (_) {
      setIsNotUrl(true);
    }

    if (url?.protocol === "http:" || url?.protocol === "https:") {
      insertLink({ editor, url: linkTarget, title: linkTitle });
      handleClose();
      setIsNotUrl(false);
    }
  };

  const RTEConfig = useRTEConfig();
  return (
    <div className="link-choice-root">
      <RTELinkInput
        value={linkTarget}
        onChange={(e) => setLinkTarget(e)}
        label={RTEConfig.linkTargetLabel}
        placeholder={RTEConfig.linkTargetPlaceholder}
        errorMessage={
          isNotUrl ? (
            RTEConfig.linkErrorMessageNotUrl
          ) : (
            linkTarget === "" && RTEConfig.linkErrorMessageRequired
          )
        }
      />
      <RTELinkInput
        value={linkTitle}
        onChange={(e) => setLinkTitle(e)}
        label={RTEConfig.linkTitleLabel}
        placeholder={RTEConfig.linkTitlePlaceholder}
        errorMessage={linkTitle === "" && RTEConfig.linkErrorMessageRequired}
        disabled={!!selectedText}
      />

      <div className="lc-buttons-container">
        <CustomButton
          className="lc-button-root"
          onClick={addLink}
          disabled={!linkTarget || !linkTitle}
          title={RTEConfig.rteButtonAddLabel}
          fullWidth
        />
        <CustomButton
          className="lc-button-root"
          onClick={handleClose}
          title={RTEConfig.rteButtonCancelLabel}
          fullWidth
          danger
        />
      </div>
    </div>
  );
};