import { IconButton } from "@material-ui/core";
import classNames from "classnames";
import { useDropzone } from "react-dropzone";
import { FileInputValue } from "../../../../Core/Models/Files/File";
import { Loader } from "../../Templates/Loader/Loader";
import { Icons } from "../Icons/Icons";
import { InputBaseProps } from "../InputBase/InputBaseProps";
import { useFilePreview } from "../../../Hooks/Form/useFilePreview";
import { FilesInput } from "./FilesInput";
import { AddNewFileButtonBase, AddNewFileButtonProps, FileInputItemProps } from "./FilesInputItem";
import "./MediaInput.scss";

const ACCEPTED_FILE_TYPE = "image/jpeg, image/png"; //, video/*";
const MAX_MEDIA_COUNT = 9;

export interface MediaInputProps extends InputBaseProps<FileInputValue[]> {
  resolveUrl?: (media: FileInputValue) => string;
  isBusy?: boolean;
  maxCount?: number;
  buttonTitle?: React.ReactNode;
  acceptedFileType?: string;
}

export enum MediaType {
  image,
  video,
}

export const MediaInput = (props: MediaInputProps) => {
  return (
    <FilesInput
      {...props}
      accept={props.acceptedFileType || ACCEPTED_FILE_TYPE}
      maxCount={props.maxCount || MAX_MEDIA_COUNT}
      className={classNames("MediaInput-root", props.className)}
      renderAddNewButton={(props) => <AddNewMediaButton {...props} />}
      renderItem={(props) => <MediaInputItem {...props} />}
      buttonTitle={props.buttonTitle || ""}
    />
  );
};

export const MediaInputItem = ({
  value,
  onDelete,
  accept,
  onNewFile,
  disabled,
  resolveUrl,
  isBusy,
  type,
}: FileInputItemProps) => {
  const preview = useFilePreview(value.file);
  const imageToShowUrl = preview ?? resolveUrl(value);

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    disabled: disabled,
    accept: accept,
    onDrop: (file) => {
      if (file.length > 0) {
        onNewFile(file[0]);
      }
    },
  });

  return (
    <div className={classNames("media-item", { error: value.hasErrorUploading })}>
      {type === MediaType.image || (type === MediaType.video && !value.file) ? (
        <img src={imageToShowUrl} alt="" className="preview" />
      ) : (
        <video src={imageToShowUrl} className="preview" />
      )}
      {isBusy && value.file ? (
        <Loader />
      ) : (
        <div className="buttons">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <IconButton disabled={disabled}>{Icons.IconMediaEdit}</IconButton>
          </div>

          <IconButton disabled={disabled} onClick={onDelete}>
            {Icons.IconMediaDelete}
          </IconButton>
        </div>
      )}
    </div>
  );
};

export const AddNewMediaButton = (props: AddNewFileButtonProps) => (
  <AddNewFileButtonBase {...props}>{Icons.IconMediaAdd}</AddNewFileButtonBase>
);
