import { ArtWorkDetailsViewModel } from "./ArtWork/ArtWorkDetailsViewModel";
import { ArtWorkInformationTabViewModel } from "./ArtWork/ArtWorkInformationTabViewModel";
import { ArtWorkListViewModel } from "./ArtWork/ArtWorkListViewModel";
import { ForgotPasswordViewModel } from "./Auth/ForgotPasswordViewModel";
import { LoginViewModel } from "./Auth/LoginViewModel";
import { ResetPasswordViewModel } from "./Auth/ResetPasswordViewModel";
import { VideoListViewModel } from "./Videos/VideoListViewModel";
import { VideoDetailsViewModel } from "./Videos/VideoDetailsViewModel";
import { ExhibitionsListViewModel } from "./Exhibitions/ExhibitionsListViewModel";
import { ExhibitionsDetailsViewModel } from "./Exhibitions/ExhibitionsDetailsViewModel";
import { NewsDetailsViewModel } from "./News/NewsDetailsViewModel";

export class ViewModelLocator {
  public static LoginVM: LoginViewModel | null = null;
  public static ResetPasswordVM: ResetPasswordViewModel | null = null;
  public static ForgotPasswordVM: ForgotPasswordViewModel | null = null;

  //* ArtWork
  public static ArtWorkListVM: ArtWorkListViewModel | null = null;
  public static ArtWorkDetailsVM: ArtWorkDetailsViewModel | null = null;
  public static ArtWorkInformationTabVM: ArtWorkInformationTabViewModel | null = null;

  public static ExhibitionsListVM: ExhibitionsListViewModel | null = null;
  public static ExhibitionsDetailsVM: ExhibitionsDetailsViewModel | null = null;

  public static NewsDetailsVM: NewsDetailsViewModel | null = null;

  //* Videos
  public static VideoListVM: VideoListViewModel | null = null;
  public static VideoDetailsVM: VideoDetailsViewModel | null = null;
}
