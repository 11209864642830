import { useHistory, useLocation } from "react-router-dom";
import {
  ArtWorkDetailsViewModel,
  ArtWorkDetailsViewState,
} from "../../../../Core/ViewModels/ArtWork/ArtWorkDetailsViewModel";
import { ViewModelLocator } from "../../../../Core/ViewModels/ViewModelLocator";
import { useLifecycle } from "../../../Hooks/Lifecycle/useLifecycle";

export const useArtWorkDetailsPageController = () => {
  const { vm, state } = useLifecycle<ArtWorkDetailsViewState, ArtWorkDetailsViewModel>({
    getRef: () => ViewModelLocator.ArtWorkDetailsVM,
    setRefValue: (value) => (ViewModelLocator.ArtWorkDetailsVM = value),
    newInstance: () => new ArtWorkDetailsViewModel(),
    logName: "ArtWorkDetailsVM",
  });

  const location = useLocation();
  const history = useHistory();
  vm.updateTabIfNeeded(location.hash, history.replace);

  return { vm, state, onSelectedTab: history.replace };
};
