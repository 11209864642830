import { AppRoutes } from "../../../Core/App/AppRoutes";
import { AppRouteType } from "../../Components/Templates/AppRouter/AppRouteType";
import { PrivateRoute } from "../../Components/Templates/AppRouter/PrivateRoute";
import { ArtWorkDetailsPage } from "../../Pages/ArtWork/ArtWorkDetailsPage/ArtWorkDetailsPage";
import { ArtWorkListPage } from "../../Pages/ArtWork/ArtWorkListPage";

const artWorkListRoute: AppRouteType = {
  id: AppRoutes.ART_WORK_LIST,
  render: PrivateRoute,
  props: {
    exact: true,
    path: AppRoutes.ART_WORK_LIST,
    component: ArtWorkListPage,
  },
};

const artWorkDetailsRoute: AppRouteType = {
  id: AppRoutes.ART_WORK_DETAILS,
  render: PrivateRoute,
  props: {
    exact: true,
    path: AppRoutes.ART_WORK_DETAILS,
    component: ArtWorkDetailsPage,
  },
};

const artWorkCreateRoute: AppRouteType = {
  id: AppRoutes.ART_WORK_CREATE,
  render: PrivateRoute,
  props: {
    exact: true,
    path: AppRoutes.ART_WORK_CREATE,
    component: ArtWorkDetailsPage,
  },
};

export const getArtWorkRoutes = () => [artWorkListRoute, artWorkCreateRoute, artWorkDetailsRoute];
