import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { GetPaginatedQueryParameter } from "../../Models/Commons/PaginatedQuery";
import { ArtWork, ArtWorkStatus } from "../../Models/ArtWork/ArtWork";
import { ArtWorkServiceName, IArtWorkService } from "../../Services/Data/ArtWork/ArtWorkService";
import { IoC } from "../../Services/ServicesContainer";
import { BaseListViewModel, BaseListViewState, InitialBaseListViewState } from "../Base/BaseListViewModel";

export interface ArtWorkListViewState extends BaseListViewState<ArtWork> {
  filter: string;
}

const InitialArtWorkListViewState: ArtWorkListViewState = {
  ...InitialBaseListViewState,
  filter: "",
};

export class ArtWorkListViewModel extends BaseListViewModel<ArtWork, ArtWorkListViewState> {
  private readonly artWorkService: IArtWorkService;

  constructor() {
    super(InitialArtWorkListViewState);
    this.artWorkService = IoC.get<IArtWorkService>(ArtWorkServiceName);
  }

  protected getItems(params: GetPaginatedQueryParameter, cts: XHRRequestCanceler) {
    return this.artWorkService.getArtWorks(params, cts);
  }

  public setFilter = (filter: string) => {
    if (this.state.filter === filter) {
      this.setState({ ...this.state, filter: "" });
    } else {
      this.setState({ ...this.state, filter: filter });
    }
  };

  public getSortedItems = () => {
    let { items, filter } = this.state;

    const itemsCpy: ArtWork[] = JSON.parse(JSON.stringify(items));

    if (items && filter && filter === "status") {
      itemsCpy?.sort((item1, item2) => {
        if (item1.is_published) {
          return -1;
        } else if (item2.is_published) {
          return 1;
        }
        return 0;
      });
      return itemsCpy;
    }

    if (items && filter) {
      itemsCpy?.sort((item1, item2) => {
        //@ts-ignore
        if (item1[filter] > item2[filter]) {
          return 1;
        }

        //@ts-ignore
        if (item1[filter] < item2[filter]) {
          return -1;
        }

        return 0;
      });
      return itemsCpy;
    }
    return items || [];
  };

  public getArtWorkStatus = (item: ArtWork) => {
    if (item.is_published) {
      return this.i18n.value().getString(`ArtWorkListPage_Status${ArtWorkStatus.Published}`);
    } else if (item.is_cancelled) {
      return this.i18n.value().getString(`ArtWorkListPage_Status${ArtWorkStatus.Cancelled}`);
    }
    return this.i18n.value().getString(`ArtWorkListPage_Status${ArtWorkStatus.Brouillon}`);
  };
}
