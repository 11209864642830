import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { Constants } from "../../Constants";
import { BasePaginatedListResponse } from "../../Models/ApiData";
import { GetPaginatedQueryParameter } from "../../Models/Commons/PaginatedQuery";
import { BaseAppViewModel } from "./BaseAppViewModel";
import { BaseViewState } from "./BaseViewModel";

export interface BaseListViewState<ItemType> extends BaseViewState {
  page: number;
  search: string;
  items: ItemType[] | null; // If null = not loaded yet
  totalCount: number;
}

export const InitialBaseListViewState: BaseListViewState<any> = {
  page: 0,
  search: "",
  items: null,
  totalCount: 0,
};

export abstract class BaseListViewModel<ItemType, T extends BaseListViewState<ItemType>> extends BaseAppViewModel<T> {
  public initialize = (): void => {
    this.WrapError(async () => {
      super.initialize();
      this.loadData();
    });
  };

  public onPageChange = (page: number): void => {
    this.WrapError(async () => {
      this.setState({ ...this.state, page });
      this.loadData();
    });
  };

  public onSearchChanged = (search: string): void => {
    this.setState({ ...this.state, search, page: 0 });

    if (search === "") {
      this.onSearch();
    }
  };

  public onSearch = (): void => {
    this.refreshData();
  };

  public refreshData = (): void => {
    this.loadData();
  };

  private loadData = (): void => {
    const { search, page } = this.state;
    const paginatedQueryParameter: GetPaginatedQueryParameter = {
      Count: Constants.DEFAULT_ITEM_COUNT_PER_PAGE,
      Page: page,
      search: search,
    };
    this.loadDataWithQueryParameter(paginatedQueryParameter);
  };

  private loadDataWithQueryParameter(params: GetPaginatedQueryParameter): void {
    this.WrapError(
      async (cts: XHRRequestCanceler) => {
        try {
          const response: BasePaginatedListResponse<ItemType> = await this.getItems(params, cts);
          this.setState({
            ...this.state,
            items: response.data,
            totalCount: response.total_count,
          });
        } catch (e) {
          //If error: show 'no result'
          this.setState({
            ...this.state,
            items: [],
            totalCount: 0,
          });
          throw e;
        }
      },
      { withBusy: true }
    );
  }

  protected abstract getItems(
    params: GetPaginatedQueryParameter,
    cts: XHRRequestCanceler
  ): Promise<BasePaginatedListResponse<ItemType>>;
}
