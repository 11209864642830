import React from 'react';
import { useSelected, useFocused, useSlateStatic } from 'slate-react';
import ArrowIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeOnIcon from '@material-ui/icons/VolumeUp';
import styled from 'styled-components';
import useVideoPlayer from './useVideoPlayer';
import './Video.scss';
import { PopupElement } from '../Image/Image';

export const Video = ({ attributes, element, children }: any) => {
    const editor = useSlateStatic();
    const selected = useSelected();
    const focused = useFocused();
    const videoRef = React.useRef(null);
    const {
        playerState, togglePlay, handleOnTimeUpdate, handleVideoProgress, handleVideoSpeed, toggleMute,
    } = useVideoPlayer(videoRef);

    return (
        <div {...attributes} className="editor-video-root">
            <div contentEditable={false} className="editor-video-wrapper">
                <video
                    ref={videoRef}
                    src={element.src}
                    placeholder={element.thumbnailUrl}
                    onTimeUpdate={handleOnTimeUpdate}
                    onClick={(event) => {
                        event.preventDefault();
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            event.stopPropagation();
                            event.preventDefault();
                            editor.insertBreak();
                        }
                    }}
                />
                <div className="controls">
                    <div className="actions">
                        <button onClick={togglePlay}>
                            {!playerState.isPlaying ? <PlayIcon className="icon" /> : <PauseIcon className="icon" />}
                        </button>
                    </div>
                    <VideoControlSlider
                        type="range"
                        min="0"
                        max="100"
                        value={playerState.progress}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => handleVideoProgress(e)}
                    />
                    <select className="velocity" value={playerState.speed} onChange={(e) => handleVideoSpeed(e)}>
                        <option value="0.50">0.50x</option>
                        <option value="1">1x</option>
                        <option value="1.25">1.25x</option>
                        <option value="2">2x</option>
                    </select>
                    <button className="mute-btn" onClick={toggleMute}>
                        {!playerState.isMuted ? <VolumeOnIcon className="icon" /> : <VolumeOffIcon className="icon" />}
                    </button>
                </div>
                {selected && focused && (
                    <PopupElement className="popup" contentEditable={false}>
                        <button
                            onMouseDown={(e) => {
                                e.preventDefault();
                                editor.insertSoftBreak();
                            }}
                        >
                            <ArrowIcon />
                        </button>
                        <button
                            onMouseDown={(e) => {
                                e.preventDefault();
                                editor.insertBreak();
                            }}
                        >
                            <ArrowIcon style={{ transform: 'rotate(180deg)' }} />
                        </button>
                        <button
                            onMouseDown={(e) => {
                                e.preventDefault();
                                editor.deleteBackward('block');
                            }}
                        >
                            <DeleteIcon />
                        </button>
                    </PopupElement>
                )}
            </div>
            {children}
        </div>
    );
};

const VideoControlSlider = styled.input`
    appearance: none !important;
    border: 1px solid white;
    width: 350px;
    height: 4px;
    ::-webkit-slider-runnable-track {
        width: 350px;
        height: 4px;
        background: rgba(255, 255, 255, 0.2);
        border: none;
        border-radius: 20px;
    }
    ::-moz-range-track {
        height: 4px;
        background: rgba(0, 0, 0, 0.2);
        border: none;
        border-radius: 20px;
    }
    ::-webkit-slider-thumb {
        appearance: none !important;
        cursor: pointer;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: ${(props) => props.theme.videoListPageProgressBarThumb ?? 'white'};
        margin-top: -6px;
    }
    ::-moz-range-thumb {
        border: none;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: ${(props) => props.theme.videoListPageProgressBarThumb ?? 'white'};
        margin-top: -4px;
    }
    :-moz-focusring {
        outline: 1px solid white;
        outline-offset: -1px;
    }
    ::-moz-range-progress {
        background: white;
    }
    :focus {
        outline: none;
    }
`;
