import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import {
  ResetPasswordViewModel,
  ResetPasswordViewState,
} from "../../../../Core/ViewModels/Auth/ResetPasswordViewModel";
import { useInputTextFieldProps } from "../../../Hooks/Form/useInputProps";
import { usePreventDefault } from "../../../Hooks/Form/usePreventDefault";
import { ViewModelLocator } from "../../../../Core/ViewModels/ViewModelLocator";
import { useLifecycle } from "../../../Hooks/Lifecycle/useLifecycle";

interface PasswordResetUrlParameters {
  email: string;
  code: string;
}

export const useResetPasswordPageController = () => {
  const { email, code } = useParams<PasswordResetUrlParameters>();
  let query = new URLSearchParams(useLocation().search);
  const isApp = query.get("app");

  const { vm, state } = useLifecycle<ResetPasswordViewState, ResetPasswordViewModel>({
    getRef: () => ViewModelLocator.ResetPasswordVM,
    setRefValue: (value) => (ViewModelLocator.ResetPasswordVM = value),
    newInstance: () => new ResetPasswordViewModel(email, code, Boolean(isApp)),
    logName: "ResetPasswordVM",
  });

  const onSubmit = usePreventDefault(vm.onSubmit);
  const registerField = useInputTextFieldProps(state, vm.onChangeField, { hideLabel: true, i18nPrefix: "LoginPage" });

  return { registerField, state, onSubmit };
};
