import { ButtonBase } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Route, Switch } from "react-router-dom";
import { AppRoutes } from "../../../Core/App/AppRoutes";
import { Constants } from "../../../Core/Constants";
import { Exhibition } from "../../../Core/Models/Exhibition/Exhibition";
import { getNavigationService } from "../../../Core/Services/Interfaces/INavigationService";
import {
    ExhibitionListSort,
    ExhibitionsListViewModel,
    ExhibitionSortFields,
} from "../../../Core/ViewModels/Exhibitions/ExhibitionsListViewModel";
import { CustomButton } from "../../Components/Views/CustomButton/CustomButton";
import { Icons } from "../../Components/Views/Icons/Icons";
import { PaginatedTable } from "../../Components/Views/PaginatedTable/PaginatedTable/PaginatedTable";
import { SearchInput } from "../../Components/Views/SearchInput/SearchInput";
import { AuthenticatedPageLayout } from "../AuthenticatedPageLayout/AuthenticatedPageLayout";
import { ExhibitionDetailsPage } from "./ExhibitionDetailsPage/ExhibitionDetailsPage";
import "./ExhibitionsListPage.scss";

import { useExhibitionsListPageController } from "./ExhibitionsListPageController";

const ExhibitionsListPage = () => {
    const { vm, state } = useExhibitionsListPageController();

    return (
        <AuthenticatedPageLayout title={<FormattedMessage id="ExhibitionsListPage_Title"/>} loading={state.busy}>
            <div className="ExhibitionsListPage-root">
                <div className="ExhibitionsListPage-actions">
                    <SearchInput
                        inputValue={state.search}
                        onChange={vm.onSearchChanged}
                        onSubmit={vm.onSearch}
                        className="ExhibitionsListPage-search"
                    />
                    <CustomButton
                        title={<FormattedMessage id="ExhibitionsListPage_addExhibitions"/>}
                        icon={Icons.IconAdd}
                        onClick={() => getNavigationService().value().goToExhibitionsCreate()}
                    />
                </div>
                <PaginatedTable<Exhibition>
                    items={state.items ?? []}
                    headerColumns={[
                        <SortHeader toggleSort={vm.toogleSort} field="Order" sortState={state.sort}>
                            <FormattedMessage id="ExhibitionsListPage_IndexHeader"/>
                        </SortHeader>,
                        <SortHeader toggleSort={vm.toogleSort} field="Title" sortState={state.sort}>
                            <FormattedMessage id="ExhibitionsListPage_NameHeader"/>
                        </SortHeader>,
                        "",
                    ]}
                    getCellsData={(item:any) => [item.order, item.name, <FormattedMessage id={getStatusI18nId(item)}/>]}
                    onRowClick={(item: Exhibition) => getNavigationService().value().goToExhibitionsDetails(item.id)}
                    itemsPerPage={Constants.DEFAULT_ITEM_COUNT_PER_PAGE}
                    page={state.page}
                    onPageChange={vm.onPageChange}
                    totalCount={state.totalCount}
                />
                <Switch>
                    <Route path={AppRoutes.EXHIBITIONS_CREATE} render={() => <ExhibitionDetailsPage/>}/>
                    <Route path={AppRoutes.EXHIBITIONS_DETAILS} render={() => <ExhibitionDetailsPage/>}/>
                </Switch>
            </div>
        </AuthenticatedPageLayout>
    );
};

const SortHeader = ({
                        toggleSort,
                        field,
                        sortState,
                        children,
                    }: React.PropsWithChildren<{
    toggleSort: ExhibitionsListViewModel["toogleSort"];
    field: ExhibitionSortFields;
    sortState: ExhibitionListSort;
}>) => {
    return (
        <ButtonBase
            onClick={() => toggleSort(field)}
            className={classNames({
                "ExhibitionsListPage-header-exhibitionsName-button": true,
                active: sortState[field] === "asc",
            })}>
            <div className="ExhibitionsListPage-header-exhibitionsName">{children}</div>
            {Icons.IconSelect}
        </ButtonBase>
    );
};

export { ExhibitionsListPage };

const getStatusI18nId = (item: Exhibition) => {
    if (!item.is_published) {
        return "ExhibitionsListPage_Status_NotPublished";
    } else {
        return "ExhibitionsListPage_Status_Published";
    }
};
