import { ButtonBase } from "@material-ui/core";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { Constants } from "../../../Core/Constants";
import { ArtWork } from "../../../Core/Models/ArtWork/ArtWork";
import { getNavigationService } from "../../../Core/Services/Interfaces/INavigationService";
import { CustomButton } from "../../Components/Views/CustomButton/CustomButton";
import { Icons } from "../../Components/Views/Icons/Icons";
import { PaginatedTable } from "../../Components/Views/PaginatedTable/PaginatedTable/PaginatedTable";
import { SearchInput } from "../../Components/Views/SearchInput/SearchInput";
import { AuthenticatedPageLayout } from "../AuthenticatedPageLayout/AuthenticatedPageLayout";
import "./ArtWorkListPage.scss";
import { useArtWorkListPageController } from "./ArtWorkListPageController";

const ArtWorkListPage = () => {
    const { vm, state } = useArtWorkListPageController();

    return (
        <AuthenticatedPageLayout title={<FormattedMessage id="ArtWorkListPage_Title"/>} loading={state.busy}>
            <div className="ArtWorkListPage-root">
                <div className="ArtWorkListPage-actions">
                    <SearchInput
                        inputValue={state.search}
                        onChange={vm.onSearchChanged}
                        onSubmit={vm.onSearch}
                        className="ArtWorkListPage-search"
                    />
                    <CustomButton
                        title={<FormattedMessage id="ArtWorkListPage_addArtWork"/>}
                        icon={Icons.IconAdd}
                        onClick={() => getNavigationService().value().goToArtWorkCreate()}
                    />
                </div>
                <PaginatedTable<ArtWork>
                    items={vm.getSortedItems()}
                    headerColumns={[
                        <ButtonBase
                            onClick={() => vm.setFilter("title")}
                            className={classNames({
                                "ArtWorkListPage-header-artWorkTitle-button": true,
                                active: state.filter === "title",
                            })}>
                            <div className="ArtWorkListPage-header-artWorkTitle">
                                <FormattedMessage id="ArtWorkListPage_ArtWorkTitle"/>
                            </div>
                            {Icons.IconSelect}
                        </ButtonBase>,
                        <ButtonBase
                            onClick={() => vm.setFilter("artist_name")}
                            className={classNames({
                                "ArtWorkListPage-header-artWorkArtist-button": true,
                                active: state.filter === "artist_name",
                            })}>
                            <div className="ArtWorkListPage-header-artWorkArtist">
                                <FormattedMessage id="ArtWorkListPage_ArtWorkArtist"/>
                            </div>
                            {Icons.IconSelect}
                        </ButtonBase>,
                        <ButtonBase
                            onClick={() => vm.setFilter("status")}
                            className={classNames({
                                "ArtWorkListPage-header-artWorkStatus-button": true,
                                active: state.filter === "status",
                            })}>
                            <div className="ArtWorkListPage-header-artWorkStatus">
                                <FormattedMessage id="ArtWorkListPage_ArtWorkStatus"/>
                            </div>
                            {Icons.IconSelect}
                        </ButtonBase>,
                        <FormattedMessage id="ArtWorkListPage_ArtWorkSection"/>,
                        "",
                    ]}
                    getCellsData={(item: any) => [item.title, item.artist_name, vm.getArtWorkStatus(item), item.section.name]}
                    onRowClick={(item: ArtWork) => getNavigationService().value().goToArtWorkDetails(item.id)}
                    itemsPerPage={Constants.DEFAULT_ITEM_COUNT_PER_PAGE}
                    page={state.page}
                    onPageChange={vm.onPageChange}
                    totalCount={state.totalCount}
                />
            </div>
        </AuthenticatedPageLayout>
    );
};

export { ArtWorkListPage };
