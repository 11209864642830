import { NavLink } from "react-router-dom";
import { ProfileHeaderMenu } from "./ProfileHeaderMenu/ProfileHeaderMenu";
import { Icons } from "../../../Components/Views/Icons/Icons";
import "./Header.scss";
import { useAuthenticatedUser, UserLoggedState } from "../../../Hooks/Authentication/useAuthenticatedUser";

export interface HeaderProps {
  title: React.ReactNode;
  previousTitle?: React.ReactNode;
  previousTitleLink?: string;
}

const Header = ({ title, previousTitle, previousTitleLink }: HeaderProps) => {
  const { initialized, name, requestLogout }: UserLoggedState = useAuthenticatedUser();

  return (
    <div className="Header-root">
      {previousTitle && previousTitleLink ? (
        <div className="Header-breadCrumb">
          <NavLink to={previousTitleLink} className="Header-previousTitleLink">
            {previousTitle}
          </NavLink>
          {Icons.IconChevronRight}
          <div className="Header-title">{title}</div>
        </div>
      ) : (
        <div className="Header-title">{title}</div>
      )}
      <ProfileHeaderMenu loading={!initialized} menulabel={name} onLogout={requestLogout} />
    </div>
  );
};

export { Header };
