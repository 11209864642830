import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { Lazy } from "../../Helpers/Lazy";
import {
  AuthenticateResultType,
  AuthServiceName,
  IAuthService,
} from "../../Services/Data/Authentication/AuthenticationService";
import { IoC } from "../../Services/ServicesContainer";
import { MessageUtils } from "../../Utils/MessageUtils";
import { ValidatorsUtils } from "../../Utils/ValidatorsUtils";
import { BaseFormViewModel } from "../Base/BaseFormViewModel";
import { BaseViewState } from "../Base/BaseViewModel";
import { Messages } from "../../Services/Data/I18n/typings/LocalMessageActionTypes";

export interface LoginViewState extends BaseViewState {
  email: string;
  password: string;
  fieldsErrors: Messages;
  invalidCredentials: boolean;
}

export const InitialLoginViewState: LoginViewState = {
  email: "",
  password: "",
  fieldsErrors: [],
  invalidCredentials: false,
};

export class LoginViewModel extends BaseFormViewModel<LoginViewState> {
  protected readonly authService: Lazy<IAuthService>;

  constructor() {
    super(InitialLoginViewState);
    this.authService = IoC.getLazy<IAuthService>(AuthServiceName);
  }

  protected validateFields = () => {
    const { email, password } = this.state;
    const errors: Messages = {};

    const requiredMessage = this.getString("COMMON_REQUIRED_FIELD");
    ValidatorsUtils.checkRequired(email, errors, "email", requiredMessage);
    ValidatorsUtils.checkRequired(password, errors, "password", requiredMessage);

    this.setState({ ...this.state, fieldsErrors: errors, invalidCredentials: false });

    return MessageUtils.isEmpty(errors);
  };

  protected save = async (cts: XHRRequestCanceler) => {
    const authResult = await this.authService.value().login(this.state.email, this.state.password, cts);

    if (
      authResult === AuthenticateResultType.INVALID_CREDENTIALS ||
      authResult === AuthenticateResultType.LOGIN_ERROR
    ) {
      this.state.fieldsErrors["email"] = this.i18n.value().getString("LoginPage_EmailNotRecognized");
      this.state.fieldsErrors["password"] = this.i18n.value().getString("LoginPage_PasswordNotRecognized");
      this.setState({ ...this.state, invalidCredentials: true });
    }
  };
}
