import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";
import { Constants } from "../../Core/Constants";
import { AuthenticationMiddleware } from "../../Core/Middlewares/AuthenticationMiddleware";
import { MeMiddleware } from "../../Core/Middlewares/MeMiddleware";
import { ApplicationReducer } from "./AppReducer";
import { InitialState } from "./InitialState";

const composeEnhancers = composeWithDevTools({ name: "backoffice" });

const middlewares = [thunk, AuthenticationMiddleware, MeMiddleware];

if (Constants.isDev) {
  const { createLogger } = require(`redux-logger`);
  const logger = createLogger({
    predicate: (_, action) => !action?.type?.includes("@@router"),
  });
  middlewares.push(logger);
}

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const store = createStore(ApplicationReducer, InitialState, enhancer);

const persistor = persistStore(store);

export { store, persistor };
